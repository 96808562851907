import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import CodeContainer from 'components/tools/CodeContainer';
import SettingsLabel from 'components/tools/SettingsLabel';

import tilt from 'assets/images/page-dividers/tilt.svg';
import triangles from 'assets/images/page-dividers/triangles.svg';
import wavesOpaque from 'assets/images/page-dividers/waves-opaque.svg';
import waves from 'assets/images/page-dividers/waves.svg';
import generateExample from './generate-example';
import { shapes } from '../Shape/Shape';

import './Output.scss';

const Output = ({ values }) => {
  const { t } = useTranslation();
  const assets = {
    tilt,
    triangles,
    wavesOpaque,
    waves,
  };

  return (
    <Background additionalClass="page-dividers-output">
      <div className="page-dividers-output__section">
        <div className="page-dividers-output__header">
          <SettingsLabel className="page-dividers-output__title">{t('pageDividersGenerator.outputHtmlCodeLabel')}</SettingsLabel>
          <a className="page-dividers-output__button button--tool" href={assets[values.type]} download>
            {t('pageDividersGenerator.downloadLabel')}
          </a>
        </div>
        <CodeContainer>{generateExample('html', values)}</CodeContainer>
      </div>
      <div className="page-dividers-output__section">
        <SettingsLabel>{t('pageDividersGenerator.outputJsxCodeLabel')}</SettingsLabel>
        <CodeContainer>{generateExample('jsx', values)}</CodeContainer>
      </div>
    </Background>
  );
};

Output.propTypes = {
  values: PropTypes.shape({
    position: PropTypes.oneOf(['top', 'bottom']).isRequired,
    direction: PropTypes.oneOf(['normal', 'reverse']).isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(shapes)).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default Output;
