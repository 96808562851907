export default [
  {
    symbol: '!',
    entity: '&excl;',
    name: 'Exclamation Mark',
    css: '\\0021',
  },
  {
    symbol: '"',
    entity: '&quot;',
    name: 'Quotation Mark',
    css: '\\0022',
  },
  {
    symbol: '#',
    entity: '&num;',
    name: 'Number Sign',
    css: '\\0023',
  },
  {
    symbol: '%',
    entity: '&percnt;',
    name: 'Percent Sign',
    css: '\\0025',
  },
  {
    symbol: '&',
    entity: '&amp;',
    name: 'Ampersand',
    css: '\\0026',
  },
  {
    symbol: "'",
    entity: '&apos;',
    name: 'Apostrophe',
    css: '\\0027',
  },
  {
    symbol: '(',
    entity: '&lpar;',
    name: 'Left Parenthesis',
    css: '\\0028',
  },
  {
    symbol: ')',
    entity: '&rpar;',
    name: 'Right Parenthesis',
    css: '\\0029',
  },
  {
    symbol: '*',
    entity: '&ast;',
    name: 'Asterisk',
    css: '\\002A',
  },
  {
    symbol: ',',
    entity: '&comma;',
    name: 'Comma',
    css: '\\002C',
  },
  {
    symbol: '.',
    entity: '&period;',
    name: 'Period',
    css: '\\002E',
  },
  {
    symbol: '/',
    entity: '&sol;',
    name: 'Slash',
    css: '\\002F',
  },
  {
    symbol: ':',
    entity: '&colon;',
    name: 'Colon',
    css: '\\003A',
  },
  {
    symbol: ';',
    entity: '&semi;',
    name: 'Semicolon',
    css: '\\003B',
  },
  {
    symbol: '?',
    entity: '&quest;',
    name: 'Question Mark',
    css: '\\003F',
  },
  {
    symbol: '@',
    entity: '&commat;',
    name: 'At Symbol',
    css: '\\0040',
  },
  {
    symbol: '[',
    entity: '&lbrack;',
    name: 'Left Bracket',
    css: '\\005B',
  },
  {
    symbol: '\\',
    entity: '&bsol;',
    name: 'Backslash',
    css: '\\005C',
  },
  {
    symbol: ']',
    entity: '&rbrack;',
    name: 'Right Bracket',
    css: '\\005D',
  },
  {
    symbol: '^',
    entity: '&Hat;',
    name: 'Caret',
    css: '\\005E',
  },
  {
    symbol: '_',
    entity: '&lowbar;',
    name: 'Underscore',
    css: '\\005F',
  },
  {
    symbol: '`',
    entity: '&grave;',
    name: 'Grave Accent',
    css: '\\0060',
  },
  {
    symbol: '{',
    entity: '&lbrace;',
    name: 'Left Brace',
    css: '\\007B',
  },
  {
    symbol: '|',
    entity: '&vert;',
    name: 'Vertical Bar',
    css: '\\007C',
  },
  {
    symbol: '}',
    entity: '&rbrace;',
    name: 'Right Brace',
    css: '\\007D',
  },
  {
    symbol: '~',
    entity: '&tilde;',
    name: 'Tilde',
    css: '\\007E',
  },
  {
    symbol: '¡',
    entity: '&iexcl;',
    name: 'Inverted Exclamation Mark',
    css: '\\00A1',
  },
  {
    symbol: '¦',
    entity: '&brvbar;',
    name: 'Broken Vertical Bar',
    css: '\\00A6',
  },
  {
    symbol: '§',
    entity: '&sect;',
    name: 'Section Sign',
    css: '\\00A7',
  },
  {
    symbol: '¨',
    entity: '&uml;',
    name: 'Umlaut',
    css: '\\00A8',
  },
  {
    symbol: '©',
    entity: '&copy;',
    name: 'Copyright Sign',
    css: '\\00A9',
  },
  {
    symbol: 'ª',
    entity: '&ordf;',
    name: 'Feminine Ordinal Indicator',
    css: '\\00AA',
  },
  {
    symbol: '«',
    entity: '&laquo;',
    name: 'Double Left-Pointing Angle Quotation Mark',
    css: '\\00AB',
  },
  {
    symbol: '¬',
    entity: '&not;',
    name: 'Not Sign',
    css: '\\00AC',
  },
  {
    symbol: '­',
    entity: '&shy;',
    name: 'Soft Hyphen',
    css: '\\00AD',
  },
  {
    symbol: '®',
    entity: '&reg;',
    name: 'Registered Trade Mark Sign',
    css: '\\00AE',
  },
  {
    symbol: '¯',
    entity: '&macr;',
    name: 'Spacing Macron',
    css: '\\00AF',
  },
  {
    symbol: '²',
    entity: '&sup2;',
    name: 'Superscript Two',
    css: '\\00B2',
  },
  {
    symbol: '³',
    entity: '&sup3;',
    name: 'Superscript Three',
    css: '\\00B3',
  },
  {
    symbol: '´',
    entity: '&acute;',
    name: 'Acute Accent',
    css: '\\00B4',
  },
  {
    symbol: 'µ',
    entity: '&micro;',
    name: 'Micro Sign',
    css: '\\00B5',
  },
  {
    symbol: '¶',
    entity: '&para;',
    name: 'Paragraph Sign',
    css: '\\00B6',
  },
  {
    symbol: '·',
    entity: '&middot;',
    name: 'Middle Dot',
    css: '\\00B7',
  },
  {
    symbol: '¸',
    entity: '&cedil;',
    name: 'Spacing Cedilla',
    css: '\\00B8',
  },
  {
    symbol: '¹',
    entity: '&sup1;',
    name: 'Superscript One',
    css: '\\00B9',
  },
  {
    symbol: 'º',
    entity: '&ordm;',
    name: 'Masculine Ordinal Indicator',
    css: '\\00BA',
  },
  {
    symbol: '»',
    entity: '&raquo;',
    name: 'Double Right-Pointing Angle Quotation Mark',
    css: '\\00BB',
  },
  {
    symbol: '¿',
    entity: '&iquest;',
    name: 'Inverted Question Mark',
    css: '\\00BF',
  },
  {
    symbol: '‐',
    entity: '&hyphen;',
    name: 'Hyphen',
    css: '\\2010',
  },
  {
    symbol: '–',
    entity: '&ndash;',
    name: 'En Dash',
    css: '\\2013',
  },
  {
    symbol: '—',
    entity: '&mdash;',
    name: 'Em Dash',
    css: '\\2014',
  },
  {
    symbol: '―',
    entity: '&horbar;',
    name: 'Horizontal Bar',
    css: '\\2015',
  },
  {
    symbol: '‖',
    entity: '&Vert;',
    name: 'Double Vertical Line',
    css: '\\2016',
  },
  {
    symbol: '‘',
    entity: '&lsquo;',
    name: 'Left Single Quotation Mark',
    css: '\\2018',
  },
  {
    symbol: '’',
    entity: '&rsquo;',
    name: 'Right Single Quotation Mark',
    css: '\\2019',
  },
  {
    symbol: '‚',
    entity: '&sbquo;',
    name: 'Single Low-9 Quotation Mark',
    css: '\\201A',
  },
  {
    symbol: '“',
    entity: '&ldquo;',
    name: 'Left Double Quotation Mark',
    css: '\\201C',
  },
  {
    symbol: '”',
    entity: '&rdquo;',
    name: 'Right Double Quotation Mark',
    css: '\\201D',
  },
  {
    symbol: '„',
    entity: '&bdquo;',
    name: 'Double Low-9 Quotation Mark',
    css: '\\201E',
  },
  {
    symbol: '†',
    entity: '&dagger;',
    name: 'Dagger',
    css: '\\2020',
  },
  {
    symbol: '‡',
    entity: '&Dagger;',
    name: 'Double Dagger',
    css: '\\2021',
  },
  {
    symbol: '•',
    entity: '&bull;',
    name: 'Bullet',
    css: '\\2022',
  },
  {
    symbol: '‥',
    entity: '&nldr;',
    name: 'Two Dot Leader',
    css: '\\2025',
  },
  {
    symbol: '…',
    entity: '&hellip;',
    name: 'Horizontal Ellipsis',
    css: '\\2026',
  },
  {
    symbol: '‰',
    entity: '&permil;',
    name: 'Per Mille Sign',
    css: '\\2030',
  },
  {
    symbol: '‱',
    entity: '&pertenk;',
    name: 'Per Ten Thousand Sign',
    css: '\\2031',
  },
  {
    symbol: '′',
    entity: '&prime;',
    name: 'Prime',
    css: '\\2032',
  },
  {
    symbol: '″',
    entity: '&Prime;',
    name: 'Double Prime',
    css: '\\2033',
  },
  {
    symbol: '‴',
    entity: '&tprime;',
    name: 'Triple Prime',
    css: '\\2034',
  },
  {
    symbol: '‵',
    entity: '&bprime;',
    name: 'Reversed Prime',
    css: '\\2035',
  },
  {
    symbol: '‹',
    entity: '&lsaquo;',
    name: 'Single Left-Pointing Angle Quotation Mark',
    css: '\\2039',
  },
  {
    symbol: '›',
    entity: '&rsaquo;',
    name: 'Single Right-Pointing Angle Quotation Mark',
    css: '\\203A',
  },
  {
    symbol: '‾',
    entity: '&oline;',
    name: 'Overline',
    css: '\\203E',
  },
  {
    symbol: '⁁',
    entity: '&caret;',
    name: 'Caret Insertion Point',
    css: '\\2041',
  },
  {
    symbol: '⁃',
    entity: '&hybull;',
    name: 'Hyphen Bullet',
    css: '\\2043',
  },
  {
    symbol: '⁄',
    entity: '&frasl;',
    name: 'Fraction Slash',
    css: '\\2044',
  },
  {
    symbol: '⁏',
    entity: '&bsemi;',
    name: 'Reversed Semicolon',
    css: '\\204F',
  },
  {
    symbol: '⁗',
    entity: '&qprime;',
    name: 'Quadruple Prime',
    css: '\\2057',
  },
  {
    symbol: '™',
    entity: '&trade;',
    name: 'Trade Mark Sign',
    css: '\\2122',
  },
];
