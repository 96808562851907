import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import useLocalStorage from 'hooks/useLocalStorage';

export const UserContext = createContext({
  userEmail: '',
  setUserEmail: () => {},
  userId: 0,
  setUserId: () => {},
  userName: '',
  setUserName: () => {},
  userRole: [],
  setUserRole: () => {},
  userImage: '',
  setUserImage: () => {},
  userPosition: '',
  setUserPosition: () => {},
  userPhone: '',
  setUserPhone: () => {},
  userSkypeAccount: '',
  setUserSkypeAccount: () => {},
  userSlackAccount: '',
  setUserSlackAccount: () => {},
  userStartDate: '',
  setUserStartDate: () => {},
  userPersonalNumber: '',
  setPersonalNumber: () => {},
  userLeaveDays: '',
  setLeaveDays: () => {},
  isRememberMode: false,
  setIsRememberMode: () => {},
});

const UserContextProvider = ({ children }) => {
  const [userEmail, setUserEmail] = useLocalStorage('userEmail', '');
  const [userId, setUserId] = useLocalStorage('userId', 0);
  const [userName, setUserName] = useLocalStorage('userName', '');
  const [userRole, setUserRole] = useLocalStorage('userRole', []);
  const [userImage, setUserImage] = useLocalStorage('userImage', '');
  const [userPosition, setUserPosition] = useLocalStorage('userPosition', '');
  const [userPhone, setUserPhone] = useLocalStorage('userPhone', '');
  const [userSkypeAccount, setUserSkypeAccount] = useLocalStorage('userSkypeAccount', '');
  const [userSlackAccount, setUserSlackAccount] = useLocalStorage('userSlackAccount', '');
  const [userPersonalNumber, setUserPersonalNumber] = useLocalStorage('userPersonalNumber', '');
  const [userStartDate, setUserStartDate] = useLocalStorage('userStartDate', '');
  const [userLeaveDays, setUserLeaveDays] = useLocalStorage('userLeaveDays', '');
  const [isRememberMode, setIsRememberMode] = useLocalStorage('rememberMode', false)

  return (
    <UserContext.Provider
      value={{
        userEmail,
        setUserEmail,
        userId,
        setUserId,
        userName,
        setUserName,
        userRole,
        setUserRole,
        userImage,
        setUserImage,
        userPosition,
        setUserPosition,
        userPhone,
        setUserPhone,
        userSkypeAccount,
        setUserSkypeAccount,
        userSlackAccount,
        setUserSlackAccount,
        userPersonalNumber,
        setUserPersonalNumber,
        userStartDate,
        setUserStartDate,
        userLeaveDays,
        setUserLeaveDays,
        isRememberMode,
        setIsRememberMode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContextProvider;
