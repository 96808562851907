import React from 'react';
import PropTypes from 'prop-types';

const Tilt = ({ style }) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path d="M1200 120L0 16.48V0h1200v120z" />
  </svg>
);

Tilt.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.string,
  }).isRequired,
};

export default Tilt;
