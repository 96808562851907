import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import example from 'helpers/utility/svg-input-example';
import Button from '../Button/Button';
import SettingsLabel from '../SettingsLabel/SettingsLabel';
import Background from '../Background';
import Dropzone from '../Dropzone';
import DropPlaceholder from '../DropPlaceholder';

import './SvgInput.scss';

const SvgInput = ({
  value,
  onChange,
  errors,
  onFilesDrop,
  dropLabel,
  formatFileName,
}) => {
  const { t } = useTranslation();

  const formattedErrors = errors.map((error) => (
    <p className="error" key={uuid()}>
      {t('svgToJsx.failedMessage')}
      {' '}
      {formatFileName(error)}
    </p>
  ));

  return (
    <div className="svg-input">
      <Dropzone onDrop={onFilesDrop} />
      <DropPlaceholder onFileAdd={(file) => onFilesDrop([file])}>{dropLabel}</DropPlaceholder>
      <Background additionalClass="svg-input__background">
        <div className="svg-input__header">
          <SettingsLabel className="svg-input__title">{t('svgToJsx.settingsLabel')}</SettingsLabel>
          <Button onClick={() => onChange(example)}>{t('svgToJsx.exampleButtonTitle')}</Button>
        </div>
        <textarea
          placeholder={t('svgToJsx.textAreaPlaceholder')}
          className="svg-input__input"
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        {formattedErrors.length > 0 && <div className="svg-input__errors">{formattedErrors}</div>}
      </Background>
    </div>
  );
};

SvgInput.defaultProps = {
  formatFileName: () => {},
};

SvgInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilesDrop: PropTypes.func.isRequired,
  dropLabel: PropTypes.string.isRequired,
  formatFileName: PropTypes.func,
};

export default SvgInput;
