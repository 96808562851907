import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Tabs from 'components/tools/Tabs';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';
import systemService from 'services/systemService';
import LeaveRequestItem from 'components/global/LeaveRequestItem';

import './Leaves.scss';

const Leaves = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isResolver, setIsResolver] = useState(false);
  const { userToken } = useContext(AuthContext);
  const { userRole, userId } = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setNotification,
    setNotificationType,
  } = useContext(NotificationContext);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [type, setType] = useState('approved');

  const types = [
    {
      value: 'pending',
      label: t('leavesPage.pendingLabel'),
    },
    {
      value: 'approved',
      label: t('leavesPage.approvedLabel'),
    },
    {
      value: 'declined',
      label: t('leavesPage.declinedLabel'),
    },
  ];

  useDocumentTitle(t('leavesPage.pageTitle'));

  useEffect(() => {
    const getAllRequests = async () => {
      try {
        const { data } = await systemService.getAllRequests(userToken, page, type);

        if (data) {
          if (data.additionalDetails) {
            setRequests([...requests, ...data.additionalDetails]);
          }
        }
      } catch (error) {
        const { message, response } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else
        if (response.data.additionalDetails === 'There are no existing Out of Office requests.') {
          setRequests([...requests]);
          setHasMore(false);
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    const getUserRequests = async () => {
      try {
        const { data } = await systemService.getUserRequests(userToken, userId, page, type);

        if (data.additionalDetails) {
          setRequests(data.additionalDetails);
        }
      } catch (error) {
        const { message, response } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else if (response.data.additionalDetails === 'There are no existing Out of Office requests.') {
          setRequests([...requests]);
          setHasMore(false);
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded && userRole) {
      const isAdminRole = userRole.find((role) => role.title === 'admin' || role.title === 'approver');

      setIsResolver(!!isAdminRole);

      if (isAdminRole) {
        getAllRequests();
      } else {
        getUserRequests();
      }

      setIsLoaded(true);
    }
  }, [isLoaded, page, requests,
    setNotification, setNotificationType,
    t, type, userId, userRole, userToken]);

  return (
    <SystemLayout>
      <section className="leaves">
        <Tabs
          additionalClass="leaves__tab"
          data={types}
          active={type}
          onTabChange={(value) => {
            setPage(1);
            setRequests([]);
            setType(value);
            setIsLoaded(false);
          }}
        />
        {requests.length > 0 ? (
          <>
            <ul
              id="scrollable"
              className="leaves__list"
            >
              <InfiniteScroll
                dataLength={requests.length}
                next={() => {
                  setPage(page + 1);
                  setIsLoaded(false);
                }}
                hasMore={hasMore}
                loader={<p className="leaves__message">{t('leavesPage.loadingLabel')}</p>}
                endMessage={(
                  <p className="leaves__message">
                    {t('leavesPage.loadedLabel')}
                  </p>
              )}
                scrollableTarget="scrollable"
              >
                {requests.map((item) => (
                  <li key={uuid()} className="leaves__item">
                    <LeaveRequestItem
                      request={item}
                      isResolver={isResolver}
                      onClickResolveHandler={() => history.push({
                        pathname: '/resolve-request',
                        state: { id: item.id },
                      })}
                      onClickSignHandler={() => history.push({
                        pathname: '/annual-leave-signing',
                        state: { request: item },
                      })}
                    />
                  </li>
                ))}
              </InfiniteScroll>
            </ul>
          </>
        ) : (
          <p className="leaves__message">{t('leavesPage.noItemsLabel')}</p>
        )}
      </section>
    </SystemLayout>
  );
};

export default Leaves;
