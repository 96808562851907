import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import PageLayout from 'layouts/PageLayout';
import Button from 'components/global/Button';

import './NotFound.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useDocumentTitle(t('notFoundPage.pageTitle'));

  return (
    <PageLayout>
      <section className="not-found">
        <div className="not-found__wrapper">
          <h2 className="not-found__title">404</h2>
          <p className="not-found__message">{t('notFoundPage.message')}</p>
          <Button
            title={t('notFoundPage.buttonTitle')}
            additionalClass="not-found__button"
            onClickHandler={() => history.push('/')}
          />
        </div>
      </section>
    </PageLayout>
  );
};

export default NotFound;
