import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/tools/Tabs';
import SliderInput from 'components/tools/SliderInput';

import './SizePicker.scss';

const PREDEFINED_SIZES = {
  sm: { width: 10, height: 6 },
  md: { width: 22, height: 12 },
  lg: { width: 54, height: 36 },
  xl: { width: 128, height: 94 },
};

const SizePicker = ({
  setPredefinedSize,
  activePredefinedSize,
  value,
  onWidthChange,
  onHeightChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="size-picker">
      <div className="size-picker__title">{t('triangleGenerator.settings.sizesSubTitle')}</div>
      <Tabs
        data={Object.keys(PREDEFINED_SIZES).map((val) => ({ value: val, label: val }))}
        onTabChange={setPredefinedSize}
        active={activePredefinedSize}
      />
      <div className="size-picker__field">
        <div className="size-picker__title">{t('triangleGenerator.settings.widthTitle')}</div>
        <SliderInput value={value.width} onChange={onWidthChange} min={1} max={200} />
      </div>
      <div className="size-picker__field">
        <div className="size-picker__title">{t('triangleGenerator.settings.heightTitle')}</div>
        <SliderInput value={value.height} onChange={onHeightChange} min={1} max={200} />
      </div>
    </div>
  );
};

SizePicker.defaultProps = {
  activePredefinedSize: 'sm',
};

SizePicker.propTypes = {
  setPredefinedSize: PropTypes.func.isRequired,
  activePredefinedSize: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  value: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  onWidthChange: PropTypes.func.isRequired,
  onHeightChange: PropTypes.func.isRequired,
};

export default SizePicker;
