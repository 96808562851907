import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from 'xooks';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/tools/Tabs/Tabs';
import Background from 'components/tools/Background';
import Highlight from 'components/tools/CodeContainer';
import directions from '../directions';
import generateExample from './generate-example';

import './Code.scss';

const languages = ['.css', '.scss', '.jss'].map((language) => ({
  value: language,
  label: language,
}));

const Code = ({ values }) => {
  const ls = useLocalStorage({ key: 'triangle-generator/code', delay: 10 });
  const [language, setLanguage] = useState(ls.retrieve() || '.css');
  const [elementExample, pseudoExample] = generateExample(language, values)[1];
  const { t } = useTranslation();

  const handleLanguageChange = (languageOption) => {
    setLanguage(languageOption);

    ls.save(languageOption);
  };

  return (
    <Background className="wrapper">
      <Tabs data={languages} active={language} onTabChange={handleLanguageChange} />
      <div className="code">
        <div className="code__section">
          <div className="code__title">{t('triangleGenerator.elementTitle')}</div>
          <Highlight>{elementExample}</Highlight>
        </div>
        <div className="code__section">
          <div className="code__title">{t('triangleGenerator.pseudoElementTitle')}</div>
          <Highlight>{pseudoExample}</Highlight>
        </div>
      </div>
    </Background>
  );
};

Code.propTypes = {
  values: PropTypes.shape({
    direction: PropTypes.oneOf(directions).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export default Code;
