import React from 'react';
import PropTypes from 'prop-types';

import generateGradientValue from '../generate-gradient-value';

import './GradientPreview.scss';

const GradientPreview = ({
  values, angle, additionalClass, type,
}) => {
  const gradient = generateGradientValue({ angle, values, type });

  return (
    <div className={`gradient-preview ${additionalClass}`}>
      <div className="gradient-preview__body" style={{ backgroundImage: gradient }} />
    </div>
  );
};

GradientPreview.defaultProps = {
  additionalClass: '',
  angle: 0,
};

GradientPreview.propTypes = {
  additionalClass: PropTypes.string,
  angle: PropTypes.number,
  type: PropTypes.oneOf(['linear', 'radial']).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      opacity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default GradientPreview;
