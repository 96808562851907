import React from 'react';
import PropTypes, { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import generateEventData from '../generate-event-data';
import Keycap from './Keycap/Keycap';

import './Header.scss';

const Header = ({ event }) => {
  const { t } = useTranslation();

  if (!event) {
    return (
      <Background additionalClass="event-keycode-page-header">
        <div className="event-keycode-page-header__placeholder">
          <h3 className="event-keycode-page-header__title">{t('eventsKeyCodes.headerTitle')}</h3>
          <p className="event-keycode-page-header__description">
            <b>{t('eventsKeyCodes.tipLabel')}</b>
            {' '}
            {t('eventsKeyCodes.tipContent')}
          </p>
        </div>
      </Background>
    );
  }

  const getPressedKeys = (currentEvent) => {
    const keys = [];

    if (currentEvent.shiftKey) {
      keys.push('Shift');
    }
    if (currentEvent.ctrlKey) {
      keys.push('Ctrl');
    }
    if (currentEvent.altKey) {
      keys.push('Alt');
    }

    if (currentEvent.metaKey) {
      keys.push('Cmd');
    }

    if (currentEvent.code) {
      keys.push(currentEvent.key);
    }

    return keys;
  }

  const data = generateEventData(event);

  return (
    <Background additionalClass="event-keycode-page-header">
      <div className="event-keycode-page-header__keys">
        {getPressedKeys(data).map((key, index, arr) => (
          <div key={key} className="event-keycode-page-header__key">
            <Keycap key={key} value={key} />
            {index !== arr.length - 1 && <span className="event-keycode-page-header__separator">+</span>}
          </div>
        ))}
      </div>
      <p className="event-keycode-page-header__description">
        <b>{t('eventsKeyCodes.tipLabel')}</b>
        {' '}
        {t('eventsKeyCodes.tipContent')}
      </p>
    </Background>
  );
};

Header.defaultProps = {
  event: null,
};

Header.propTypes = {
  event: PropTypes.shape({
    shiftKey: bool,
    ctrlKey: bool,
    altKey: bool,
    metaKey: bool,
    code: string,
    key: string,
  }),
};

export default Header;
