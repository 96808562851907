import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import EditDeleteMenu from 'components/global/EditDeleteMenu/EditDeleteMenu';
import Avatar from 'components/global/Avatar';

import MailIcon from 'assets/images/card/mail.png';
import MobileIcon from 'assets/images/card/mobile.png';
import SlackIcon from 'assets/images/card/slack.png';
import SkypeIcon from 'assets/images/card/skype.png';

import './MemberCard.scss';

const MemberCard = ({
  user,
  additionalClass,
  isAdmin,
  onClickEditHandler,
  onClickDeleteHandler,
}) => {
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
  const [detail, setDetail] = useState(user.email);
  const { t } = useTranslation();

  const onClickEmailHandler = (event) => {
    event.preventDefault();

    window.location = `mailto:${user.email}`;

    setDetail(user.email);
  };

  const onClickPhoneHandler = (event) => {
    event.preventDefault();

    window.location = `tel:${user.phone}`;

    setDetail(user.phone);
  };

  const onClickSlackHandler = (event) => {
    event.preventDefault();

    setDetail(user.slack);
  };

  const onClickSkypeHandler = (event) => {
    event.preventDefault();

    setDetail(user.skype);
  };

  const detailsData = [
    {
      icon: MailIcon,
      handler: onClickEmailHandler,
    },
    {
      icon: MobileIcon,
      handler: onClickPhoneHandler,
    },
    {
      icon: SlackIcon,
      handler: onClickSlackHandler,
    },
    {
      icon: SkypeIcon,
      handler: onClickSkypeHandler,
    },
  ];

  return (
    <div className={`member-card ${additionalClass}`} onMouseLeave={() => setIsEditMenuOpen(false)}>
      {isAdmin && (
        <div className="member-card__settings">
          <button type="button" className="member-card__settings-button" onClick={() => setIsEditMenuOpen(!isEditMenuOpen)}>
            <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="member-card__settings-icon">
              <path
                d="M19.502 12c0 .34-.03.66-.07.98l2.11 1.65c.19.15.24.42.12.64l-2 3.46c-.09.16-.26.25-.43.25-.06 0-.12-.01-.18-.03l-2.49-1c-.52.39-1.08.73-1.69.98l-.38 2.65c-.03.24-.24.42-.49.42h-4c-.25 0-.46-.18-.49-.42l-.38-2.65c-.61-.25-1.17-.58-1.69-.98l-2.49 1a.5.5 0 01-.61-.22l-2-3.46a.505.505 0 01.12-.64l2.11-1.65a7.93 7.93 0 01-.07-.98c0-.33.03-.66.07-.98l-2.11-1.65a.493.493 0 01-.12-.64l2-3.46c.09-.16.26-.25.43-.25.06 0 .12.01.18.03l2.49 1c.52-.39 1.08-.73 1.69-.98l.38-2.65c.03-.24.24-.42.49-.42h4c.25 0 .46.18.49.42l.38 2.65c.61.25 1.17.58 1.69.98l2.49-1a.5.5 0 01.61.22l2 3.46c.12.22.07.49-.12.64l-2.11 1.65c.04.32.07.64.07.98zm-2 0c0-.21-.01-.42-.05-.73l-.14-1.13.89-.7 1.07-.85-.7-1.21-1.27.51-1.06.43-.91-.7c-.4-.3-.8-.53-1.23-.71l-1.06-.43-.16-1.13-.19-1.35h-1.39l-.2 1.35-.16 1.13-1.06.43c-.41.17-.82.41-1.25.73l-.9.68-1.04-.42-1.27-.51-.7 1.21 1.08.84.89.7-.14 1.13c-.03.3-.05.53-.05.73 0 .2.02.43.05.74l.14 1.13-.89.7-1.08.84.7 1.21 1.27-.51 1.06-.43.91.7c.4.3.8.53 1.23.71l1.06.43.16 1.13.19 1.35h1.4l.2-1.35.16-1.13 1.06-.43c.41-.17.82-.41 1.25-.73l.9-.68 1.04.42 1.27.51.7-1.21-1.08-.84-.89-.7.14-1.13c.03-.3.05-.52.05-.73zm-5.5-4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-2 4c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </button>
          {isEditMenuOpen && (
          <EditDeleteMenu
            onClickEditHandler={() => onClickEditHandler(user)}
            onClickDeleteHandler={() => onClickDeleteHandler(user)}
          />
          )}
        </div>
      )}
      <div className="member-card__avatar-wrapper">
        <Avatar
          additionalClass="member-card__avatar"
          imageUrl={user.profilePicture}
          member
        />
      </div>
      <h3 className="member-card__name">
        {user.firstName}
        {' '}
        {user.lastName}
      </h3>
      <p className="member-card__additional">{user.jobPosition}</p>
      {isAdmin && (
        <>
          <p className="member-card__additional">
            {t('membersPage.startDate')}
            {' '}
            {user.employeeStartDate}
          </p>
          <p className="member-card__additional">
            {t('membersPage.paidLeaveDays')}
            {' '}
            {user.availableAnnualLeaveDays}
          </p>
        </>
      )}
      <p className="member-card__details">{detail}</p>
      <ul className="member-card__contact-list">
        {detailsData.map((item) => (
          <li className="member-card__contact-item" key={uuid()}>
            <button
              type="button"
              to="#"
              className="member-card__contact-button"
              onClick={item.handler}
            >
              <img className="member-card__contact-icon" src={item.icon} alt="" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

MemberCard.defaultProps = {
  additionalClass: '',
};

MemberCard.propTypes = {
  additionalClass: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  onClickEditHandler: PropTypes.func.isRequired,
  onClickDeleteHandler: PropTypes.func.isRequired,
  user: PropTypes.shape({
    profilePicture: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    jobPosition: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    slack: PropTypes.string,
    skype: PropTypes.string,
    employeeStartDate: PropTypes.string,
    availableAnnualLeaveDays: PropTypes.number,
    userId: PropTypes.number,
  }).isRequired,
};

export default MemberCard;
