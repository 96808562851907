import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

const Button = ({
  additionalClass,
  elementRef,
  children,
  onClick,
}) => (
  <button
    data-button
    className={`button-tool ${additionalClass}`}
    type="button"
    ref={elementRef}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.defaultProps = {
  additionalClass: '',
  children: null,
  elementRef: null,
};

Button.propTypes = {
  elementRef: PropTypes.func,
  additionalClass: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default Button;
