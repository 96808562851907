import API from 'helpers/api';

const userService = {
  getUserData: (
    userId,
    token,
  ) => API.get(`api/get/user/${userId}`, {}, token),
  addMember: (
    data,
    token,
  ) => API.post('api/account/register', data, token),
  getAllUsers: (
    token,
  ) => API.get('api/get/users', {}, token),
  resendActivationMail: (
    email,
    token,
  ) => API.post('api/account/resend/activation-email', { email }, token),
  deactivateUser: (
    userId,
    token,
  ) => API.post(`api/user/${userId}/deactivate`, {}, token),
  deleteUser: (
    userId,
    token,
  ) => API.delete(`api/user/${userId}/delete`, {}, token),
  updateUser: (
    userId,
    data,
    token,
  ) => API.post(`api/user/${userId}/update`, data, token),
  setRole: (
    data,
    token,
  ) => API.post('api/user/role/set', data, token),
  removeRole: (
    data,
    token,
  ) => API.post('api/user/role/delete', data, token),
  updateProfile: (
    userId,
    data,
    token,
  ) => API.post(`api/user/${userId}/update`, data, token),
  uploadUserImage: (
    token,
    data,
  ) => API.post('api/user-profile-picture/upload', data, token, true),

};

export default userService;
