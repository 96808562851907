import React, {
  useEffect, useContext, useState, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import userService from 'services/userService';
import systemService from 'services/systemService';
import SystemLayout from 'layouts/SystemLayout';
import AnnoucementCard from 'components/global/AnnouncementCard';
import LeaveRequestItem from 'components/global/LeaveRequestItem';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';

// TODO: phase 2 calendar and todo
import './Dashboard.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [lastAnnouncement, setLastAnnouncement] = useState(null);
  const [lastLeave, setLastLeave] = useState(null);
  const [isRequestMade, setIsRequestMade] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isResolver, setIsResolver] = useState(false);
  const [isLastLeaveLoaded, setIsLastLeaveLoaded] = useState(false);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const { userToken } = useContext(AuthContext);
  const {
    userId,
    setUserName,
    setUserRole,
    userRole,
    setUserImage,
    setUserPosition,
    setUserPhone,
    setUserSlackAccount,
    setUserSkypeAccount,
    setUserPersonalNumber,
    setUserStartDate,
    setUserLeaveDays,
  } = useContext(UserContext);
  const isMounted = useRef(null);

  useDocumentTitle(t('dashboardPage.pageTitle'));

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await userService.getUserData(userId, userToken);

        setUserName(`${data.additionalDetails.firstName} ${data.additionalDetails.middleName} ${data.additionalDetails.lastName}`);
        setUserRole([...data.additionalDetails.roles]);
        setUserImage(data.additionalDetails.profilePicture);
        setUserPosition(data.additionalDetails.jobPosition);
        setUserPhone(data.additionalDetails.phone);
        setUserSlackAccount(data.additionalDetails.slack);
        setUserSkypeAccount(data.additionalDetails.skype);
        setUserStartDate(data.additionalDetails.employeeStartDate);
        setUserPersonalNumber(data.additionalDetails.personalNumber);
        setUserLeaveDays(data.additionalDetails.availableAnnualLeaveDays);

        setIsRequestMade(true);

        setNotification(`${t('notification.welcomeMessage')}${data.additionalDetails.firstName}`);
        setNotificationType('success');
        resetNotification();
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    }

    if (!isRequestMade && userToken && userId !== 0) {
      getUser();
      setIsRequestMade(true);
    }
  }, [isRequestMade, resetNotification, setNotification,
    setNotificationType, setUserImage, setUserLeaveDays,
    setUserName, setUserPersonalNumber, setUserPhone,
    setUserPosition, setUserRole, setUserSkypeAccount,
    setUserSlackAccount, setUserStartDate, t,
    userId, userToken]);

  useEffect(() => {
    const getAllAnnouncements = async () => {
      try {
        const { data } = await systemService.getAllAnnouncements(userToken);

        if (data.additionalDetails) {
          const last = data.additionalDetails.shift();

          const itemData = {
            title: last.title,
            announcement: last.announcement,
            description: last.description,
            alertUsers: last.alertUsers === 1,
            featuredImage: last.featuredImage,
            author: last.author.name,
            authorPosition: last.author.jobPosition,
            authorImage: last.author.profilePicture,
            id: last.id,
            createdAt: last.createdAt,
          };

          setLastAnnouncement(itemData);
        }
      } catch (error) {
        setLastAnnouncement(null);
      }
    };

    if (!isLoaded && isRequestMade) {
      getAllAnnouncements();
      setIsLoaded(true);
    }
  }, [isLoaded, userToken, setLastAnnouncement, isRequestMade]);

  useEffect(() => {
    const getAllRequests = async () => {
      try {
        const { data } = await systemService.getAllRequests(userToken, 1, 'pending');

        if (data.additionalDetails) {
          const last = data.additionalDetails.shift();

          setLastLeave(last);
        }
      } catch (error) {
        setLastLeave(null);
      }
    };

    const getUserRequests = async () => {
      try {
        const { data } = await systemService.getUserRequests(userToken, 1, 'pending');

        if (data.additionalDetails) {
          const last = data.additionalDetails.pop();

          setLastLeave(last);
        }
      } catch (error) {
        setLastLeave(null);
      }
    };

    if (!isLastLeaveLoaded && isRequestMade && userRole.length > 0) {
      const isAdminRole = userRole.find((role) => role.title === 'admin' || role.title === 'approver');
      const resolver = !!isAdminRole;

      setIsResolver(resolver);

      if (resolver) {
        getAllRequests();
      } else {
        getUserRequests();
      }

      setIsLastLeaveLoaded(true);
    }
  }, [isLastLeaveLoaded, userToken, setLastAnnouncement,
    isLoaded, userId, userRole, isRequestMade,
    setNotification, t, setNotificationType, isResolver]);

  return (
    <SystemLayout>
      <section className="dashboard">
        <div className="dashboard__card">
          <h3 className="dashboard__card-title">
            {t('dashboardPage.latestLabel')}
          </h3>
          <div className="dashboard__wrapper">
            {lastAnnouncement ? (
              <AnnoucementCard
                isAdmin={false}
                dashboard
                data={lastAnnouncement}
                onClickHandler={() => {
                  history.push({
                    pathname: '/announcement-article',
                    state: {
                      id: lastAnnouncement.id,
                    },
                  })
                }}
              />
            ) : (
              <p>{t('dashboardPage.noLatestLabel')}</p>
            )}
          </div>
        </div>
        {/* <div className="dashboard__card"> // TODO: phase 2 calendar and todo
          <h3 className="dashboard__card-title">
            {t('dashboardPage.calendarLabel')}
          </h3>
        </div> */}
        <div className="dashboard__card">
          <h3 className="dashboard__card-title">
            {t('dashboardPage.upcomingLeavesLabel')}
          </h3>
          <div className="dashboard__wrapper">
            {lastLeave ? (
              <LeaveRequestItem
                dashboard
                request={lastLeave}
                isResolver={isResolver}
                onClickResolveHandler={() => history.push({
                  pathname: '/resolve-request',
                  state: { id: lastLeave.id },
                })}
                onClickSignHandler={() => history.push({
                  pathname: '/annual-leave-signing',
                  state: { request: lastLeave.id },
                })}
              />
            ) : (
              <p>{t('dashboardPage.noLeavesLabel')}</p>
            )}
          </div>
        </div>
        {/* <div className="dashboard__card"> // TODO: phase 2 calendar and todo
          <h3 className="dashboard__card-title">
            {t('dashboardPage.todosLabel')}
          </h3>
        </div> */}
      </section>
    </SystemLayout>
  );
};

export default Dashboard;
