import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Avatar from 'components/global/Avatar';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import CodeContainer from 'components/tools/CodeContainer';
import DropPlaceholder from 'components/tools/DropPlaceholder';
import Dropzone from 'components/tools/Dropzone';
import ToolButton from 'components/tools/Button';
import { UserContext } from 'context/UserContext';
import { AuthContext } from 'context/AuthContext';
import { NotificationContext } from 'context/NotificationContext';
import userService from 'services/userService';
import authService from 'services/authService';
import Arrow from 'assets/images/arrow-up.svg';
import generateSignature from 'helpers/utility/generateSignature';

import './Profile.scss';

const SPLIT_NUMBERS_VALUE = 3;

const Profile = () => {
  const {
    userId,
    userPosition,
    userRole,
    userPersonalNumber,
    userStartDate,
    userLeaveDays,
  } = useContext(UserContext);
  const { userEmail, userToken } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [phone, setPhone] = useState('');
  const [phoneInvalidInput, setPhoneInvalidInput] = useState(false);
  const [slack, setSlack] = useState('');
  const [skype, setSkype] = useState('');
  const [signature, setSignature] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSignatureExpanded, setIsSignatureExpanded] = useState(false);
  const [result, setResult] = useState({ loading: false, error: null, content: '' });
  const [image, setImage] = useState('');
  const imageInputRef = useRef('');
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');
  const isMounted = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(t('profilePage.pageTitle'));

  const handleFilesDrop = async (files) => {
    if (files.length > 0) {
      const file = files[0];

      if (file) {
        try {
          const formData = new FormData();

          formData.append('file', file);
          formData.append('userId', userId);

          await userService.uploadUserImage(userToken, formData);

          setIsLoaded(false);
        } catch (error) {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }

        try {
          const reader = new FileReader();

          reader.addEventListener('loadend', () => setResult({ content: reader.result }));
          reader.readAsDataURL(file);
        } catch (error) {
          setResult({
            error,
            loading: false,
            content: error.message,
          });
        }
      }
    }
  };

  const onSaveChangesHandler = async () => {
    if (!phone) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else if (phoneInvalidInput) {
      setFormNotification(t('notification.invalidPhoneErrorMessage'));
    } else {
      setFormNotification('');
    }

    const data = {
      firstName,
      middleName,
      lastName,
      jobPosition: userPosition,
      role: userRole,
      email: userEmail,
      personalNumber: userPersonalNumber,
      employeeStartDate: userStartDate,
      availableAnnualLeaveDays: userLeaveDays,
      phone,
    };

    if (slack) {
      data.slack = slack;
    }

    if (skype) {
      data.skype = skype;
    }

    if (!phoneInvalidInput) {
      setIsLoading(true);

      try {
        await userService.updateProfile(userId, data, userToken);

        setIsLoaded(false);

        setNotification(t('notification.profileUpdateSuccessMessage'));
        setNotificationType('success');
        resetNotification();

        setIsLoading(false);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }

        setIsLoading(false);
      }
    }
  };

  const onClickRestPasswordHandler = async () => {
    try {
      const { data } = await authService.restorePassword(userEmail);

      if (data) {
        setNotification(t('notification.passwordResetSuccessMessage'));
        setNotificationType('success');
        resetNotification();
      }
    } catch (error) {
      setNotification(t('notification.errorMessage'));
      setNotificationType('error');
    }
  };

  useEffect(() => {
    setPhoneInvalidInput(phone === '');
  }, [phone]);

  useEffect(() => {
    setImage(result.content);
  }, [result]);

  useEffect(() => {
    const formatPhoneInput = (array, splitNumbersValue) => {
      const plusSymbol = array.shift();
      const formattedArray = [];

      for (let i = 0; i < array.length; i += 1) {
        if (i % splitNumbersValue === 0 && i !== 0) {
          formattedArray.push(' ');
        }

        formattedArray.push(array[i]);
      }

      return `${plusSymbol}${formattedArray.join('')}`;
    }

    if (isSignatureExpanded) {
      const formattedPhone = formatPhoneInput(phone.split(''), SPLIT_NUMBERS_VALUE);

      const data = {
        firstName,
        lastName,
        position: userPosition,
        phoneHref: phone,
        phone: formattedPhone,
      };

      const template = generateSignature(data);

      setSignature(template);
    }
  }, [isSignatureExpanded, phone, userPosition, userEmail, firstName, lastName]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const { data } = await userService
          .getUserData(userId, userToken);

        setImage(data.additionalDetails.profilePicture);
        setPhone(data.additionalDetails.phone);
        setSlack(data.additionalDetails.slack ? data.additionalDetails.slack : '');
        setSkype(data.additionalDetails.skype ? data.additionalDetails.skype : '');
        setFirstName(data.additionalDetails.firstName);
        setMiddleName(data.additionalDetails.middleName);
        setLastName(data.additionalDetails.lastName);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded) {
      getUserData();

      setIsLoaded(true);
    }
  }, [isLoaded, setNotification, setNotificationType, t, userId, userToken]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);

  return (
    <SystemLayout>
      <section className="profile-page">
        <div className="profile-page__wrapper">
          <div>
            <span className="profile-page__title">{t('profilePage.avatarMessage')}</span>
            <Button
              onClickHandler={() => imageInputRef.current.click()}
              additionalClass="profile-page__upload-button"
              title={t('profilePage.avatarInputButtonTitle')}
            />
            <input
              ref={imageInputRef}
              id="file-input"
              type="file"
              name="name"
              className="profile-page__input--hidden"
              onChange={(event) => event.target.files && handleFilesDrop(event.target.files)}
            />
          </div>
          <div className="profile-page__avatar-wrapper">
            <Dropzone accepts="*" onDrop={handleFilesDrop} avatar />
            <DropPlaceholder
              additionalClass={!result.content ? 'fullscreenDrop profile-page__dropzone' : 'profile-page__dropzone'}
              onFileAdd={(file) => handleFilesDrop([file])}
              accepts="*"
              avatar
            >
              <Avatar
                additionalClass="profile-page__avatar"
                imageUrl={image}
              />
            </DropPlaceholder>
            <div className="profile-page__details">
              <p className="profile-page__detail">{`${firstName} ${lastName}`}</p>
              <p className="profile-page__detail">{userPosition}</p>
              <p className="profile-page__detail">{userEmail}</p>
              <FormInput
                system
                isInvalidInput={phoneInvalidInput}
                additionalClass="profile-page__input profile-page__input--phone"
                type="text"
                name="phone"
                id="phone"
                placeholder={t('profilePage.phoneInputLabel')}
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </div>
          </div>
          <div className={`profile-page__signature ${isSignatureExpanded ? 'profile-page__signature--expanded' : ''}`}>
            <button
              type="button"
              className="profile-page__signature-button"
              onClick={() => setIsSignatureExpanded(!isSignatureExpanded)}
            >
              <p className="profile-page__title">{t('profilePage.signatureLabel')}</p>
              <img src={Arrow} className="profile-page__signature-arrow" alt="" />
            </button>
            <div
              className="profile-page__template-wrapper"
            >
              <CodeContainer additionalClass="profile-page__template">{signature}</CodeContainer>
            </div>
          </div>
          <div className="profile-page__input-container">
            <p className="profile-page__title">{t('profilePage.contactInfoLabel')}</p>
            <FormInput
              system
              isInvalidInput={false}
              additionalClass="profile-page__input"
              type="text"
              name="skype"
              id="skype"
              placeholder={t('profilePage.skypeInputLabel')}
              value={skype}
              onChange={(event) => setSkype(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={false}
              additionalClass="profile-page__input"
              type="text"
              name="text"
              id="slack"
              placeholder={t('profilePage.slackInputLabel')}
              value={slack}
              onChange={(event) => setSlack(event.target.value)}
            />
            <label htmlFor="language-switcher" className="profile-page__language-switcher">
              {t('profilePage.languageSwitcherLabel')}
              <select
                id="language-switcher"
                className="profile-page__input form-input form-input--system"
                onChange={(event) => i18n.changeLanguage(event.target.value)}
              >
                <option value="en">EN</option>
                <option value="bg">BG</option>
              </select>
            </label>
            <ToolButton
              additionalClass="profile-page__reset-button"
              onClick={onClickRestPasswordHandler}
            >
              {t('profilePage.resetPasswordButtonTitle')}
            </ToolButton>
            <FormNotification additionalClass="profile-page__notification" message={formNotification} />
            <Button
              onClickHandler={onSaveChangesHandler}
              additionalClass="profile-page__save-button"
              title={t('profilePage.saveChangesButtonTitle')}
              loading={isLoading}
            />
          </div>
        </div>
      </section>
    </SystemLayout>
  );
};

export default Profile;
