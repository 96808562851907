import React from 'react';
import PropTypes from 'prop-types';

import './FormInput.scss';

const FormInput = ({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  additionalClass,
  isInvalidInput,
  system,
}) => (
  <input
    className={`form-input ${isInvalidInput ? 'form-input--invalid' : ''} ${additionalClass || ''} ${system ? 'form-input--system' : ''}`}
    type={type}
    name={name}
    id={id}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
);

FormInput.defaultProps = {
  additionalClass: '',
  system: false,
};

FormInput.propTypes = {
  additionalClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  isInvalidInput: PropTypes.bool.isRequired,
  system: PropTypes.bool,
};

export default FormInput;
