import React from 'react';
import PropTypes from 'prop-types';

import './EditDeleteMenu.scss';

const EditDeleteMenu = ({
  additionalClass,
  onClickEditHandler,
  onClickDeleteHandler,
}) => (
  <div className={`edit-delete-menu ${additionalClass}`}>
    <button className="edit-delete-menu__button" type="button" onClick={onClickEditHandler}>
      <svg className="edit-delete-menu__icon edit-delete-menu__icon--edit" fill="none" viewBox="0 0 37 37" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M28.318 5.072l3.608 3.608a1.535 1.535 0 010 2.173l-2.821 2.822-5.782-5.782 2.822-2.82a1.517 1.517 0 011.079-.448c.4 0 .786.139 1.094.447zM4.623 26.594v5.781h5.781l17.051-17.05-5.781-5.782-17.05 17.05zm4.502 1.698H7.706v-1.419l13.968-13.967 1.418 1.418L9.125 28.292z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </button>
    <button className="edit-delete-menu__button" type="button" onClick={onClickDeleteHandler}>
      <svg className="edit-delete-menu__icon edit-delete-menu__icon--delete" fill="none" viewBox="0 0 24 32" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0h8l2 2h6v4H0V2h6l2-2zM2 28c0 2.2 1.8 4 4 4h12c2.2 0 4-1.8 4-4V8H2v20zm4-16h12v16H6V12z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </button>
  </div>
);

EditDeleteMenu.defaultProps = {
  additionalClass: '',
};

EditDeleteMenu.propTypes = {
  additionalClass: PropTypes.string,
  onClickEditHandler: PropTypes.func.isRequired,
  onClickDeleteHandler: PropTypes.func.isRequired,
};

export default EditDeleteMenu;
