import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DirectionPicker from 'components/tools/DirectionPicker';

import SliderInput from 'components/tools/SliderInput';
import Tabs from 'components/tools/Tabs';

import './GradientSettings.scss';

const DIRECTIONS = {
  'top-left': 315,
  top: 0,
  'top-right': 45,
  left: 270,
  right: 90,
  'bottom-left': 225,
  bottom: 180,
  'bottom-right': 135,
};

const GradientSettings = ({
  additionalClass, type, onTypeChange, angle, onAngleChange,
}) => {
  const { t } = useTranslation();

  const activeDirection = (type === 'linear'
      && Object.keys(DIRECTIONS).find((direction) => DIRECTIONS[direction] === angle))
    || 'top';

  return (
    <div className={`gradient-settings ${additionalClass}`}>
      <div className="gradient-settings__field">
        <div className="gradient-settings__label">{t('gradientGenerator.gradientTypeTitle')}</div>
        <Tabs
          data={[
            { value: 'linear', label: t('gradientGenerator.linearTitle') },
            { value: 'radial', label: t('gradientGenerator.radialTitle') },
          ]}
          onTabChange={onTypeChange}
          active={type}
        />
      </div>
      {type === 'linear' && (
        <>
          <div className="gradient-settings__field">
            <div className="gradient-settings__label">{t('gradientGenerator.directionTitle')}</div>
            <DirectionPicker
              value={activeDirection}
              onChange={(direction) => onAngleChange(DIRECTIONS[direction])}
            />
          </div>
          <div className="gradient-settings__field">
            <div className="gradient-settings__label">{t('gradientGenerator.directionInputTitle')}</div>
            <SliderInput value={angle} onChange={onAngleChange} min={0} max={360} trackSize={133} />
          </div>
        </>
      )}
    </div>
  );
};

GradientSettings.defaultProps = {
  additionalClass: '',
  angle: 0,
};

GradientSettings.propTypes = {
  additionalClass: PropTypes.string,
  type: PropTypes.oneOf(['linear', 'radial']).isRequired,
  onTypeChange: PropTypes.func.isRequired,
  angle: PropTypes.number,
  onAngleChange: PropTypes.func.isRequired,
};

export default GradientSettings;
