import React, { useState, useEffect } from 'react';
import oc from 'open-color';
import Color from 'color';
import { useDocumentTitle, useLocalStorage } from 'xooks';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Settings from './Settings';
import TrianglePreview from './TrianglePreview';
import Code from './Code';

import './TriangleGenerator.scss';

const predefinedSizes = {
  sm: { width: 10, height: 6 },
  md: { width: 22, height: 12 },
  lg: { width: 54, height: 36 },
  xl: { width: 128, height: 94 },
};

const INITIAL_VALUES = {
  direction: 'bottom',
  width: predefinedSizes.lg.width,
  height: predefinedSizes.lg.height,
  color: oc.violet[7],
  theme: 'light',
};

const getActivePredefinedSize = ({ width, height }) => Object.keys(predefinedSizes).find(
  (size) => predefinedSizes[size].width === width && predefinedSizes[size].height === height,
);

const TriangleGenerator = () => {
  const { t } = useTranslation();
  const ls = useLocalStorage({ key: 'triangle-generator', delay: 1000 });
  const initialValues = ls.retrieve() || INITIAL_VALUES;

  const [direction, onDirectionChange] = useState(initialValues.direction);
  const [width, onWidthChange] = useState(initialValues.width);
  const [height, onHeightChange] = useState(initialValues.height);
  const [color, onColorChange] = useState(initialValues.color);
  const [theme, setTheme] = useState(initialValues.theme);

  useDocumentTitle(t('triangleGenerator.pageTitle'));

  useEffect(() => {
    ls.save({
      direction, width, height, color, theme,
    });
    return ls.cancel;
  }, [direction, width, height, color, theme, ls]);

  const toggleTheme = () => setTheme((current) => (current === 'light' ? 'dark' : 'light'));

  const setPredefinedSize = (size) => {
    if (size in predefinedSizes) {
      const values = predefinedSizes[size];

      onWidthChange(values.width);
      onHeightChange(values.height);
    }
  };

  const handleColorChange = (value) => {
    onColorChange(value);

    if (Color(value).isLight()) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  const values = {
    predefinedSizes,
    activePredefinedSize: getActivePredefinedSize({ width, height }),
    direction,
    height,
    width,
    color,
  };

  const handlers = {
    onDirectionChange,
    onWidthChange,
    onHeightChange,
    setPredefinedSize,
    onColorChange: handleColorChange,
  };

  return (
    <SystemLayout>
      <div className="triangle-generator">
        <div className="triangle-generator__row">
          <Settings values={values} handlers={handlers} />
        </div>
        <div className="triangle-generator__row">
          <TrianglePreview
            values={values}
            theme={theme}
            onThemeToggle={toggleTheme}
          />
        </div>
        <div className="triangle-generator__row">
          <Code values={values} />
        </div>
      </div>
    </SystemLayout>
  );
};

export default TriangleGenerator;
