import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useDocumentTitle, useLocalStorage } from 'xooks';
import prettier from 'prettier/standalone';
import prettierBabel from 'prettier/parser-babel';
import svg2jsx from 'svg-to-jsx';
import optimize from 'svgo-browser/lib/optimize';
import { useTranslation } from 'react-i18next';

import SvgInput from 'components/tools/SvgInput';
import SystemLayout from 'layouts/SystemLayout/SystemLayout';
import processSvgFile from 'helpers/utility/svg/provcessSvgFiles';
import Output from './Output/Output';

const SvgToJsx = () => {
  const { t } = useTranslation();
  const ls = useLocalStorage({ key: 'svg-to-jsx', delay: 1000 });
  const transmittedValue = useLocalStorage({ key: 'conversion-after-compression/jsx' });
  const [value, setValue] = useState(transmittedValue.retrieveAndClean() || ls.retrieve() || '');
  const [result, setResult] = useState({ loading: false, error: null, content: null });

  useDocumentTitle(t('navigation.svgToJsxPageTitle'));

  const generateComponent = (svg) => `import React from 'react';\n\nexport default function SvgComponent() { return ${svg} }`;

  const generateJsx = useCallback((input) => {
    optimize(input)
      .then((content) => svg2jsx(content))
      .then((svg) => prettier.format(generateComponent(svg), { parser: 'babel', plugins: [prettierBabel] }))
      .then((code) => setResult({
        loading: false,
        error: null,
        content: code,
      }))
      .catch((error) => setResult({ loading: false, error, content: null }));
  }, []);

  useLayoutEffect(() => {
    if (value.trim().length > 0) {
      setResult({ loading: true, content: null, error: null });
      generateJsx(value);
    }
  }, [value, generateJsx]);

  const handleChange = (text) => {
    setValue(text);
    ls.save(text);

    setResult({ loading: true, content: null, error: null });
    generateJsx(text);
  };

  const handleFilesDrop = (files) => {
    if (files.length > 0) {
      processSvgFile(files[0]).then((file) => handleChange(file.text));
    }
  };

  return (
    <SystemLayout>
      <div>
        <SvgInput
          value={value}
          onChange={handleChange}
          errors={result.error && value.trim().length > 0 ? ['input file'] : []}
          onFilesDrop={handleFilesDrop}
          dropLabel={t('svgToJsx.dropLabel')}
        />
        <Output data={result} />
      </div>
    </SystemLayout>
  );
};

export default SvgToJsx;
