import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/global/Button';

import './ConfirmModal.scss';

const ConfirmModal = ({
  message,
  onClickConfirmHandler,
  onClickCancelHandler,
  confirmButtonTitle,
  cancelButtonTitle,
}) => (
  <div className="modal">
    <div className="modal__wrapper">
      <p className="modal__message">{message}</p>
      <div className="modal__button-wrapper">
        <Button
          additionalClass="modal__button-delete button--delete"
          title={confirmButtonTitle}
          onClickHandler={() => onClickConfirmHandler()}
        />
        <Button
          title={cancelButtonTitle}
          onClickHandler={() => onClickCancelHandler()}
        />
      </div>
    </div>
  </div>
);

ConfirmModal.propTypes = {
  message: PropTypes.string.isRequired,
  confirmButtonTitle: PropTypes.string.isRequired,
  cancelButtonTitle: PropTypes.string.isRequired,
  onClickConfirmHandler: PropTypes.func.isRequired,
  onClickCancelHandler: PropTypes.func.isRequired,
};

export default ConfirmModal;
