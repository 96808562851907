import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/global/Avatar';
import Button from 'components/global/Button';
import FormInput from 'components/global/FormInput';
import FormNotification from 'components/global/FormNotification';

import './RestorePasswordForm.scss';

const RestorePasswordForm = ({
  title,
  buttonTitle,
  emailLabel,
  notificationMessage,
  onClickHandler,
  successMessage,
  redirectButtonTitle,
  onClickRedirectHandler,
}) => {
  const [email, setEmail] = useState('');
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const onLoginHandler = () => {
    setEmailInvalidInput(email === '');

    if (email) {
      onClickHandler(email);
      setIsPasswordReset(true);
    }
  };

  return (
    <section className="reset-password-form">
      <div className="reset-password-form__wrapper">
        <h2 className="reset-password-form__title">{title}</h2>
        <form className="reset-password-form__container">
          <Avatar
            additionalClass="reset-password-form__avatar"
            circle
          />
          {!isPasswordReset ? (
            <>
              <div className="reset-password-form__input-container">
                <label
                  htmlFor="email"
                  className={`reset-password-form__label ${emailInvalidInput ? 'reset-password-form__label--invalid' : ''}`}
                >
                  {emailLabel}
                </label>
                <FormInput
                  isInvalidInput={emailInvalidInput}
                  additionalClass="reset-password-form__input"
                  type="email"
                  name="email"
                  id="email"
                  placeholder={emailLabel}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <FormNotification additionalClass="reset-password-form__notificaiton" message={notificationMessage} />
              </div>

              <Button
                title={buttonTitle}
                onClickHandler={onLoginHandler}
              />
            </>
          ) : (
            <div className="reset-password-form__success-message-wrapper">
              <p className="reset-password-form__success-message">{`${successMessage} ${email}.`}</p>
              <Button
                title={redirectButtonTitle}
                onClickHandler={onClickRedirectHandler}
              />
            </div>
          )}
        </form>
      </div>
    </section>
  );
};

RestorePasswordForm.defaultProps = {
  emailLabel: '',
};

RestorePasswordForm.propTypes = {
  emailLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  redirectButtonTitle: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  onClickRedirectHandler: PropTypes.func.isRequired,
};

export default RestorePasswordForm;
