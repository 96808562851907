import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import systemService from 'services/systemService';
import { AuthContext } from 'context/AuthContext';
import { NotificationContext } from 'context/NotificationContext';

import './HomeOfficeRequestForm.scss';

const MAX_CHARACTERS_LIMIT = 10000;

const HomeOfficeRequestForm = () => {
  const history = useHistory();
  const { userToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState('');
  const [startDateInvalidInput, setStartDateInvalidInput] = useState(false);
  const [days, setDays] = useState(1);
  const [daysInvalidInput, setDaysInvalidInput] = useState(false);
  const [reason, setReason] = useState('');
  const [reasonInvalidInput, setReasonInvalidInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');

  useDocumentTitle(t('annualLeaveRequestPage.pageTitle'));

  const onClickButtonHandler = async () => {
    if (reason.split('').length > MAX_CHARACTERS_LIMIT) {
      setFormNotification(t('notification.characterLimitErrorMessage'));
    } else if (!startDate || !reason || days < 0) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else {
      setNotification('');
    }

    setStartDateInvalidInput(startDate === '');
    setDaysInvalidInput(days < 1);
    setReasonInvalidInput(reason === ''
      || reason.split('').length > MAX_CHARACTERS_LIMIT);

    if (startDate !== ''
      && days > 0
      && reason !== '' && reason.split('').length < MAX_CHARACTERS_LIMIT) {
      setIsLoading(true);

      try {
        const { data } = await systemService.createRequest({
          type: 'home-office',
          days,
          startingFrom: startDate,
          reason,
        }, userToken);

        if (data.responseDescription) {
          setNotification(t('notification.addRequestSuccessMessage'));
          setNotificationType('success');
          resetNotification();

          setIsLoading(false);

          history.push('/leaves');
        }
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }

        setIsLoading(false);
      }
    }
  };

  return (
    <SystemLayout>
      <section className="home-office-request-form">
        <form className="home-office-request-form__container">
          <FormInput
            system
            isInvalidInput={startDateInvalidInput}
            additionalClass="home-office-request-form__input"
            type="date"
            id="start-date"
            name="start-date"
            value={startDate}
            placeholder={t('homeOfficeRequestPage.startDateLabel')}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <FormInput
            system
            isInvalidInput={daysInvalidInput}
            additionalClass="home-office-request-form__input"
            type="number"
            id="days"
            min="1"
            name="days"
            value={days}
            placeholder={t('homeOfficeRequestPage.daysLabel')}
            onChange={(e) => setDays(e.target.value)}
          />
          <FormInput
            system
            isInvalidInput={reasonInvalidInput}
            additionalClass="home-office-request-form__input"
            type="text"
            id="reason"
            name="reason"
            value={reason}
            placeholder={t('homeOfficeRequestPage.reasonLabel')}
            onChange={(e) => setReason(e.target.value)}
          />
          <FormNotification additionalClass="home-office-request-form__notification" message={formNotification} />
          <Button
            title={t('homeOfficeRequestPage.buttonTitle')}
            onClickHandler={onClickButtonHandler}
            loading={isLoading}
          />
        </form>
      </section>
    </SystemLayout>
  );
};

export default HomeOfficeRequestForm;
