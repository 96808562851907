import React from 'react';
import PropTypes from 'prop-types';

const Triangle = ({ style }) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 1200 120"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path d="M60 120L0 0h120L60 120zm120 0L120 0h120l-60 120zm120 0L240 0h120l-60 120zm120 0L360 0h120l-60 120zm120 0L480 0h120l-60 120zm120 0L600 0h120l-60 120zm120 0L720 0h120l-60 120zm120 0L840 0h120l-60 120zm120 0L960 0h120l-60 120zm120 0L1080 0h120l-60 120z" />
  </svg>
);

Triangle.propTypes = {
  style: PropTypes.shape({
    fill: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.string,
  }).isRequired,
};

export default Triangle;
