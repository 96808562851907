import Fuse from 'fuse.js';

import arrows from './arrows-symbols';
import fractions from './fractions-symbols';
import math from './math-symbols';
import punctuation from './punctuation-symbols';
import special from './special-symbols';
import mostUsed from './most-used-symbols';

const f = (data) => new Fuse(data, { keys: ['name'] });

export default {
  'symbolsCollection.mostUsedTitle': { data: mostUsed, fuse: f(mostUsed) },
  'symbolsCollection.arrowsTitle': { data: arrows, fuse: f(arrows) },
  'symbolsCollection.mathTitle': { data: [...fractions, ...math], fuse: f([...fractions, ...math]) },
  'symbolsCollection.punctuationTitle': { data: punctuation, fuse: f(punctuation) },
  'symbolsCollection.specialTitle': { data: special, fuse: f(special) },
};
