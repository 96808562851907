import axios from 'axios';

const API_URL = 'https://internal-system.rbd.rocks/';

const apiInstance = axios.create({
  headers: {
    post: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
});

const API = {
  get: async (
    url,
    params,
    token,
  ) => {
    const config = {
      ...params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const request = await apiInstance.get(`${API_URL}${url}`, config);

    return request;
  },

  post: async (
    url,
    params,
    token,
    isFormData,
  ) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (isFormData) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
        },
      };
    }

    const request = await apiInstance.post(`${API_URL}${url}`, params, config);

    return request;
  },
  delete: async (
    url,
    params,
    token,
  ) => {
    const config = {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const request = await apiInstance.delete(`${API_URL}${url}`, config);

    return request;
  },
};

export default API;
