import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import useLocalStorage from 'hooks/useLocalStorage';

export const AuthContext = createContext({
  userToken: '',
  setUserToken: () => {},
  userEmail: '',
  setUserEmail: () => {},
  isLogged: false,
  setIsLogged: () => {},
  isNotification: false,
  setIsNotification: () => {},
});

const AuthContextProvider = ({ children }) => {
  const [userToken, setUserToken] = useLocalStorage('token', null);
  const [userEmail, setUserEmail] = useLocalStorage('userEmail', null);
  const [isLogged, setIsLogged] = useLocalStorage('isLogged', false);
  const [isNotification, setIsNotification] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        userToken,
        setUserToken,
        userEmail,
        setUserEmail,
        isLogged,
        setIsLogged,
        isNotification,
        setIsNotification,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
