import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import CompressedResult from '../CompressedResult/CompressedResult';

import './Output.scss';

const Output = ({ results }) => {
  const { t } = useTranslation();

  const files = Object.keys(results).sort((a, b) => results[b].queue - results[a].queue);

  if (files.length === 0) {
    return null;
  }

  const items = files.map((fileKey) => {
    if (!results[fileKey].content) {
      return null;
    }

    if (results[fileKey].error) {
      return (
        <Background key={fileKey} additionalClass="svg-compressor-output">
          {t('svgCompressor.errorMessage')}
        </Background>
      );
    }

    return results[fileKey].content
      && <CompressedResult key={fileKey} content={results[fileKey].content} fileKey={fileKey} />;
  });

  return items;
};

Output.propTypes = {
  results: PropTypes.objectOf(
    PropTypes.shape({
      content: PropTypes.string,
      error: PropTypes.string,
      loading: PropTypes.bool,
      queue: PropTypes.number.isRequired,
    }),
  ),
};

export default Output;
