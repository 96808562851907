import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Stage, Layer, Line } from 'react-konva';

import Button from 'components/tools/Button';

import './DrawingArea.scss';

const DrawingArea = ({
  onSaveImage,
  signButtonTitle,
  resetButtonTitle,
}) => {
  const [lines, setLines] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const stageRef = useRef(null);

  const handleMouseDown = (event) => {
    setIsDrawing(true);

    const pos = event.target.getStage().getPointerPosition();

    setLines([...lines, { points: [pos.x, pos.y] }]);
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) {
      return;
    }

    const stage = event.target.getStage();
    const point = stage.getPointerPosition();
    const lastLine = lines[lines.length - 1];

    if (lastLine) {
      lastLine.points = lastLine.points.concat([point.x, point.y]);

      lines.splice(lines.length - 1, 1, lastLine);

      setLines(lines.concat());
    }
  };

  return (
    <div className="drawing-area">
      <div className="drawing-area__buttons">
        <Button
          additionalClass="drawing-area__button"
          onClick={() => onSaveImage(stageRef.current.toDataURL())}
        >
          {signButtonTitle}
        </Button>
        <Button
          additionalClass="drawing-area__button"
          onClick={() => setLines([])}
        >
          {resetButtonTitle}
        </Button>
      </div>
      <div className="drawing-area__background">
        <Stage
          width={300}
          height={300}
          onMouseDown={handleMouseDown}
          onMousemove={handleMouseMove}
          onMouseup={() => setIsDrawing(false)}
          ref={stageRef}
        >
          <Layer>
            {lines.map((line) => (
              <Line
                key={uuid()}
                points={line.points}
                stroke="#000"
                strokeWidth={2}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                }
              />
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

DrawingArea.propTypes = {
  onSaveImage: PropTypes.func.isRequired,
  signButtonTitle: PropTypes.string.isRequired,
  resetButtonTitle: PropTypes.string.isRequired,
};

export default DrawingArea;
