import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SliderInput from 'components/tools/SliderInput';
import Background from 'components/tools/Background';
import Button from 'components/tools/Button';

import './Settings.scss';

const Settings = ({
  darken,
  onDarkenChange,
  saturation,
  onSaturationChange,
  onPaletteLoad,
  onAllRemove,
  canRemove,
}) => {
  const { t } = useTranslation();

  return (
    <Background additionalClass="colors-shade-generator-settings">
      <div>
        <div className="colors-shade-generator-settings__slider">
          <h3 className="colors-shade-generator-settings__label">{t('colorShadesGenerator.darkLigthLabel')}</h3>
          <SliderInput
            value={Math.round(darken * 100)}
            onChange={(value) => onDarkenChange(value / 100)}
            min={1}
            max={100}
          />
        </div>
        <div className="colors-shade-generator-settings__slider">
          <h3 className="colors-shade-generator-settings__label">{t('colorShadesGenerator.saturationLabel')}</h3>
          <SliderInput
            value={Math.round(saturation * 100)}
            onChange={(value) => onSaturationChange(value / 100)}
            min={-50}
            max={50}
          />
        </div>
      </div>
      <div className="colors-shade-generator-settings__buttons">
        <Button additionalClass="control" onClick={onPaletteLoad}>
          {t('colorShadesGenerator.loadPalletLabel')}
        </Button>
        {canRemove && (
          <Button additionalClass="control" onClick={onAllRemove} theme="red">
            {t('colorShadesGenerator.removeAllLabel')}
          </Button>
        )}
      </div>
    </Background>
  );
};

Settings.propTypes = {
  darken: PropTypes.number.isRequired,
  onDarkenChange: PropTypes.func.isRequired,
  saturation: PropTypes.number.isRequired,
  onSaturationChange: PropTypes.func.isRequired,
  onPaletteLoad: PropTypes.func.isRequired,
  onAllRemove: PropTypes.func.isRequired,
  canRemove: PropTypes.bool.isRequired,
};

export default Settings;
