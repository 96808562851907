import React from 'react';
import PropTypes from 'prop-types';

import './FormNotification.scss';

const FormNotificaiton = ({ message, additionalClass }) => (
  <span className={`form-notification ${additionalClass || ''}`}>{message}</span>
);

FormNotificaiton.defaultProps = {
  additionalClass: '',
};

FormNotificaiton.propTypes = {
  message: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

export default FormNotificaiton;
