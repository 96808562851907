import React, {
  useContext,
  useState,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import { NotificationContext } from 'context/NotificationContext';
import { AuthContext } from 'context/AuthContext';
import systemService from 'services/systemService';
import SystemLayout from 'layouts/SystemLayout';
import AnnouncementForm from '../AnnouncementForm/AnnouncementForm';

const AddAnnoucement = () => {
  const history = useHistory();
  const { userToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [announcementId, setAnnouncementId] = useState(0);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);

  useDocumentTitle(t('addAnnouncementPage.pageTitle'));

  const onErrorHandler = () => {
    setNotification(t('notification.errorMessage'));
    setNotificationType('error');
  };

  const onPressAddAnnouncement = async (data, image, files) => {
    try {
      setIsLoading(true);

      const response = await systemService.createAnnouncement(userToken, data);

      setUploadImage(image);
      setUploadFiles(files);
      setIsDataUploaded(true);
      setAnnouncementId(response.data.additionalDetails.id);

      setNotification(t('notification.addAnnouncementSuccessMessage'));
      setNotificationType('success');
      resetNotification();
    } catch (error) {
      onErrorHandler();

      setIsLoading(false);
    }
  };

  useEffect(() => {
    const uploadImageRequest = async () => {
      if (announcementId !== 0) {
        try {
          const formData = new FormData();

          formData.append('file', uploadImage);
          formData.append('announcementId', announcementId);

          const response = await systemService.uploadAnnouncementImage(userToken, formData);

          if (response.status === 200) {
            setIsImageUploaded(true);
          }
        } catch (error) {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (isDataUploaded && !isImageUploaded) {
      uploadImageRequest();
    }
  }, [announcementId, isDataUploaded, isImageUploaded,
    setNotification, setNotificationType, t, uploadImage, userToken]);

  useEffect(() => {
    const uploadFilesRequest = () => {
      if (uploadFiles.length > 0 && announcementId !== 0) {
        uploadFiles.forEach(async (uploadFile) => {
          const fileFormData = new FormData();

          fileFormData.append('file', uploadFile);
          fileFormData.append('announcementId', announcementId);

          try {
            const response = await systemService
              .uploadAnnouncementAttachment(userToken, fileFormData);

            if (response.status === 200) {
              setIsFileUploaded(true);
            }
          } catch (error) {
            setNotification(t('notification.errorMessage'));
            setNotificationType('error');
          }
        })
      } else {
        setIsFileUploaded(true);
      }
    };

    if (isDataUploaded && isImageUploaded && !isFileUploaded) {
      uploadFilesRequest();
    }
  }, [announcementId, isDataUploaded, isFileUploaded,
    isImageUploaded, setNotification, setNotificationType,
    t, uploadFiles, userToken]);

  useEffect(() => {
    if (isDataUploaded && isImageUploaded && isFileUploaded) {
      setIsLoading(false);

      history.push('/announcements-list');
    }
  }, [history, isDataUploaded, isFileUploaded, isImageUploaded]);

  return (
    <SystemLayout>
      <AnnouncementForm
        onClickHandler={onPressAddAnnouncement}
        buttonTitle={t('addAnnouncementPage.addButtonTitle')}
        submitButtonLoading={isLoading}
      />
    </SystemLayout>
  );
};

export default AddAnnoucement;
