import React from 'react';
import PropTypes from 'prop-types';

import useWindowWidth from 'hooks/useWindowWidth';

import './Tabs.scss';

const TABLET_BREAKPOINT = 992;
const Tabs = ({
  additionalClass,
  data,
  active,
  onTabChange,
  noBorderOnMobile,
}) => {
  const width = useWindowWidth();
  const mobileBorderHelper = width < TABLET_BREAKPOINT && noBorderOnMobile;

  const tabs = data.map((tab) => (
    <button
      type="button"
      key={tab.value}
      onClick={() => onTabChange(tab.value)}
      className={`tabs__button ${tab.value} ${active === tab.value ? 'tabs__button--active' : ''}`}
    >
      {tab.label}
    </button>
  ));

  return <div className={`tabs ${additionalClass} ${mobileBorderHelper ? 'tabs--no-border' : ''}`}>{tabs}</div>;
};

Tabs.defaultProps = {
  additionalClass: '',
  active: '',
  noBorderOnMobile: false,
};

Tabs.propTypes = {
  additionalClass: PropTypes.string,
  active: PropTypes.string,
  onTabChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  noBorderOnMobile: PropTypes.bool,
};

export default Tabs;
