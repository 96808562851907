import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './Dropzone.scss';

const preventDefault = (event) => event.preventDefault();

const Dropzone = ({ onDrop, accepts, avatar }) => {
  const [dragOver, setDragOver] = useState(false);
  const { t } = useTranslation();
  const dragLeaveTimeout = useRef();
  const allowAll = accepts === '*';

  useLayoutEffect(() => {
    const handleDrop = (event) => {
      event.preventDefault();

      clearTimeout(dragLeaveTimeout.current);
      setDragOver(false);
      onDrop([...event.dataTransfer.files]
        .filter((file) => allowAll || accepts.includes(file.type)));
    };

    const onDragOver = () => {
      clearTimeout(dragLeaveTimeout.current);

      setDragOver(true);
    };

    const onDragLeave = () => {
      clearTimeout(dragLeaveTimeout.current);

      dragLeaveTimeout.current = setTimeout(() => {
        setDragOver(false);
      }, 20);
    };

    document.addEventListener('dragover', preventDefault, false);
    document.addEventListener('drop', handleDrop, false);
    document.addEventListener('dragover', onDragOver, false);
    document.addEventListener('dragleave', onDragLeave, false);

    return () => {
      document.removeEventListener('dragover', preventDefault, false);
      document.removeEventListener('drop', handleDrop, false);
      document.removeEventListener('dragover', onDragOver, false);
      document.removeEventListener('dragleave', onDragLeave, false);
    };
  }, [accepts, allowAll, onDrop]);

  return dragOver ? (
    <div className="dropzone">
      {!avatar && (
        <h3 className="dropzone__title">{t('dropZone.dropZoneTitle')}</h3>
      )}
    </div>
  ) : null;
};

Dropzone.defaultProps = {
  accepts: 'image/svg+xml',
  avatar: false,
};

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  avatar: PropTypes.bool,
  accepts: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default Dropzone;
