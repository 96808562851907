import Color from 'color';

const generateShades = ({
  steps,
  value,
  saturation,
  darken,
}) => {
  let dark = Color(value);
  let light = Color(value);

  const shades = [dark.hex()];
  const tints = [];

  for (let i = 1; i < steps; i += 1) {
    dark = dark.darken(darken).saturate(saturation);
    light = light.lighten(darken).saturate(saturation);

    if (dark.hex().toLowerCase() !== '#000000') {
      shades.push(dark.hex())
    }

    if (light.hex().toLowerCase() !== '#ffffff') {
      tints.push(light.hex())
    }
  }

  return [...tints.reverse(), ...shades];
};

export default generateShades;
