import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import RouteWithSubRoutes from 'components/global/RouteWithSubRoutes';

const Tools = ({ routes }) => (
  <Switch>
    {routes.map((route) => (
      <RouteWithSubRoutes key={route.path} {...route} routes={routes} />
    ))}
  </Switch>
);

export const routeShape = PropTypes.shape({
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string,
  exact: PropTypes.bool,
  private: PropTypes.bool,
  fallback: PropTypes.node,
  component: PropTypes.elementType,
});

Tools.propTypes = {
  routes: PropTypes.arrayOf(routeShape).isRequired,
};

export default Tools;
