import React, { useState, useLayoutEffect } from 'react';
import { useDocumentTitle } from 'xooks';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Background from 'components/tools/Background';
import SettingsLabel from 'components/tools/SettingsLabel';
import CodeContainer from 'components/tools/CodeContainer';
import Header from './Header/Header';

import generateUsage from './generate-usage';
import EventInfo from './EventInfo/EventInfo';

import './EventsKeycode.scss';

const EventsKeycode = () => {
  const { t } = useTranslation();
  const [capturedEvent, setCapturedEvent] = useState(null);

  const handleEventCapture = (event) => {
    event.preventDefault();

    setCapturedEvent(event);
  };

  useDocumentTitle(t('eventsKeyCodes.pageTitle'));

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleEventCapture);

    return () => window.removeEventListener('keydown', handleEventCapture);
  }, []);

  return (
    <SystemLayout additionalClass="event-keycode-page">
      <Header event={capturedEvent} />
      {capturedEvent && (
        <div className="event-keycode-page__event">
          <div className="event-keycode-page__group">
            <SettingsLabel>{t('eventsKeyCodes.eventInfoTitle')}</SettingsLabel>
            <div className="event-keycode-page__info">
              <EventInfo className="event-keycode-page__info-item" title="event.key" value={capturedEvent.key} />
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.code"
                value={capturedEvent.code}
              />
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.which"
                value={capturedEvent.which}
                deprecation
              />
            </div>
          </div>

          <div className="event-keycode-page__group">
            <SettingsLabel>{t('eventsKeyCodes.modifierKeysTitle')}</SettingsLabel>
            <div className="event-keycode-page__info">
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.altKey"
                kbd="alt"
                value={capturedEvent.altKey}
              />
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.ctrlKey"
                kbd="ctrl"
                value={capturedEvent.ctrlKey}
              />
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.metaKey"
                kbd="cmd"
                value={capturedEvent.metaKey}
              />
              <EventInfo
                className="event-keycode-page__info-item"
                title="event.shiftKey"
                kbd="shift"
                value={capturedEvent.shiftKey}
              />
            </div>
          </div>

          <div className="event-keycode-page__group">
            <SettingsLabel>{t('eventsKeyCodes.usageTitle')}</SettingsLabel>
            <Background additionalClass="output">
              <CodeContainer>{generateUsage(capturedEvent)}</CodeContainer>
            </Background>
          </div>
        </div>
      )}
    </SystemLayout>
  );
};

export default EventsKeycode;
