import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import SettingsLabel from 'components/tools/SettingsLabel';
import directions from '../directions';
import DirectionPicker from '../../../../components/tools/DirectionPicker/DirectionPicker';
import SizePicker from '../SizePicker';
import ColorPicker from '../ColorPicker';

import './Settings.scss';

const Settings = ({ values, handlers }) => {
  const { t } = useTranslation();

  return (
    <Background className="settings">
      <div className="settings__inner">
        <div className="settings__section">
          <SettingsLabel>{t('triangleGenerator.settings.directionTitle')}</SettingsLabel>
          <DirectionPicker value={values.direction} onChange={handlers.onDirectionChange} />
        </div>

        <div className="settings__section">
          <SettingsLabel>{t('triangleGenerator.settings.sizeTitle')}</SettingsLabel>
          <SizePicker
            value={{ width: values.width, height: values.height }}
            setPredefinedSize={handlers.setPredefinedSize}
            activePredefinedSize={values.activePredefinedSize}
            onWidthChange={handlers.onWidthChange}
            onHeightChange={handlers.onHeightChange}
          />
        </div>
        <div className="settings__section">
          <SettingsLabel>{t('triangleGenerator.settings.colorTitle')}</SettingsLabel>
          <ColorPicker value={values.color} onChange={handlers.onColorChange} />
        </div>
      </div>
    </Background>
  );
};

Settings.propTypes = {
  values: PropTypes.shape({
    activePredefinedSize: PropTypes.string,
    direction: PropTypes.oneOf(directions).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    onDirectionChange: PropTypes.func.isRequired,
    onWidthChange: PropTypes.func.isRequired,
    onHeightChange: PropTypes.func.isRequired,
    onColorChange: PropTypes.func.isRequired,
    setPredefinedSize: PropTypes.func.isRequired,
  }).isRequired,
};

export default Settings;
