import React, { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import { useDocumentTitle, useLocalStorage } from 'xooks';
import { useTranslation } from 'react-i18next';

import defaultPalette from 'helpers/utility/shades/defaultPallete';
import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/tools/Button';
import ColorShadesList from './ColorShadesList/ColorShadesList';
import Settings from './Settings/Settings';

import './ColorShadesGenerator.scss';

const INITIAL_VALUES = {
  value: [{ color: '#ffffff', key: v4() }],
  saturation: -0.2,
  darken: 0.1,
};

const ColorShadesGenerator = () => {
  const { t } = useTranslation();
  const ls = useLocalStorage({ key: '@omatsuri', delay: 1000 });
  const initialValues = ls.retrieve() || INITIAL_VALUES;
  const [value, setValue] = useState(initialValues.value);
  const [saturation, setSaturation] = useState(initialValues.saturation);
  const [darken, setDarken] = useState(initialValues.darken);
  const canDelete = value.length > 1;

  useDocumentTitle(t('colorShadesGenerator.pageTitle'));

  const addColor = () => setValue((current) => [...current, { color: '#ffffff', key: v4() }]);

  const setColor = (index, color) => setValue((current) => {
    const cloned = [...current];

    cloned[index] = { ...cloned[index], color };

    return cloned;
  });

  const removeColor = (index) => setValue((current) => {
    const cloned = [...current];

    cloned.splice(index, 1);

    return cloned;
  });

  const removeAll = () => setValue([{ color: '#ffffff', key: v4() }]);
  const loadDefaultPalette = () => setValue(defaultPalette);

  useEffect(() => {
    ls.save({ value, saturation, darken });

    return ls.cancel;
  }, [value, saturation, darken, ls]);

  const colors = value.map((color, index) => (
    <ColorShadesList
      key={color.key}
      value={color.color}
      onChange={(c) => setColor(index, c)}
      onDelete={() => removeColor(index)}
      canDelete={canDelete}
      saturation={saturation}
      darken={darken}
    />
  ));

  return (
    <SystemLayout className="color-shades-generator">
      <Settings
        darken={darken}
        onDarkenChange={setDarken}
        saturation={saturation}
        onSaturationChange={setSaturation}
        onPaletteLoad={loadDefaultPalette}
        onAllRemove={removeAll}
        canRemove={canDelete}
      />
      {colors}
      <div className="color-shades-generator__button">
        <Button onClick={addColor}>
          {t('colorShadesGenerator.addColorLabel')}
        </Button>
      </div>
    </SystemLayout>
  );
};

export default ColorShadesGenerator;
