import API from 'helpers/api';

const systemService = {
  createRequest: (
    data,
    token,
  ) => API.post('api/out-of-office-request/create', data, token),
  getAllRequests: (
    token,
    page,
    status,
  ) => API.get('api/out-of-office-requests/all', {
    params: {
      page,
      status,
    },
  },
  token),
  getUserRequests: (
    token,
    userId,
    page,
    status,
  ) => API.get(`api/out-of-office-requests/user/${userId}/all`, {
    params: {
      page,
      status,
    },
  }, token),
  resolveRequest: (
    data,
    token,
  ) => API.post('api/out-of-office-request/process-request', data, token),
  sendDocument: (
    data,
    token,
  ) => API.post('api/out-of-office-request/send/file', data, token, true),
  getAllFAQ: (
    token,
  ) => API.get('api/faq/all', {}, token),
  createFAQ: (
    data,
    token,
  ) => API.post('api/faq/create', data, token),
  updateFAQ: (
    data,
    token,
  ) => API.post('api/faq/update', data, token),
  deleteFAQ: (
    id,
    token,
  ) => API.delete('api/faq/delete', { id }, token),
  createAnnouncement: (
    token,
    data,
  ) => API.post('api/company-announcement/create', data, token),
  getAnnouncement: (
    token,
    id,
  ) => API.get(`api/company-announcement/${id}`, {}, token),
  uploadAnnouncementImage: (
    token,
    data,
  ) => API.post('api/company-announcement/featured-image/upload', data, token, true),
  uploadAnnouncementAttachment: (
    token,
    data,
  ) => API.post('api/company-announcement/attachment/upload', data, token, true),
  updateAnnouncement: (
    token,
    data,
  ) => API.post('api/company-announcement/update', data, token),
  getAllAnnouncements: (
    token,
    page,
  ) => API.get('api/company-announcements/all', {
    params: {
      page,
    },
  }, token),
  deleteAnnouncement: (
    announcementId,
    token,
  ) => API.delete('api/company-announcement/delete', { announcementId }, token),
  deleteAnnouncementAttachment: (
    token,
    data,
  ) => API.delete('api/company-announcement/attachment/delete', { data }, token),
};

export default systemService;
