import React from 'react';
import PropTypes from 'prop-types';

import './Avatar.scss';

const Avatar = ({
  imageUrl,
  additionalClass,
  circle,
  small,
  member,
}) => (
  <div className={`avatar ${additionalClass} ${circle ? 'avatar--circle' : ''} ${small ? 'avatar--small' : ''} ${member ? 'avatar--member' : ''}`}>
    {imageUrl ? (
      <img src={imageUrl} alt="avatar" className="avatar__image" />
    ) : (
      <svg
        className="avatar__placeholder"
        fill="none"
        viewBox="0 0 98 94"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M97.175 82.26v10.865H.825V82.26c0-3.417 6.97-7.585 20.91-12.505 6.423-2.323 10.796-4.68 13.12-7.072 2.323-2.392 3.485-5.638 3.485-9.738 0-1.503-.752-3.178-2.255-5.023-1.504-1.844-2.597-4.339-3.28-7.482-.137-.82-.444-1.435-.923-1.845-.478-.41-.957-.683-1.435-.82-.478-.137-.956-.718-1.435-1.742-.478-1.026-.786-2.495-.922-4.408 0-1.093.17-1.982.512-2.665.342-.683.65-1.093.923-1.23l.41-.41c-.547-3.417-.957-6.423-1.23-9.02-.274-3.69 1.127-7.517 4.202-11.48S41.347.875 49 .875c7.653 0 13.051 1.982 16.195 5.945 3.143 3.963 4.51 7.79 4.1 11.48l-1.23 9.02c1.23.547 1.845 1.982 1.845 4.305-.137 1.913-.444 3.383-.923 4.407-.478 1.026-.956 1.606-1.435 1.743-.478.137-.957.41-1.435.82-.478.41-.786 1.025-.922 1.845-.547 3.28-1.606 5.808-3.178 7.585-1.572 1.777-2.357 3.417-2.357 4.92 0 4.1 1.196 7.346 3.587 9.737 2.392 2.392 6.731 4.75 13.018 7.073 13.94 4.92 20.91 9.088 20.91 12.505z"
          fillRule="evenodd"
          stroke="#000"
        />
      </svg>
    )}
  </div>
);

Avatar.defaultProps = {
  additionalClass: '',
  imageUrl: '',
  circle: false,
  small: false,
  member: false,
};

Avatar.propTypes = {
  additionalClass: PropTypes.string,
  imageUrl: PropTypes.string,
  circle: PropTypes.bool,
  small: PropTypes.bool,
  member: PropTypes.bool,
};

export default Avatar;
