import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

import Avatar from 'components/global/Avatar';
import Image from 'assets/images/announcement-image.png';
import EditDeleteMenu from 'components/global/EditDeleteMenu/EditDeleteMenu';

import './AnnouncementCard.scss';

const CHARACTER_DESCRIPTION_LIMIT = 60;
const CHARACTER_TITLE_LIMIT = 20;
const CHARACTER_DESCRIPTION_LIMIT_DASHBOARD = 250;

const AnnouncementCard = ({
  data,
  onClickHandler,
  isAdmin,
  dashboard,
  onClickEditHandler,
  onClickDeleteHandler,
}) => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);

  useEffect(() => {
    const textSizeHelper = (textValue, characterLimit, setter) => {
      const array = textValue.split('');

      if (array.length > characterLimit) {
        setter(`${array.slice(0, characterLimit).join('')}...`);
      } else {
        setter(textValue);
      }
    };

    if (data.announcement) {
      const limit = dashboard ? CHARACTER_DESCRIPTION_LIMIT_DASHBOARD : CHARACTER_DESCRIPTION_LIMIT;

      textSizeHelper(data.announcement, limit, setText);
    }

    if (data.title) {
      textSizeHelper(data.title, CHARACTER_TITLE_LIMIT, setTitle);
    }
  }, [dashboard, data.announcement, data.title]);

  return (
    <>
      {isAdmin ? (
        <div
          className={`announcement-card ${dashboard ? 'announcement-card--dashboard' : ''}`}
        >
          <div className="announcement-card__settings">
            <button type="button" className="announcement-card__settings-button" onClick={() => setIsEditMenuOpen(!isEditMenuOpen)}>
              <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="member-card__settings-icon">
                <path
                  d="M19.502 12c0 .34-.03.66-.07.98l2.11 1.65c.19.15.24.42.12.64l-2 3.46c-.09.16-.26.25-.43.25-.06 0-.12-.01-.18-.03l-2.49-1c-.52.39-1.08.73-1.69.98l-.38 2.65c-.03.24-.24.42-.49.42h-4c-.25 0-.46-.18-.49-.42l-.38-2.65c-.61-.25-1.17-.58-1.69-.98l-2.49 1a.5.5 0 01-.61-.22l-2-3.46a.505.505 0 01.12-.64l2.11-1.65a7.93 7.93 0 01-.07-.98c0-.33.03-.66.07-.98l-2.11-1.65a.493.493 0 01-.12-.64l2-3.46c.09-.16.26-.25.43-.25.06 0 .12.01.18.03l2.49 1c.52-.39 1.08-.73 1.69-.98l.38-2.65c.03-.24.24-.42.49-.42h4c.25 0 .46.18.49.42l.38 2.65c.61.25 1.17.58 1.69.98l2.49-1a.5.5 0 01.61.22l2 3.46c.12.22.07.49-.12.64l-2.11 1.65c.04.32.07.64.07.98zm-2 0c0-.21-.01-.42-.05-.73l-.14-1.13.89-.7 1.07-.85-.7-1.21-1.27.51-1.06.43-.91-.7c-.4-.3-.8-.53-1.23-.71l-1.06-.43-.16-1.13-.19-1.35h-1.39l-.2 1.35-.16 1.13-1.06.43c-.41.17-.82.41-1.25.73l-.9.68-1.04-.42-1.27-.51-.7 1.21 1.08.84.89.7-.14 1.13c-.03.3-.05.53-.05.73 0 .2.02.43.05.74l.14 1.13-.89.7-1.08.84.7 1.21 1.27-.51 1.06-.43.91.7c.4.3.8.53 1.23.71l1.06.43.16 1.13.19 1.35h1.4l.2-1.35.16-1.13 1.06-.43c.41-.17.82-.41 1.25-.73l.9-.68 1.04.42 1.27.51.7-1.21-1.08-.84-.89-.7.14-1.13c.03-.3.05-.52.05-.73zm-5.5-4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-2 4c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </button>
            {isEditMenuOpen && (
            <EditDeleteMenu
              onClickEditHandler={() => onClickEditHandler(data.id)}
              onClickDeleteHandler={() => onClickDeleteHandler(data.id)}
            />
            )}
          </div>
          <div className="announcement-card__avatar-wrapper">
            <Avatar
              circle
              small
              additionalClass="announcement-card__avatar"
              imageUrl={data.authorImage}
            />
            <div>
              <p className="announcement-card__author-name">{data.author}</p>
              <p className="announcement-card__author-position">{data.authorPosition}</p>
            </div>
          </div>
          <img src={data.featuredImage === '' ? Image : data.featuredImage} alt="" className="announcement-card__image" />
          <div className="announcement-card__text-wrapper">
            <h3>{title}</h3>
            <p>{text}</p>
            <p>{data.createdAt}</p>
          </div>
        </div>
      ) : (
        <button
          type="button"
          className={`announcement-card ${dashboard ? 'announcement-card--dashboard' : ''}`}
          onClick={onClickHandler}
        >
          <div className="announcement-card__avatar-wrapper">
            <Avatar
              circle
              small
              additionalClass="announcement-card__avatar"
              imageUrl={data.authorImage}
            />
            <div>
              <p className="announcement-card__author-name">{data.author}</p>
              <p className="announcement-card__author-position">{data.authorPosition}</p>
            </div>
          </div>
          <img src={data.featuredImage === '' ? Image : data.featuredImage} alt="" className="announcement-card__image" />
          <div className="announcement-card__text-wrapper">
            <h3>{title}</h3>
            <p>{text}</p>
            <p className="announcement-card__date">{data.createdAt}</p>
          </div>
        </button>
      )}
    </>
  );
};

AnnouncementCard.defaultProps = {
  dashboard: false,
  onClickEditHandler: () => {},
  onClickDeleteHandler: () => {},
};

AnnouncementCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    announcement: PropTypes.string,
    description: PropTypes.string,
    alertUsers: PropTypes.bool,
    featuredImage: PropTypes.string,
    author: PropTypes.string,
    authorPosition: PropTypes.string,
    authorImage: PropTypes.string,
    id: PropTypes.number,
    createdAt: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool.isRequired,
  dashboard: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
  onClickEditHandler: PropTypes.func,
  onClickDeleteHandler: PropTypes.func,
};

export default AnnouncementCard;
