import React from 'react';
import PropTypes from 'prop-types';
import { useClipboard } from 'xooks';
import { useTranslation } from 'react-i18next';

import './CursorControl.scss';

const CursorControl = ({ additionalClass, value }) => {
  const clipboard = useClipboard();
  const { t } = useTranslation();

  return (
    <button
      className={`
        cursor-item ${additionalClass} ${clipboard.copied ? 'cursor-item--active' : ''}`}
      data-content={clipboard.copied ? t('cssCursorGenerator.copiedLabel') : t('cssCursorGenerator.copyLabel')}
      type="button"
      onClick={() => clipboard.copy(value)}
      style={{ cursor: value }}
      title={t('cssCursorGenerator.buttonDescription')}
    >
      {value}
    </button>
  );
};

CursorControl.propTypes = {
  additionalClass: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CursorControl;
