import React, { useState, useEffect } from 'react';
import { useDocumentTitle, useLocalStorage } from 'xooks';
import optimize from 'svgo-browser/lib/optimize';
import { useTranslation } from 'react-i18next';

import CodeContainer from 'components/tools/CodeContainer';
import Background from 'components/tools/Background';
import SettingsLabel from 'components/tools/SettingsLabel';
import SystemLayout from 'layouts/SystemLayout';

import DropPlaceholder from 'components/tools/DropPlaceholder';
import Dropzone from 'components/tools/Dropzone';
import processSvgFile from 'helpers/utility/svg/provcessSvgFiles';

import './Base64Encoding.scss';

const generateCssExample = (content) => `.element {\n  background-image: url(${content});\n}`

const B64Encoding = () => {
  const ls = useLocalStorage({ key: 'b64-encoding', delay: 500 });
  const [result, setResult] = useState({ loading: false, error: null, content: ls.retrieve() });
  const transmittedValue = useLocalStorage({ key: 'conversion-after-compression/b64' });
  const [encodedResult, setEndcodedResult] = useState(null);
  const { t } = useTranslation();

  useDocumentTitle(t('base64Encoding.pageTitle'));

  const handleFilesDrop = (files) => {
    if (files.length > 0) {
      const file = files[0];

      if (file) {
        if (file.type === 'image/svg+xml') {
          processSvgFile(file)
            .then((code) => optimize(code))
            .then((code) => {
              setResult({ content: `data:image/svg+xml;base64,${btoa(code)}` });
            })
            .catch((error) => setResult({ error }));
        }

        try {
          const reader = new FileReader();

          reader.addEventListener('loadend', () => setResult({ content: reader.result }));
          reader.readAsDataURL(file);
        } catch (error) {
          setResult({
            error,
            loading: false,
            content: error.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    const transmittedContent = transmittedValue.retrieveAndClean();

    if (transmittedContent) {
      setResult({ content: transmittedContent });
    }

    if (result.content) {
      ls.save(result.content);
      setEndcodedResult(result.content);
    }
  }, [ls, result.content, transmittedValue]);

  return (
    <SystemLayout>
      <Dropzone accepts="*" onDrop={handleFilesDrop} />
      <DropPlaceholder
        className={!result.content ? 'fullscreenDrop' : ''}
        onFileAdd={(file) => handleFilesDrop([file])}
        accepts="*"
      >
        {t('base64Encoding.dropZoneTitle')}
      </DropPlaceholder>
      {encodedResult && (
        <Background additionalClass="base64-encoding">
          <div className="base64-encoding__section">
            <SettingsLabel>{t('base64Encoding.resultLabel')}</SettingsLabel>
            <CodeContainer>{encodedResult}</CodeContainer>
          </div>

          <div className="base64-encoding__section">
            <SettingsLabel>{t('base64Encoding.backgroundLabel')}</SettingsLabel>
            <CodeContainer>{generateCssExample(encodedResult)}</CodeContainer>
          </div>
        </Background>
      )}
    </SystemLayout>
  );
};

export default B64Encoding;
