import { useEffect, useState } from 'react';

const useDeviceDetect = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator === 'undefined' ? '' : navigator.userAgent;

    const mobile = userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) !== null;

    setIsMobile(mobile);
  }, []);

  return isMobile;
}

export default useDeviceDetect;
