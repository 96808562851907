import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import authService from 'services/authService';
import { AuthContext } from 'context/AuthContext';
import PageLayout from 'layouts/PageLayout';
import RestorePasswordForm from 'components/forms/RestorePasswordForm';

const RequestResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setIsNotification } = useContext(AuthContext);
  const [notification, setNotification] = useState('');

  useDocumentTitle(t('restorePasswordPage.pageTitle'));

  const onClickResetPassWord = async (email) => {
    try {
      await authService.restorePassword(email);
    } catch (error) {
      setNotification(t('notification.errorMessage'))
    }

    setIsNotification(false);
  };

  return (
    <PageLayout>
      <RestorePasswordForm
        title={t('restorePasswordPage.title')}
        buttonTitle={t('restorePasswordPage.buttonTitle')}
        emailLabel={t('restorePasswordPage.emailLabel')}
        successMessage={t('restorePasswordPage.successMessage')}
        redirectButtonTitle={t('restorePasswordPage.redirectButtonTitle')}
        onClickHandler={onClickResetPassWord}
        onClickRedirectHandler={() => history.push('/login')}
        notificationMessage={notification}
      />
    </PageLayout>
  );
};

export default RequestResetPassword;
