import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import NotFound from 'pages/NotFound';
import Tools from 'pages/Tools';
import ToolsList from 'pages/Tools/ToolsList';
import TriangleGenerator from 'pages/Tools/TriangleGenerator';
import SymbolsCollection from 'pages/Tools/SymbolsCollection';
import LoremIpsumGenerator from 'pages/Tools/LoremIpsumGenerator';
import GradientGenerator from 'pages/Tools/GradientGenerator';
import FakeDataGenerator from 'pages/Tools/FakeDataGenerator';
import Base64Encoding from 'pages/Tools/Base64Encoding';
import EventsKeycode from 'pages/Tools/EventsKeyCode';
import ColorShadesGenerator from 'pages/Tools/ColorShadesGenerator';
import PageDividersGenerator from 'pages/Tools/PageDividersGenerator';
import CssCursorsGenerator from 'pages/Tools/CssCursors/CssCursors';
import SvgToJsx from 'pages/Tools/SvgToJsx/SvgToJsx';
import CreatePassword from 'pages/CreatePassword';
import SvgCompressor from 'pages/Tools/SvgCompressor';
import AnnouncementsList from 'pages/Announcements/AnnouncementList';
import AnnouncementArticle from 'pages/Announcements/AnnouncementArticle';
import AddMember from 'pages/Members/AddMember';
import Profile from 'pages/Profile';
import MembersList from 'pages/Members/MembersList';
import RequestResetPassword from 'pages/RequestResetPassword';
import ResetPassword from 'pages/ResetPassword';
import AddAnnouncement from 'pages/Announcements/AddAnnouncement/AddAnnouncement';
import EditAnnouncement from 'pages/Announcements/EditAnnouncement/EditAnnouncement';
import FAQ from 'pages/FAQ';
import EditMember from 'pages/Members/EditMember';
import Leaves from 'pages/Leaves/Leaves';
import AddLeaveRequest from 'pages/Leaves/AddLeaveRequest';
import HomeOfficeRequestForm from 'pages/Leaves/HomeOfficeRequestForm';
import ResolveRequestForm from 'pages/Leaves/ResolveRequestForm';
import AnnualLeaveRequestForm from 'pages/Leaves/AnnualLeaveRequestForm';
import SignAnnualLeave from 'pages/Leaves/SignAnnualLeave';

const routes = [
  {
    path: '/',
    exact: true,
    redirect: '/login',
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/create-password/:email?/:activate?/:token?',
    component: CreatePassword,
  },
  {
    path: '/request-reset-password',
    component: RequestResetPassword,
  },
  {
    path: '/reset-password/:email?/:token?',
    component: ResetPassword,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      translation: 'navigation.dashboardPageTitle',
    },
  },
  {
    path: '/tools',
    component: Tools,
    meta: {
      translation: 'navigation.toolsPageTitle',
    },
    routes: [
      {
        path: '/tools/tools-list',
        component: ToolsList,
        meta: {
          translation: 'navigation.toolsPageTitle',
        },
      },
      {
        path: '/tools/triangle-generator',
        component: TriangleGenerator,
        meta: {
          translation: 'navigation.triangleGeneratorPageTitle',
        },
      },
      {
        path: '/tools/lorem-isum-generator',
        component: LoremIpsumGenerator,
        meta: {
          translation: 'navigation.loremIpsumGeneratorPageTitle',
        },
      },
      {
        path: '/tools/gradient-generator',
        component: GradientGenerator,
        meta: {
          translation: 'navigation.gradientGeneratorPageTitle',
        },
      },
      {
        path: '/tools/fake-data-generator',
        component: FakeDataGenerator,
        meta: {
          translation: 'navigation.fakeDataGeneratorPageTitle',
        },
      },
      {
        path: '/tools/symbols-collection',
        component: SymbolsCollection,
        meta: {
          translation: 'navigation.symbolsCollectionPageTitle',
        },
      },
      {
        path: '/tools/base-64-encoder',
        component: Base64Encoding,
        meta: {
          translation: 'navigation.base64EncodingPageTitle',
        },
      },
      {
        path: '/tools/events-keycodes',
        component: EventsKeycode,
        meta: {
          translation: 'eventsKeyCodes.pageTitle',
        },
      },
      {
        path: '/tools/color-shades-generator',
        component: ColorShadesGenerator,
        meta: {
          translation: 'navigation.colorShadesGeneratorPageTitle',
        },
      },
      {
        path: '/tools/page-dividers',
        component: PageDividersGenerator,
        meta: {
          translation: 'navigation.pageDividersGeneratorPageTitle',
        },
      },
      {
        path: '/tools/css-cursors-generator',
        component: CssCursorsGenerator,
        meta: {
          translation: 'navigation.cssCursorGeneratorPageTitle',
        },
      },
      {
        path: '/tools/svg-to-jsx',
        component: SvgToJsx,
        meta: {
          translation: 'navigation.svgToJsxPageTitle',
        },
      },
      {
        path: '/tools/svg-compressor',
        component: SvgCompressor,
        meta: {
          translation: 'navigation.svgCompressorPageTitle',
        },
      },
      {
        path: '/not-found',
        component: NotFound,
      },
      {
        path: '**',
        redirect: '/not-found',
      },
    ],
  },
  {
    path: '/add-announcement',
    component: AddAnnouncement,
  },
  {
    path: '/edit-announcement',
    component: EditAnnouncement,
  },
  {
    path: '/faq',
    component: FAQ,
  },
  {
    path: '/announcements-list',
    component: AnnouncementsList,
  },
  {
    path: '/announcement-article',
    component: AnnouncementArticle,
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      translation: 'navigation.profilePageTitle',
    },
  },
  {
    path: '/members-list',
    component: MembersList,
    meta: {
      translation: 'navigation.membersPageTitle',
    },
  },
  {
    path: '/add-member',
    component: AddMember,
    meta: {
      translation: 'navigation.addMemberPageTitle',
    },
  },
  {
    path: '/edit-member',
    component: EditMember,
    meta: {
      translation: 'navigation.editMemberPageTitle',
    },
  },
  {
    path: '/leaves',
    component: Leaves,
  },
  {
    path: '/add-leave-request',
    component: AddLeaveRequest,
  },
  {
    path: '/add-homeoffice-request',
    component: HomeOfficeRequestForm,
  },
  {
    path: '/add-annual-leave-request',
    component: AnnualLeaveRequestForm,
  },
  {
    path: '/resolve-request',
    component: ResolveRequestForm,
  },
  {
    path: '/annual-leave-signing',
    component: SignAnnualLeave,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '**',
    redirect: '/not-found',
  },
];

export default routes;
