import React from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

const Input = ({
  additionalClass,
  invalid,
  component: Element = 'input',
  type,
  placeholder,
  children,
  value,
  onChange,
  onBlur,
  min,
  max,
  id,
}) => {
  if (id !== '') {
    return (
      <Element
        className={`input ${invalid ? 'input--invalid' : ''} ${additionalClass}`}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid.toString()}
        min={min}
        max={max}
        id={id}
      >
        {children}
      </Element>
    );
  }

  return (
    <Element
      className={`input ${invalid ? 'input--invalid' : ''} ${additionalClass}`}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      invalid={invalid.toString()}
      min={min}
      max={max}
    >
      {children}
    </Element>
  );
};

Input.defaultProps = {
  additionalClass: '',
  value: '',
  placeholder: '',
  min: 0,
  max: 200,
  invalid: false,
  onChange: undefined,
  onBlur: undefined,
  children: null,
  component: 'input',
  type: 'text',
  id: '',
};

Input.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  additionalClass: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  invalid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.string,
  id: PropTypes.string,
};

export default Input;
