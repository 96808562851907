import React from 'react';
import PropTypes from 'prop-types';

import Shape, { shapes } from '../Shape/Shape';

import './Preview.scss';

const Preview = ({ values }) => (
  <div className="page-dividers-preview ">
    <div
      className={`page-dividers-preview__shape-wrapper page-dividers-preview__shape-wrapper--${values.position} page-dividers-preview__shape-wrapper--${`${values.position}-${values.direction}`}`}
    >
      <Shape
        shape={values.type}
        style={{
          width: `${values.width}%`,
          height: values.height,
          fill: values.color,
        }}
      />
    </div>
  </div>
);

Preview.propTypes = {
  values: PropTypes.shape({
    position: PropTypes.oneOf(['top', 'bottom']).isRequired,
    direction: PropTypes.oneOf(['normal', 'reverse']).isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(shapes)).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
};

export default Preview;
