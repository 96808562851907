import React from 'react';
import PropTypes from 'prop-types';
import Background from 'components/tools/Background';
import CodeContainer from 'components/tools/CodeContainer';
import { useTranslation } from 'react-i18next';

import './Output.scss';

const Output = ({ data }) => {
  const { t } = useTranslation();

  if (data.loading) {
    return (
      <Background additionalClass="svg-to-jsx-wrapper">
        {t('svgToJsx.processingMessage')}
        svgo → svg2jsx → prettier
      </Background>
    );
  }

  if (data.content) {
    return (
      <Background additionalClass="svg-to-jsx-wrapper">
        <CodeContainer>{data.content}</CodeContainer>
      </Background>
    );
  }

  return null;
};

Output.defaultProps = {
  data: {
    content: '',
    loading: false,
  },
};

Output.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.string,
    loading: PropTypes.bool.isRequired,
  }),
};

export default Output;
