import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import Button from 'components/tools/Button';
import Triangle from '../Triangle/Triangle';
import directions from '../directions';

import './TrianglePreview.scss';

const TrianglePreview = ({ values, theme, onThemeToggle }) => {
  const { t } = useTranslation();

  return (
    <Background additionalClass={`triangle-preview ${theme === 'dark' ? 'triangle-preview--dark' : 'triangle-preview--light'}`}>
      <Button
        type="button"
        onClick={onThemeToggle}
        additionalClass={`triangle-preview__button ${theme === 'light' ? 'triangle-preview__button--light' : 'triangle-preview__button--dark'}`}
      >
        <div className="label">
          {theme === 'light' ? t('triangleGenerator.settings.darkTheme') : t('triangleGenerator.settings.lightTheme')}
          {' '}
          {t('triangleGenerator.settings.themeToggleButtonTitle')}
        </div>
      </Button>
      <div className="triangle-preview__triangle">
        <Triangle
          direction={values.direction}
          height={values.height}
          width={values.width}
          color={values.color}
        />
      </div>
    </Background>
  );
};

TrianglePreview.propTypes = {
  onThemeToggle: PropTypes.func.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']).isRequired,
  values: PropTypes.shape({
    direction: PropTypes.oneOf(directions).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};

export default TrianglePreview;
