import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import PropTypes from 'prop-types';
import Button from 'components/global/Button';
import DropPlaceholder from 'components/tools/DropPlaceholder';
import Dropzone from 'components/tools/Dropzone';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import ToolButton from 'components/tools/Button';
import IconCamera from 'assets/images/camera-retro.svg';
import IconPlus from 'assets/images/plus-black-symbol.svg';

import './AnnouncementForm.scss';

const ANNOUNCEMENT_CHARACTER_LIMIT = 255;

const AnnouncementForm = ({
  onClickHandler,
  announcementData,
  buttonTitle,
  isEdit,
  submitButtonLoading,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [titleInvalidInput, setTitleInvalidInput] = useState(false);
  // const [tags, setTags] = useState(''); // TODO: tags
  // const [tagsInvalidInput, setTagsInvalidInput] = useState(false);
  const [description, setDescription] = useState('');
  const [descriptionInvalidInput, setDescriptionInvalidInput] = useState(false);
  const [announcement, setAnnoucement] = useState('');
  const [annoucementInvalidInput, setAnnoucementInvalidInput] = useState(false);
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [resultImage, setResultImage] = useState({ loading: false, error: null, content: '' });
  const [imageInvalidInput, setImageInvalidInput] = useState(false);
  const imageInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [emailNotification, setEmailNotification] = useState(false);
  const [isInitialDataSet, setIsInitialDataSet] = useState(false);
  const [notification, setNotification] = useState('');

  const fileNameHelper = (fileName) => {
    if (typeof fileName === 'string') {
      const array = fileName.split('/');

      return array[array.length - 1];
    }

    return fileName;
  };

  const handleImageFile = (filesInput) => {
    if (filesInput.length > 0) {
      const file = filesInput[0];

      setImageFile(file);

      if (file) {
        try {
          const reader = new FileReader();

          reader.addEventListener('loadend', () => setResultImage({ content: reader.result }));
          reader.readAsDataURL(file);
        } catch (error) {
          setResultImage({
            error,
            loading: false,
            content: error.message,
          });
        }
      }
    }
  };

  const onFileAddHandler = (input) => {
    const acceptTypes = ['image/png',
      'image/jpeg',
      'image/jpg',
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

    input.forEach((droppedFile) => {
      if (acceptTypes.includes(droppedFile.type) || droppedFile.name.split('.')[1] === 'docx') {
        setFiles([...files, droppedFile]);
      } else {
        setNotification(t('notification.fileTypeErrorMessage'));
      }
    });
  };

  const onClickRemoveImageFile = () => {
    setImage('');
    setImageFile(null);

    imageInputRef.current.value = '';
  };

  const onClickButtonHandler = () => {
    if (announcement.split('').length >= ANNOUNCEMENT_CHARACTER_LIMIT) {
      setNotification(t('notification.characterLimitErrorMessage'));
    } else if (!announcement || !description || !title || !image) {
      setNotification(t('notification.requiredFieldsErrorMessage'));
    } else {
      setNotification('');
    }

    setImageInvalidInput(!imageFile);
    setTitleInvalidInput(title === '');
    // setTagsInvalidInput(tags === ''); // TODO: tags
    setDescriptionInvalidInput(description === '');
    setAnnoucementInvalidInput(announcement === '' || announcement.split('').length >= ANNOUNCEMENT_CHARACTER_LIMIT);

    const isInputValidated = title !== ''
    // && tags  // TODO: tags
    && description !== ''
    && announcement !== ''
    && announcement.split('').length < ANNOUNCEMENT_CHARACTER_LIMIT
    && image !== ''
    && (imageFile !== null || isEdit);

    if (isInputValidated) {
      const data = {
        title,
        announcement,
        description,
        alertUsers: emailNotification,
      };

      onClickHandler(data, imageFile, files);
    }
  };

  const onClickRemoveFileHandler = (array, item) => {
    setFiles(array
      .filter((value) => (announcementData
        ? value !== item : value.name !== item.name)));
  };

  useEffect(() => {
    setImage(resultImage.content);
    setImageInvalidInput(false);
  }, [resultImage.content, setImage]);

  useEffect(() => {
    if (announcementData && !isInitialDataSet) {
      setTitle(announcementData.title);
      // setTags(announcementData.tags); // TODO: tags
      setDescription(announcementData.description);
      setAnnoucement(announcementData.announcement);
      setImageFile(announcementData.featuredImage ? announcementData.featuredImage : '');
      setImage(announcementData.featuredImage ? announcementData.featuredImage : '');
      setFiles(announcementData.attachments);

      setIsInitialDataSet(true);
    }
  }, [announcementData, isInitialDataSet]);

  return (
    <section className="announcement-form">
      <form className="announcement-form__form">
        <FormInput
          system
          isInvalidInput={titleInvalidInput}
          additionalClass="announcement-form__input"
          type="text"
          name="title"
          value={title}
          id="password"
          placeholder={t('addAnnouncementPage.titleLabel')}
          onChange={(event) => setTitle(event.target.value)}
        />
        {/* <FormInput // TODO: tags
          system
          isInvalidInput={tagsInvalidInput}
          additionalClass="announcement-form__input"
          type="text"
          name="tags"
          value={tags}
          id="password"
          placeholder={t('addAnnouncementPage.tagsLabel')}
          onChange={(event) => setTags(event.target.value)}
        /> */}
        <FormInput
          system
          isInvalidInput={descriptionInvalidInput}
          additionalClass="announcement-form__input"
          type="text"
          name="description"
          value={description}
          id="description"
          placeholder={t('addAnnouncementPage.descriptionLabel')}
          onChange={(event) => setDescription(event.target.value)}
        />
        <FormInput
          system
          isInvalidInput={annoucementInvalidInput}
          additionalClass="announcement-form__input"
          type="text"
          name="announcement"
          value={announcement}
          id="announcement"
          placeholder={t('addAnnouncementPage.announcementLabel')}
          onChange={(event) => setAnnoucement(event.target.value)}
        />
        <div className="announcement-form__upload-wrapper">
          <div>
            <div className="announcement-form__image-label-wrapper">
              <p className="announcement-form__upload-label">{t('addAnnouncementPage.previewImageLabel')}</p>
              {image && (
                <ToolButton
                  type="button"
                  additionalClass="announcement-form__upload-label announcement-form__reset-image-button"
                  onClick={onClickRemoveImageFile}
                >
                  {t('addAnnouncementPage.resetImageButtonTitle')}
                </ToolButton>
              )}
            </div>
            <button
              type="button"
              className={`announcement-form__upload-image-button ${imageInvalidInput ? 'announcement-form__upload-image-button--invalid' : ''}`}
              onClick={() => imageInputRef.current.click()}
            >
              {image ? (
                <div className="announcement-form__image-preview-wrapper">
                  <img src={image} alt="" className="announcement-form__image-preview" />
                </div>
              ) : (
                <>
                  <img src={IconCamera} alt="" className="announcement-form__upload-image-icon" />
                  <p className="add-anouncement-page__icon-text">{t('addAnnouncementPage.uploadImagelabel')}</p>
                </>
              )}
            </button>
            <input
              ref={imageInputRef}
              id="file-input"
              type="file"
              name="name"
              accept=".png, .jpeg, .jpg"
              className="hidden"
              onChange={(event) => event.target.files && handleImageFile(event.target.files)}
            />
          </div>
          <div>
            <p className="announcement-form__upload-label">{t('addAnnouncementPage.filesLabel')}</p>
            <Dropzone
              accepts="*"
              onDrop={(file) => onFileAddHandler(file)}
              avatar
            />
            <DropPlaceholder
              additionalClass="fullscreenDrop announcement-form__dropzone"
              onFileAdd={(file) => onFileAddHandler(file)}
              accepts="*"
              avatar
            >
              <img src={IconPlus} alt="" className="announcement-form__upload-image-icon" />
              <p>{t('addAnnouncementPage.uploadFilesLabel')}</p>
            </DropPlaceholder>
          </div>
        </div>
        <div className="announcement-form__email-notification">
          <label
            className={`announcement-form__checkbox-label ${emailNotification && 'announcement-form__checkbox-label--checked'}`}
            htmlFor="notification"
          >
            {t('addAnnouncementPage.checkmarkLabel')}
            <input
              className="announcement-form__checkbox"
              type="checkbox"
              name="notification"
              id="notification"
              checked={emailNotification}
              onChange={() => setEmailNotification(!emailNotification)}
            />
          </label>
        </div>
        {files.length > 0 && (
          <ul className="announcement-form__files-list">
            {files.map((item) => (
              <li key={uuid()} className="announcement-form__files-item">
                {item.attachmentPath ? fileNameHelper(item.attachmentPath) : item.name}
                <ToolButton
                  type="button"
                  additionalClass="announcement-form__upload-label announcement-form__reset-image-button"
                  onClick={() => onClickRemoveFileHandler(files, item)}
                >
                  {t('addAnnouncementPage.removeFileButtonTitle')}
                </ToolButton>
              </li>
            ))}
          </ul>
        )}
        <FormNotification additionalClass="announcement-form__notification" message={notification} />
        <Button
          addtitionalClass="announcement-form__button"
          title={buttonTitle}
          onClickHandler={onClickButtonHandler}
          loading={submitButtonLoading}
        />
      </form>
    </section>
  );
};

AnnouncementForm.defaultProps = {
  announcementData: null,
  isEdit: false,
  submitButtonLoading: false,
};

AnnouncementForm.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  submitButtonLoading: PropTypes.bool,
  announcementData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    alertUsers: PropTypes.number.isRequired,
    announcement: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    featuredImage: PropTypes.string,
    tags: PropTypes.string, // TODO: tags
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      jobPosition: PropTypes.string.isRequired,
      profilePicture: PropTypes.string.isRequired,
    }),
    attachments: PropTypes.arrayOf(PropTypes.any),
  }),
};

export default AnnouncementForm;
