import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Arrow from 'assets/images/arrow-up.svg';
import EditDeleteMenu from 'components/global/EditDeleteMenu';

import './FAQItem.scss';

const FAQItem = ({
  item,
  isAdmin,
  onClickEditHandler,
  onClickDeleteHandler,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="faq-item">
      <div className="faq-item__question-wrapper">
        <button
          type="button"
          className="faq-item__expand-button"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <h3 className="faq-item__question">{item.question}</h3>
          <img src={Arrow} className={`faq-item__arrow ${isExpanded ? 'faq-item__arrow--rotated' : ''}`} alt="" />
        </button>
      </div>
      <div className={`faq-item__answer-wrapper ${isExpanded ? 'faq-item__answer-wrapper--expanded' : ''}`}>
        <div className="faq-item__answer">
          {isAdmin && (
            <EditDeleteMenu
              additionalClass="faq-item__menu"
              onClickEditHandler={() => onClickEditHandler(item.id)}
              onClickDeleteHandler={() => onClickDeleteHandler(item.id)}
            />
          )}
          {item.answer}
        </div>
      </div>
    </div>
  );
};

FAQItem.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  onClickEditHandler: PropTypes.func.isRequired,
  onClickDeleteHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,
};

export default FAQItem;
