import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import TOOLS from 'config/tools';
import SystemLayout from 'layouts/SystemLayout';

import './ToolsList.scss';

const ToolsList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useDocumentTitle(t('navigation.toolsPageTitle'));

  return (
    <SystemLayout>
      <ul className="tools">
        {TOOLS.map((tool) => (
          <li key={uuid()} className="tools__item">
            <button type="button" className="tools__button" onClick={() => history.push(tool.path)}>
              <div className="tools__image-wrapper">
                <img className="tools__image" src={tool.icon} alt={t(tool.title)} />
              </div>
              <h3 className="tools__title" to={tool.path}>{t(tool.title)}</h3>
            </button>
          </li>
        ))}
      </ul>
    </SystemLayout>
  );
};

export default ToolsList;
