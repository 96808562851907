import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from 'hooks/useDeviceDetect';

import Avatar from 'components/global/Avatar';
import Button from 'components/global/Button';

import './LeaveRequestItem.scss';

const LeaveRequestItem = ({
  request,
  isResolver,
  onClickResolveHandler,
  onClickSignHandler,
  dashboard,
}) => {
  const { t } = useTranslation();
  const isMobile = useDeviceDetect();

  const translateStatusHelper = (item) => {
    let translation = '';

    if (item.status === 'pending') {
      translation = t('leavesPage.pendingLabel');
    } else if (item.status === 'approved') {
      translation = `${t('leavesPage.approvedLabel')} ${t('leavesPage.concatLabel')} ${item.approver.name}`;
    } else if (item.status === 'declined') {
      translation = `${t('leavesPage.declinedLabel')} ${t('leavesPage.concatLabel')} ${item.approver.name}`
    }

    return translation;
  };

  return (
    <div className={`request ${dashboard ? 'request--dashboard' : ''}`}>
      <div className="request__type">
        <p className="request__type-label">{request.type === 'Home Office' ? t('leavesPage.homeOfficeTypeLabel') : t('leavesPage.annualLeaveTypeLabel')}</p>
      </div>
      <div className="request__wrapper">
        <div className="request__avatar-wrapper">
          <Avatar additionalClass="request__avatar" imageUrl={request.requester.profilePicture} small circle />
          <div className="request__author">
            <p className="request__author-name">{request.requester.name}</p>
            <p className="request__author-position">{request.requester.jobPosition}</p>
          </div>
        </div>
        <div className="request__info">
          <p>{`${t('leavesPage.requestStartDateLabel')} ${(new Date(request.startingFrom)).toLocaleDateString('en-GB').split('/').join('.')}`}</p>
          <p>{`${t('leavesPage.requestDaysLabel')} ${request.days}`}</p>
          <p>{`${t('leavesPage.requestReasonLabel')} ${request.reason}`}</p>
          {request.requestResolution && (
            <p>{`${t('leavesPage.requestResolutionLabel')} ${request.requestResolution}`}</p>
          )}
          {request.annualLeaveType && (
            <p>
              {`${request.annualLeaveType === 'paid'
                ? t('leavesPage.requestAnnualLeavePaidTypeLabel')
                : t('leavesPage.requestAnnualLeaveUnpaidTypeLabel')}`}
            </p>
          )}
        </div>
        {request.status === 'pending' && isResolver && (
          <Button
            additionalClass="request__button"
            onClickHandler={onClickResolveHandler}
            title={t('leavesPage.requestButtonLabel')}
          />
        )}
        {request.status === 'approved'
          && request.type === 'Annual Leave'
          && !isMobile
          && !request.isAccountingEmailSent
          && (
            <Button
              additionalClass="request__button"
              onClickHandler={onClickSignHandler}
              title={t('leavesPage.signButtonLabel')}
            />
          )}
      </div>
      <div className={`request__status request__status--${request.status}`}>
        <p className="request__status-label">{translateStatusHelper(request)}</p>
      </div>
    </div>
  );
};

LeaveRequestItem.defaultProps = {
  dashboard: false,
  request: ({
    id: '',
    requester: {},
    approver: {},
    type: '',
    annualLeaveType: null,
    days: 0,
    startingFrom: '',
    reason: '',
    status: '',
    isAccountingEmailSent: false,
  }),
  onClickResolveHandler: () => {},
};

LeaveRequestItem.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.number,
    requester: {
      userId: PropTypes.number,
      userEmail: PropTypes.string,
      name: PropTypes.string,
      jobPosition: PropTypes.string,
      profilePicture: PropTypes.string,
      personalNumber: PropTypes.string,
    },
    approver: {
      name: PropTypes.string,
    },
    type: PropTypes.string,
    annualLeaveType: PropTypes.oneOf(['paid', 'unpaid']),
    days: PropTypes.number,
    startingFrom: PropTypes.string,
    reason: PropTypes.string,
    requestResolution: PropTypes.string,
    status: PropTypes.string,
    isAccountingEmailSent: PropTypes.bool,
  }),
  dashboard: PropTypes.bool,
  isResolver: PropTypes.bool.isRequired,
  onClickResolveHandler: PropTypes.func,
  onClickSignHandler: PropTypes.func.isRequired,
};

export default LeaveRequestItem;
