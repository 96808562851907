import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './DropPlaceholder.scss';

const DropPlaceholder = ({
  additionalClass,
  children,
  onFileAdd,
  accepts,
  avatar,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`placeholder ${additionalClass}`}>
      {!avatar ? (
        <>
          <div className="placeholder__label">
            {children}
            {' '}
            {t('dropZone.dropZoneConnectWord')}
          </div>
          <label className="placeholder__input-label" htmlFor="file-browse">
            {t('dropZone.dropZoneButtonLabel')}
            {' '}
            <input
              className="placeholder__input"
              type="file"
              id="file-browse"
              accept={accepts}
              onChange={(event) => event.target.files[0] && onFileAdd(event.target.files[0])}
            />
          </label>
        </>
      ) : (children)}
    </div>
  );
};

DropPlaceholder.defaultProps = {
  additionalClass: '',
  accepts: 'image/svg+xml',
  avatar: false,
};

DropPlaceholder.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  onFileAdd: PropTypes.func.isRequired,
  accepts: PropTypes.string,
  avatar: PropTypes.bool,
};

export default DropPlaceholder;
