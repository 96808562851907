import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './Button.scss';

const Button = ({
  title,
  additionalClass,
  onClickHandler,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={`button ${additionalClass} ${loading ? 'button--disabled' : ''}`}
      onClick={onClickHandler}
      disabled={loading}
    >
      {loading
        ? t('loadingButton')
        : title}
    </button>
  );
};

Button.defaultProps = {
  additionalClass: '',
  loading: false,
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
  loading: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
};

export default Button;
