import React, { useState, useEffect, useRef } from 'react';
import { useDocumentTitle, useListState, useLocalStorage } from 'xooks';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Background from 'components/tools/Background';
import SettingsLabel from 'components/tools/SettingsLabel';
import GradientLine from './GradientLine/GradientLine';
import GradientColors from './GradientColors/GradientColors';
import GradientSettings from './GradientSettings/GradientSettings';
import GradientPreview from './GradientPreview/GradientPreview';
import GradientCode from './GradientCode/GradientCode';
import GradientsGallery from './GradientsGallery/GradientsGallery';

import './GradientGenerator.scss';

const INITIAL_VALUES = {
  angle: 90,
  type: 'linear',
  values: [
    {
      color: '#ed6ea0', position: 10, opacity: 100, key: v4(),
    },
    {
      color: '#ec8c69', position: 90, opacity: 100, key: v4(),
    },
  ],
};

const GradientGenerator = () => {
  const { t } = useTranslation();
  const ls = useLocalStorage({ key: 'gradient-generator', delay: 1000 });
  const initialValues = ls.retrieve() || INITIAL_VALUES;
  const wrapperRef = useRef();
  const [values, handlers] = useListState(initialValues.values);
  const [angle, setAngle] = useState(initialValues.angle);
  const [type, setType] = useState(initialValues.type);

  useDocumentTitle(t('gradientGenerator.pageTitle'));

  useEffect(() => {
    ls.save({ values, angle, type });

    return ls.cancel;
  }, [values, angle, type, ls]);

  return (
    <SystemLayout>
      <div ref={wrapperRef}>
        <GradientPreview values={values} angle={angle} type={type} additionalClass="gradient-generator" />
        <Background additionalClass="gradient-generator__controls">
          <GradientLine values={values} handlers={handlers} />
          <div className="gradient-generator__body">
            <div className="gradient-generator__column" style={{ flex: '0 0 203px' }}>
              <SettingsLabel>{t('gradientGenerator.settingsTitle')}</SettingsLabel>
              <GradientSettings
                angle={angle}
                type={type}
                onTypeChange={setType}
                onAngleChange={setAngle}
              />
            </div>
            <div className="gradient-generator__column">
              <SettingsLabel>{t('gradientGenerator.colorsTitle')}</SettingsLabel>
              <GradientColors values={values} handlers={handlers} />
            </div>
          </div>
        </Background>
        <GradientCode values={values} type={type} angle={angle} />
        <GradientsGallery
          handlers={handlers}
          onItemClickHandler={() => wrapperRef.current.scrollIntoView()}
        />
      </div>
    </SystemLayout>
  );
};

export default GradientGenerator;
