import React from 'react';
import PropTypes from 'prop-types';

import './EventInfo.scss';

const EventInfo = ({
  additionalClass,
  title,
  kbd,
  value,
  deprecation,
}) => (
  <div className={`event-info ${additionalClass}`}>
    <div className="event-info__title">
      {kbd && <div className="event-info__kbd">{kbd}</div>}
      <p className="event-info__title-label">{title}</p>
      {deprecation && <p className="event-info__deprecation">deprecated</p>}
    </div>

    <div
      className={`event-info__value ${
        typeof value === 'boolean' ? 'event-info__boolean' : ''} ${!value ? 'event-info__boolean--false' : ''}
      `}
    >
      {value.toString() === ' ' ? (
        <div className="event-info__space">(Space charachter)</div>
      ) : (
        value.toString()
      )}
    </div>
  </div>
);

EventInfo.defaultProps = {
  additionalClass: '',
  deprecation: false,
  kbd: '',
};

EventInfo.propTypes = {
  additionalClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  kbd: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]).isRequired,
  deprecation: PropTypes.bool,
};

export default EventInfo;
