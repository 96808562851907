import React from 'react';
import PropTypes from 'prop-types';
import {
  Page, Text, View, Document, StyleSheet, Image, Font,
} from '@react-pdf/renderer';

import DINProMedium from 'assets/fonts/din-pro--regular.woff';
import DINProBold from 'assets/fonts/din-pro--bold.woff';

Font.register({
  family: 'DINPro',
  fonts: [
    {
      src: DINProMedium,
      fontWeight: 'normal',
    },
    {
      src: DINProBold,
      fontWeight: 'bold',
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 40px 70px',
  },
  text: {
    marginVertical: 4,
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'DINPro',
  },
  bold: {
    fontWeight: 600,
  },
  title: {
    marginVertical: 60,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 600,
  },
  personalInfo: {
    marginVertical: 30,
    fontWeight: 600,
  },
  application: {
    marginVertical: 90,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  signtature: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    height: 60,
  },
  signatureImage: {
    width: 80,
    height: 80,
  },
});

const PdfDocument = ({
  name,
  privateNumber,
  job,
  days,
  startDate,
  option,
  signature,
}) => {
  const year = new Date().getFullYear();

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.container}>
          <Text style={styles.text}>До</Text>
          <Text style={styles.text}>Управителя</Text>
          <Text style={styles.text}>на РЪББЪР ДЪК ООД</Text>
        </View>
        <View style={styles.main}>
          <View style={styles.titleContainer}>
            <Text style={{ ...styles.text, ...styles.title }}>ЗАЯВЛЕНИЕ</Text>
          </View>
          <View style={styles.personalInfo}>
            <Text style={{ ...styles.text, ...styles.bold }}>
              {`От ${name === '' ? '..........................................' : name} ЕГН ${privateNumber === '' ? '..................' : privateNumber}`}
            </Text>
            <Text style={{ ...styles.text, ...styles.bold }}>
              {` на длъжност: ${job === '' ? '..........................................' : job}.`}
            </Text>
          </View>
          <View style={styles.application}>
            <Text style={styles.text}>Г-н Управител,</Text>
            <Text style={styles.text}>
              {` Моля да ми разрешите да ползването на ${option === 'paid' ? 'платен' : 'неплатен'} отпуск по чл.155, ал.4 от КТ за ${year} година, в продължение на ${days} работни дни, считано от ${startDate} год.`}
            </Text>
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <Text style={styles.text}>
            {`Дата: ${(new Date()).toLocaleDateString('en-GB').split('/').join('.')} г.`}
          </Text>
          <View style={styles.signtature}>
            <Text style={styles.text}>
              {`Подпис: ${!signature ? '.............' : ' '}`}
            </Text>
            {signature !== '' && <Image style={styles.signatureImage} src={signature} />}
          </View>
        </View>
      </Page>
    </Document>
  );
};

PdfDocument.propTypes = {
  name: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  privateNumber: PropTypes.string.isRequired,
  days: PropTypes.number.isRequired,
  signature: PropTypes.string.isRequired,
};

export default PdfDocument;
