import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/tools/Tabs';
import Background from 'components/tools/Background';
import SliderInput from 'components/tools/SliderInput';
import SettingsLabel from 'components/tools/SettingsLabel';
import Button from 'components/tools/Button';
import Select from 'components/tools/Select';
import Input from 'components/tools/Input';
import { generatorsData } from '../generator';

import './Settings.scss';

const Settings = ({
  fields,
  amount,
  onAmountChange,
  onFieldAdd,
  onFieldRemove,
  onFieldPropChange,
  type,
  onTypeChange,
  onRegenerate,
}) => {
  const { t } = useTranslation();

  const types = [
    { value: 'default', label: t('fakeDataGenerator.tabDataTitle') },
    { value: 'json', label: t('fakeDataGenerator.tabSettingsTitle') },
  ];

  const schema = fields.map((field, index) => (
    <div className="fake-generator-settings__field" key={field.key}>
      <div className="fake-generator-settings__input">
        <div className="fake-generator-settings__label">{t('fakeDataGenerator.nameTitle')}</div>
        <Input
          additionalClass="fake-generator-settings__name-input"
          value={field.name}
          onChange={(event) => onFieldPropChange(index, 'name', event.target.value)}
        />
      </div>
      <Select
        additionalClass="fake-generator-settings__input"
        id={field.key}
        label={t('fakeDataGenerator.typeTitle')}
        data={generatorsData}
        value={field.type}
        onChange={(value) => onFieldPropChange(index, 'type', value)}
      />
      <button className="fake-generator-settings__remove-button" type="button" onClick={onFieldRemove}>
        {t('fakeDataGenerator.removeButtonTitle')}
      </button>
    </div>
  ));

  return (
    <Background additionalClass="fake-generator-settings">
      <div className="fake-generator-settings__header">
        <Tabs data={types} active={type} onTabChange={onTypeChange} />
        <Button onClick={onRegenerate} additionalClass="fake-generator-settings__button">
          {t('fakeDataGenerator.regenerateButtonTitle')}
        </Button>
      </div>
      {type === 'json' && (
        <>
          {' '}
          <div className="fake-generator-settings__body">
            <SettingsLabel>{t('fakeDataGenerator.settingsTitle')}</SettingsLabel>
            {schema}
          </div>
          <div className="fake-generator-settings__footer">
            <div className="fake-generator-settings__control">
              <Button
                additionalClass="fake-generator-settings__button"
                onClick={onFieldAdd}
              >
                {t('fakeDataGenerator.addFieldButtonTitle')}
              </Button>
            </div>

            <div>
              <div className="fake-generator-settings__label">{t('fakeDataGenerator.amountTitle')}</div>
              <SliderInput additionalClass="fake-generator-settings__slider" value={amount} onChange={onAmountChange} min={1} max={100} />
            </div>
          </div>
        </>
      )}
    </Background>
  );
};

Settings.propTypes = {
  type: PropTypes.oneOf(['default', 'json']).isRequired,
  amount: PropTypes.number.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onAmountChange: PropTypes.func.isRequired,
  onFieldAdd: PropTypes.func.isRequired,
  onFieldRemove: PropTypes.func.isRequired,
  onFieldPropChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onRegenerate: PropTypes.func.isRequired,
};

export default Settings;
