import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ConfirmModal from 'components/global/ConfirmModal';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';

import Navigation from 'components/global/Navigation';
import Button from 'components/global/Button';
import Logo from 'assets/images/arrow-left.svg';

import './SystemLayout.scss';

const SystemLayout = ({ children }) => {
  const [title, setTitle] = useState('');
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [backPagePath, setBackPagePath] = useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { isLogged, setIsLogged } = useContext(AuthContext);
  const { userRole } = useContext(UserContext);
  const { notification, notificationType } = useContext(NotificationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setNotification, setNotificationType } = useContext(NotificationContext);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (notificationType === 'unautorized') {
      setIsModalOpen(true);
    }
  }, [notificationType, setIsLogged]);

  useEffect(() => {
    const adminUrls = ['/add-member',
      '/edit-member',
      '/add-announcement',
      '/edit-announcement'];

    if (isLogged && userRole) {
      const isAdminChecker = !!(userRole.find((role) => role.title === 'admin'));

      setIsAdmin(isAdminChecker);

      if (!isAdminChecker && adminUrls.includes(location.pathname)) {
        history.push('/');
      }
    }
  }, [history, isLogged, location.pathname, userRole]);

  useEffect(() => {
    const notSystemUrls = ['/login', '/create-password', '/reset-password'];

    const pagePath = location.pathname.split('/');
    const isToolPages = pagePath[1] === 'tools' && pagePath[2] !== 'tools-list';

    setIsBackButtonVisible(isToolPages
      || pagePath[1] === 'edit-member'
      || pagePath[1] === 'add-member'
      || pagePath[1] === 'add-leave-request'
      || pagePath[1] === 'resolve-request'
      || pagePath[1] === 'add-annual-leave-request'
      || pagePath[1] === 'annual-leave-signing'
      || pagePath[1] === 'add-homeoffice-request'
      || pagePath[1] === 'add-announcement'
      || pagePath[1] === 'edit-announcement'
      || pagePath[1] === 'announcement-article');

    if (pagePath[1] === 'tools') {
      setBackPagePath('/tools/tools-list');
    } else if (pagePath[1] === 'add-member' || pagePath[1] === 'edit-member') {
      setBackPagePath('/members-list');
    } else if (pagePath[1] === 'add-leave-request'
      || pagePath[1] === 'add-homeoffice-request'
      || pagePath[1] === 'add-annual-leave-request'
      || pagePath[1] === 'annual-leave-signing'
      || pagePath[1] === 'resolve-request') {
      setBackPagePath('/leaves');
    } else if (pagePath[1] === 'announcement-article'
      || pagePath[1] === 'add-announcement'
      || pagePath[1] === 'edit-announcement'
      || pagePath[1] === 'announcement-article') {
      setBackPagePath('/announcements-list');
    }

    setCurrentPage(pagePath[1]);

    if (!isLogged && !notSystemUrls.includes(location.pathname)) {
      history.push('/login');
    }
  }, [history, isLogged, location.pathname]);

  return (
    <main className="system-layout">
      <Navigation onChangePage={(pageTitle) => setTitle(pageTitle)} />
      <div className="system-layout__content">
        <div className="system-layout__wrapper">
          {isBackButtonVisible && (
            <button
              aria-label="back button"
              className="system-layout__back-button"
              type="button"
              onClick={() => history.push(backPagePath)}
            >
              <img src={Logo} alt="" className="system-layout__arrow" />
            </button>
          )}
          <h2 className="system-layout__title">{title}</h2>
          {(currentPage === 'members-list' && isAdmin) && (
            <Button
              title={t('navigation.addButtonLabel')}
              additionalClass="system-layout__button"
              onClickHandler={() => history.push('/add-member')}
            />
          )}
          {currentPage === 'leaves' && (
            <Button
              title={t('navigation.addButtonLabel')}
              additionalClass="system-layout__button"
              onClickHandler={() => history.push('/add-leave-request')}
            />
          )}
          {(currentPage === 'announcements-list' && isAdmin) && (
            <Button
              title={t('navigation.addButtonLabel')}
              additionalClass="system-layout__button"
              onClickHandler={() => history.push('/add-announcement')}
            />
          )}
          <div className={`notification notification--${notificationType}`}>
            <p className="notification__message">{notification}</p>
          </div>
        </div>
        <div className="system-layout__page">
          {children}
        </div>
      </div>
      {isModalOpen && (
        <ConfirmModal
          message={notification}
          onClickConfirmHandler={() => {
            setIsLogged(false);
            setNotification('');
            setNotificationType('hidden');
            history.push('/login')
          }}
          onClickCancelHandler={() => setIsModalOpen(false)}
          confirmButtonTitle={`${t('notification.confirmButtonTitle')}`}
          cancelButtonTitle={`${t('notification.cancelButtonTitle')}`}
        />
      )}
    </main>
  );
};

SystemLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default SystemLayout;
