import React from 'react';
import PropTypes from 'prop-types';

import './Swatch.scss';

const Swatch = ({
  additionalClass, value, onClick,
}) => (
  <button
    aria-label={value}
    type="button"
    className={`swatch ${additionalClass}`}
    style={{ backgroundColor: value }}
    onClick={onClick}
  />
);

Swatch.propTypes = {
  additionalClass: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Swatch;
