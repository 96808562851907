import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const NOTIFICATION_TIME = 5000;

export const NotificationContext = createContext({
  notification: '',
  setNotification: () => {},
  notificationType: '',
  setNotificationType: () => {},
});

const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState('');
  const [notificationType, setNotificationType] = useState('hidden');

  const resetNotification = () => {
    setTimeout(() => {
      setNotification('');
      setNotificationType('hidden');
    }, NOTIFICATION_TIME);
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
        notificationType,
        setNotificationType,
        resetNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationContextProvider;
