import React from 'react';
import PropTypes from 'prop-types';

import Waves from './Waves';
import WavesOpaque from './WavesOpaque';
import Tilt from './Tilt';
import Triangles from './Triangles';

export const shapes = {
  tilt: Tilt,
  waves: Waves,
  wavesOpaque: WavesOpaque,
  triangles: Triangles,
};

const Shape = ({ shape, style }) => {
  if (shape in shapes) {
    const Component = shapes[shape];

    return <Component style={style} />;
  }

  return null;
}

Shape.propTypes = {
  shape: PropTypes.oneOf(Object.keys(shapes)).isRequired,
  style: PropTypes.shape({
    fill: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.string,
  }).isRequired,
};

export default Shape;
