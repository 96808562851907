import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useClipboard } from 'xooks';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import HexInput from 'components/tools/HexInput';
import Background from 'components/tools/Background';
import generateShades from 'helpers/utility/shades/generator';

import './ColorShadesList.scss';

const ColorShadesList = ({
  value,
  onChange,
  onDelete,
  canDelete,
  saturation,
  darken,
}) => {
  const { t } = useTranslation();
  const clipboardAll = useClipboard();
  const clipboard = useClipboard({ timeout: 500 });
  const values = generateShades({
    steps: 7, value, saturation, darken,
  });
  const copyAll = () => clipboardAll.copy(JSON.stringify(values, null, 2));

  const items = values.map((shade) => (
    <button
      type="button"
      key={uuid()}
      className={`color-shades-list__shade ${clipboard.copied ? 'color-shades-list__shade--copied' : ''}`}
      onClick={() => clipboard.copy(shade)}
      data-content={clipboard.copied ? t('colorShadesGenerator.copiedLabel') : t('colorShadesGenerator.copyLabel')}
    >
      <div className="color-shades-list__preview" style={{ backgroundColor: shade }} />
      <div className="color-shades-list__value">{shade}</div>
    </button>
  ));

  return (
    <Background
      additionalClass="color-shades-list"
    >
      <div className="color-shades-list__header">
        <HexInput value={value} onChange={onChange} />
        <div>
          <button
            className={`color-shades-list__copy-all-button ${clipboard.copied ? 'color-shades-list__copy-all-button--copied' : ''}`}
            type="button"
            onClick={copyAll}
          >
            {clipboardAll.copied ? t('colorShadesGenerator.copiedAllLabel') : t('colorShadesGenerator.copyAllLabel')}
          </button>
          {canDelete && (
            <button type="button" className="color-shades-list__remove-button" onClick={onDelete}>
              {t('colorShadesGenerator.removeLabel')}
            </button>
          )}
        </div>
      </div>
      <Scrollbars style={{ width: '100%', height: 110 }}>
        <div className="color-shades-list__shades">{items}</div>
      </Scrollbars>
    </Background>
  );
};

ColorShadesList.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool.isRequired,
  saturation: PropTypes.number.isRequired,
  darken: PropTypes.number.isRequired,
};

export default ColorShadesList;
