import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import useWindowWidth from 'hooks/useWindowWidth';
import Logo from 'components/global/Logo';
import NAVIGATION from 'config/navigation';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';

import './Navigation.scss';

const MOBILE_NAVIGATION_BREAKPOINT = 992;

const Navigation = ({
  onChangePage,
}) => {
  const width = useWindowWidth();
  const location = useLocation();
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const isMobile = width <= MOBILE_NAVIGATION_BREAKPOINT;
  const { t } = useTranslation();
  const {
    setUserToken,
    setIsLogged,
  } = useContext(AuthContext);

  const {
    setUserEmail,
    setUserId,
    setUserName,
    setUserRole,
    setUserImage,
    setUserPosition,
    setUserPhone,
    setUserSlackAccount,
    setUserSkypeAccount,
    setUserStartDate,
    setUserLeaveDays,
    setUserPersonalNumber,
    isRememberMode,
  } = useContext(UserContext);

  const mobileNavigationClassHelper = () => {
    if (isMobile) {
      return isNavigationOpen ? 'navigation--open' : 'navigation--closed';
    }

    return '';
  };

  const onClickSignOut = () => {
    setUserToken(null);
    setIsLogged(false);

    if (!isRememberMode) {
      setUserEmail('');
      setUserId(0);
      setUserName('');
      setUserRole('');
      setUserImage('');
      setUserPosition('');
      setUserPhone('');
      setUserSlackAccount('');
      setUserSkypeAccount('');
      setUserStartDate('');
      setUserPersonalNumber('');
      setUserLeaveDays('');
    }
  };

  useEffect(() => {
    const pageTitle = NAVIGATION.filter((link) => link.path === location.pathname);

    if (pageTitle[0]) {
      onChangePage(t(pageTitle[0].meta.translation));
    } else if (location.pathname === '/add-member') {
      onChangePage(t('navigation.addMemberPageTitle'));
    } else if (location.pathname === '/edit-member') {
      onChangePage(t('editMemberPage.pageTitle'));
    } else if (location.pathname === '/add-leave-request') {
      onChangePage(t('leavesPage.pageTitle'));
    } else if (location.pathname === '/add-homeoffice-request') {
      onChangePage(t('homeOfficeRequestPage.pageTitle'));
    } else if (location.pathname === '/add-annual-leave-request') {
      onChangePage(t('annualLeaveRequestPage.pageTitle'));
    } else if (location.pathname === '/resolve-request') {
      onChangePage(t('resolveRequestPage.pageTitle'));
    } else if (location.pathname === '/annual-leave-signing') {
      onChangePage(t('signAnnualLeavePage.pageTitle'));
    } else if (location.pathname === '/add-announcement') {
      onChangePage(t('addAnnouncementPage.pageTitle'));
    } else if (location.pathname === '/edit-announcement') {
      onChangePage(t('editAnnouncementPage.pageTitle'));
    } else if (location.pathname === '/announcement-article') {
      onChangePage(t('announcementArticlePage.pageTitle'));
    } else {
      onChangePage(t('navigation.toolsPageTitle'));
    }
  }, [location, onChangePage, t]);

  return (
    <nav className={`navigation ${mobileNavigationClassHelper()}`}>
      {isMobile && (
        <>
          <div className="navigation__background" />
          <button
            type="button"
            onClick={() => setIsNavigationOpen(!isNavigationOpen)}
            className="navigation__close-button"
          >
            <svg
              className={`navigation__header-logo ${isNavigationOpen ? 'navigation__header-logo--white' : ''}`}
              viewBox="0 0 49 55"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M36.8 18l11.4-8.2-3.9-5.4-7.6 5.5C35.9 4.2 31 .6 25.4.6 21.9.6 13.8 2 13.8 14.3v10.5H.8V35c0 18.4 14.1 19.4 18 19.4 7.1 0 18.4-2.8 18.4-19.5V24.8h11V18H36.8zM25.4 6.7c3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4" />
            </svg>
            {isNavigationOpen ? t('navigation.mobileMenuCloseTitle') : t('navigation.mobileMenuTitle')}
          </button>
        </>
      )}
      <div className="navigation__wrapper">
        <Logo white additionalClass="navigation__logo" />
        <ul className="navigation__list">
          {NAVIGATION.map((link) => (
            <NavLink
              to={link.path}
              key={uuid()}
              className="navigation__link"
              activeClassName="navigation__link--active"
              exact
              onClick={() => setIsNavigationOpen(false)}
            >
              {t(link.meta.translation)}
            </NavLink>
          ))}
          <NavLink
            to="/login"
            className="navigation__link navigation__link--sign-out"
            activeClassName="navigation__link--active"
            onClick={onClickSignOut}
          >
            {t('navigation.signOutTitle')}
          </NavLink>
        </ul>
      </div>
    </nav>
  );
};

Navigation.propTypes = {
  onChangePage: PropTypes.func.isRequired,
};

export default Navigation;
