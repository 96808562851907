import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import authService from 'services/authService';
import { AuthContext } from 'context/AuthContext';
import PageLayout from 'layouts/PageLayout';
import CreatePasswordForm from 'components/forms/CreatePasswordForm';

const CreatePassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { setIsNotification } = useContext(AuthContext);
  const { email, activate, token } = useParams();
  const [emailParam, setEmailParam] = useState('');
  const [activateTokenParam, setActivateTokenParam] = useState('');
  const [tokenParam, setTokenParam] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [notification, setNotification] = useState('');

  useDocumentTitle(t('createPasswordPage.pageTitle'));

  const onClickButtonHandler = async (password) => {
    try {
      await authService.activateAccount(activateTokenParam);

      setIsNotification(false);
    } catch (error) {
      setIsNotification(false);
    }

    try {
      await authService
        .createPassword(emailParam, password, password, tokenParam);

      setIsNotification(false);

      history.push('/login');
    } catch (error) {
      setNotification(t('notification.createPasswordErrorMessage'));
      setIsNotification(true);
    }
  };

  useEffect(() => {
    if (email && activate && token && !isLoaded) {
      setEmailParam(email);
      setActivateTokenParam(activate);
      setTokenParam(token);
      setIsLoaded(true);

      history.push('/create-password');
    }
  }, [location.pathname, email, activate, token,
    history, isLoaded, emailParam, tokenParam, activateTokenParam, setIsNotification]);

  return (
    <PageLayout>
      <CreatePasswordForm
        title={t('createPasswordPage.title')}
        passwordLabel={t('createPasswordPage.passwordLabel')}
        repeatPasswordLabel={t('createPasswordPage.repeatPasswordLabel')}
        buttonTitle={t('createPasswordPage.buttonTitle')}
        onClickHandler={onClickButtonHandler}
        notificationMessage={notification}
        notMatchHint={t('notification.passwordsDoNotMatchHint')}
      />
    </PageLayout>
  );
};

export default CreatePassword;
