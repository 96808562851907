import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import useDeviceDetect from 'hooks/useDeviceDetect';
import { AuthContext } from 'context/AuthContext';
import { NotificationContext } from 'context/NotificationContext';
import systemService from 'services/systemService';
import DrawingArea from './DrawingArea';
import PdfDocument from './PdfDocument';

import './SignAnnualLeave.scss';

const SignAnnualLeave = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useDeviceDetect();
  const { userToken } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [nameInvalidInput, setNameInvalidInput] = useState(false);
  const [personalNumber, setPersonalNumber] = useState(0);
  const [job, setJob] = useState('');
  const [jobPositionInvalidInput, setJobPositionInvalidInput] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [days, setDays] = useState(0);
  const [signature, setSignature] = useState('');
  const [option, setOption] = useState('');
  const [requestId, setRequestId] = useState(0);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(t('signAnnualLeavePage.pageTitle'));

  const UploadDocument = (
    <PdfDocument
      name={name}
      privateNumber={personalNumber}
      job={job}
      days={days}
      startDate={startDate}
      option={option}
      signature={signature}
    />
  );

  const onClickSaveHandler = async (blob) => {
    if (!name || !job) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else {
      setFormNotification('');
    }

    setJobPositionInvalidInput(job === '');
    setNameInvalidInput(name === '');

    if (job && name && signature) {
      setIsLoading(true);

      try {
        const formData = new FormData();

        formData.append('file', blob);
        formData.append('requestId', requestId);

        const { data } = await systemService.sendDocument(formData, userToken);

        if (data.responseDescription) {
          setNotification(t('notification.signRequestSuccessMessage'));
          setNotificationType('success');
          resetNotification();

          setIsLoading(false);

          history.push('/leaves');
        }
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      const { request } = location.state;
      const formatDate = (new Date(request.startingFrom)).toLocaleDateString('en-GB').split('/').join('.');

      setStartDate(formatDate);
      setOption(request.annualLeaveType);
      setDays(request.days);
      setRequestId(request.id);
      setPersonalNumber(request.requester.personalNumber);
    }
  }, [location]);

  useEffect(() => {
    if (isMobile) {
      history.push('/leaves');
    }
  }, [history, isMobile]);

  return (
    <SystemLayout>
      <section className="sign-annual-leave">
        <div className="sign-annual-leave__wrapper">
          <form className="sign-annual-leave__form">
            <FormInput
              system
              isInvalidInput={nameInvalidInput}
              additionalClass="sign-annual-leave__input"
              type="text"
              value={name}
              id="names"
              placeholder={t('signAnnualLeavePage.namesNumberLabel')}
              onChange={(e) => setName(e.target.value)}
            />
            <FormInput
              system
              isInvalidInput={jobPositionInvalidInput}
              additionalClass="sign-annual-leave__input"
              type="text"
              value={job}
              id="job-position"
              placeholder={t('signAnnualLeavePage.positionLabel')}
              onChange={(e) => setJob(e.target.value)}
            />
            <FormNotification additionalClass="annual-leave-request-form__notification" message={formNotification} />
          </form>
          <div className="sign-annual-leave__signature-wrapper">
            <DrawingArea
              signButtonTitle={t('signAnnualLeavePage.signButtonTitle')}
              resetButtonTitle={t('signAnnualLeavePage.resetButtonTitle')}
              onSaveImage={(image) => setSignature(image)}

            />
            <BlobProvider document={UploadDocument}>
              {({ blob }) => (
                <>
                  {name && job && signature && (
                    <Button
                      additionalClass="sign-annual-leave__button"
                      onClickHandler={() => onClickSaveHandler(blob)}
                      title={t('signAnnualLeavePage.saveButtonTitle')}
                      loading={isLoading}
                    />
                  )}
                </>
              )}
            </BlobProvider>
          </div>
          {name && job && signature && (
            <PDFViewer className="sign-annual-leave__preview">
              {UploadDocument}
            </PDFViewer>
          )}
        </div>
      </section>
    </SystemLayout>
  );
};

export default SignAnnualLeave;
