import React from 'react';
import PropTypes from 'prop-types';

import './Logo.scss';

const Logo = ({
  white,
  additionalClass,
}) => (
  <div className={`logo ${additionalClass}`}>
    <svg
      className={`logo__image ${white && 'logo__image--white'}`}
      viewBox="0 0 49 55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M36.8 18l11.4-8.2-3.9-5.4-7.6 5.5C35.9 4.2 31 .6 25.4.6 21.9.6 13.8 2 13.8 14.3v10.5H.8V35c0 18.4 14.1 19.4 18 19.4 7.1 0 18.4-2.8 18.4-19.5V24.8h11V18H36.8zM25.4 6.7c3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4" />
    </svg>
    <h1 className={`logo__title ${white ? 'logo__title--white' : ''}`}>Rubber Duck</h1>
  </div>
);

Logo.defaultProps = {
  white: false,
  additionalClass: '',
};

Logo.propTypes = {
  white: PropTypes.bool,
  additionalClass: PropTypes.string,
};

export default Logo;
