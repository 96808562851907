import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    render={(props) => (
      route.redirect
        ? <Redirect to={route.redirect} />
        : <route.component {...props} routes={route.routes} />
    )}
  />
);

export default RouteWithSubRoutes;
