import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SliderInput from 'components/tools/SliderInput';
import Button from 'components/tools/Button';
import Tabs from 'components/tools/Tabs';
import Background from 'components/tools/Background';

import { AVAILABLE_GENERATORS } from '../generate-text';

import './Settings.scss';

const types = AVAILABLE_GENERATORS.map((generator) => ({
  value: generator,
  label: `${generator.charAt(0).toUpperCase() + generator.slice(1)} ipsum`,
}));

const Settings = ({
  onTypeChange, type, length, onLengthChange, onSubmit, copied,
}) => {
  const { t } = useTranslation();
  return (
    <Background additionalClass="lorem-ipsum-settings">
      <Tabs data={types} active={type} onTabChange={onTypeChange} noBorderOnMobile additionalClass="lorem-ipsum-settings__tabs" />
      <div className="lorem-ipsum-settings__wrapper">
        <div className="lorem-ipsum-settings__input-wrapper">
          <h3 className="lorem-ipsum-settings__input-label">{t('loremIpsumGenerator.inputLabel')}</h3>
          <SliderInput value={length} min={1} max={20} onChange={onLengthChange} />
        </div>
        <Button
          additionalClass="lorem-ipsum-settings__button"
          onClick={onSubmit}
          disabled={copied}
        >
          {copied ? t('loremIpsumGenerator.copiedCodeButtonTitle') : t('loremIpsumGenerator.copyCodeButtonTitle')}
        </Button>
      </div>
    </Background>
  )
};

Settings.propTypes = {
  onTypeChange: PropTypes.func.isRequired,
  onLengthChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  copied: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(AVAILABLE_GENERATORS).isRequired,
  length: PropTypes.number.isRequired,
};

export default Settings;
