import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import systemService from 'services/systemService';
import { AuthContext } from 'context/AuthContext';
import { NotificationContext } from 'context/NotificationContext';

import './ResolveRequestForm.scss';

const MAX_CHARACTERS_LIMIT = 5000;

const ResolveRequestForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { userToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [resolution, setResolution] = useState('');
  const [resolutionInvalidInput, setResolutionInvalidInput] = useState(false);
  const [status, setStatus] = useState('approved');
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(t('resolveRequestPage.pageTitle'));

  const onClickButtonHandler = async () => {
    if (resolution.split('').length > MAX_CHARACTERS_LIMIT) {
      setFormNotification(t('notification.characterLimitErrorMessage'));
    } else if (!resolution) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else {
      setFormNotification('');
    }

    setResolutionInvalidInput(resolution === ''
      || resolution.split('').length > MAX_CHARACTERS_LIMIT);

    if (status !== ''
      && resolution !== ''
      && resolution.split('').length < MAX_CHARACTERS_LIMIT) {
      setIsLoading(true);

      try {
        const { data } = await systemService.resolveRequest({
          requestId: location.state.id,
          status,
          resolution,
        }, userToken);

        if (data.responseDescription) {
          setNotification(t('notification.resolveRequestSuccessMessage'));
          setNotificationType('success');
          resetNotification();

          setIsLoading(false);

          history.push('/leaves');
        }
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }

        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!location.state) {
      history.push('/announcements-list');
    }
  }, [history, location.state]);

  return (
    <SystemLayout>
      <section className="resolve-request">
        <form className="resolve-request__container">
          <label htmlFor="status">{t('resolveRequestPage.statusLabel')}</label>
          <select
            className="resolve-request__input resolve-request__select form-input form-input--system"
            name="status"
            id="status"
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="approved">{t('resolveRequestPage.approvedLabel')}</option>
            <option value="declined">{t('resolveRequestPage.declinedLabel')}</option>
          </select>
          <FormInput
            system
            isInvalidInput={resolutionInvalidInput}
            additionalClass="resolve-request__input"
            type="text"
            id="resolution"
            name="resolution"
            value={resolution}
            placeholder={t('resolveRequestPage.resolutionLabel')}
            onChange={(e) => setResolution(e.target.value)}
          />
          <FormNotification additionalClass="resolve-request__notification" message={formNotification} />
          <Button
            title={t('resolveRequestPage.buttonTitle')}
            onClickHandler={onClickButtonHandler}
            loading={isLoading}
          />
        </form>
      </section>
    </SystemLayout>
  );
};

export default ResolveRequestForm;
