import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import { NotificationContext } from 'context/NotificationContext';
import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import { AuthContext } from 'context/AuthContext';
import userService from 'services/userService';

import './EditMember.scss';

const PERSONAL_NUMBER_REGEX = /^\d{10}$/gm;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EditMember = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { userToken } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [position, setPosition] = useState('');
  const [positionInvalidInput, setPositionInvalidInput] = useState(false);
  const [leaveDays, setLeaveDays] = useState(0);
  const [leaveDaysInvalidInput, setLeaveDaysInvalidInput] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [firstNameInvalidInput, setFirstNameInvalidInput] = useState(false);
  const [middleName, setMiddleName] = useState('');
  const [middleNameInvalidInput, setMiddleNameInvalidInput] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameInvalidInput, setLastNameInvalidInput] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneInvalidInput, setPhoneInvalidInput] = useState(false);
  const [personalNumber, setPersonalNumber] = useState('');
  const [personalNumberInvalidInput, setPersonalNumberInvalidInput] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [startDateInvalidInput,
    setStartDateInvalidInput] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [,
    setEndDateInvalidInput] = useState(false);
  const [slack, setSlack] = useState('');
  const [skype, setSkype] = useState('');
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isRoleUpdated, setIsRoleUpdated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [activatedStatus, setActivatedStatus] = useState(false);
  const [isUserSet, setIsUserSet] = useState(false);
  const [formNotification, setFormNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(t('editMemberPage.pageTitle'));

  const onErrorHandler = () => {
    setNotification(t('notification.errorMessage'));
    setNotificationType('error');
  };

  const setRoleHelper = async (role, userId) => {
    try {
      await userService.setRole({
        userId,
        role,
      }, userToken);
    } catch (error) {
      console.log(error);
    }
  };

  const removeRoleHelper = async (roleId, userId) => {
    try {
      await userService.removeRole({
        userId,
        roleId,
      }, userToken);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickSaveHandler = async () => {
    if (!firstName
      || !middleName
      || !lastName
      || !position
      || !email
      || !personalNumber
      || !leaveDays
      || !phone
      || !startDate) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else if (emailInvalidInput) {
      setFormNotification(t('notification.invalidEmailErrorMessage'));
    } else if (personalNumberInvalidInput) {
      setFormNotification(t('notification.invalidPersonalNumberErrorMessage'));
    } else if (phoneInvalidInput) {
      setFormNotification(t('notification.invalidPhoneErrorMessage'));
    } else {
      setFormNotification('');
    }
    const isInputValidated = position !== ''
      && leaveDays !== ''
      && firstName !== ''
      && middleName !== ''
      && lastName !== ''
      && email !== '' && EMAIL_REGEX.test(email)
      && PERSONAL_NUMBER_REGEX.test(personalNumber) && personalNumber !== ''
      && phone !== '';

    if (isInputValidated) {
      const userData = {
        firstName,
        middleName,
        lastName,
        email,
        phone,
        personalNumber,
        employeeStartDate: startDate,
        jobPosition: position,
        availableAnnualLeaveDays: leaveDays,
      };

      if (endDate) {
        userData.employeeEndDate = endDate;
      }

      if (slack) {
        userData.slack = slack;
      }

      if (skype) {
        userData.skype = skype;
      }

      setIsLoading(true);

      try {
        await userService.updateUser(user.userId, userData, userToken);

        setNotification(t('notification.editMemberSuccessMessage'));
        setNotificationType('success');
        resetNotification();

        setIsDataUpdated(true);

        setIsLoading(false);

        history.push('/members-list');
      } catch (error) {
        setNotification(t('notification.editMemberErrorMessage'));
        setNotificationType('error');

        setIsLoading(false);
      }
    }

    if (isAdmin || isApprover || isEmployee) {
      const admin = user.roles.find((item) => item.title === 'admin');
      const approver = user.roles.find((item) => item.title === 'approver');
      const employee = user.roles.find((item) => item.title === 'employee');

      if (isAdmin && !admin) {
        setRoleHelper('admin', user.userId);
      }

      if (!isAdmin && admin) {
        const { id } = admin;

        removeRoleHelper(id, user.userId);
      }

      if (isApprover && !approver) {
        setRoleHelper('approver', user.userId);
      }

      if (!isApprover && approver) {
        const { id } = approver;

        removeRoleHelper(id, user.userId);
      }

      if (isEmployee && !employee) {
        setRoleHelper('employee', user.userId);
      }

      if (!isEmployee && employee) {
        const { id } = employee;

        removeRoleHelper(id, user.userId);
      }

      setIsRoleUpdated(true);
    }
  };

  const onClickDeactivateHandler = async () => {
    try {
      const { data } = await userService
        .deactivateUser(user.id, userToken);

      if (data) {
        setNotification(t('notification.deactivateMemberSuccessMessage'));
        setNotificationType('success');

        resetNotification();
      }
    } catch (error) {
      onErrorHandler();
    }
  };

  const onClickResendActivationHandler = async () => {
    try {
      await userService
        .resendActivationMail(user.email, userToken);

      setNotification(t('notification.resendActivationSuccessMessage'));
      setNotificationType('success');

      resetNotification();
    } catch (error) {
      onErrorHandler();
    }
  };

  useEffect(() => {
    setPositionInvalidInput(position === '');
    setLeaveDaysInvalidInput(leaveDays === '');
    setFirstNameInvalidInput(firstName === '');
    setMiddleNameInvalidInput(middleName === '');
    setLastNameInvalidInput(lastName === '');
    setEmailInvalidInput(!EMAIL_REGEX.test(email));
    setPersonalNumberInvalidInput(personalNumber === '' || !PERSONAL_NUMBER_REGEX.test(personalNumber));
    setStartDateInvalidInput(startDate === '');
    setEndDateInvalidInput(endDate === '');
    setPhoneInvalidInput(phone === '');
  }, [email, endDate, firstName, lastName, leaveDays,
    middleName, personalNumber, phone, position, startDate]);

  useEffect(() => {
    if (isDataUpdated && isRoleUpdated) {
      history.push('/members-list');
    }
  }, [history, isDataUpdated, isRoleUpdated]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const currentUserId = location.state.id;

        const { data } = await userService
          .getUserData(currentUserId, userToken);

        setUser(data.additionalDetails);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!location.state) {
      history.push('/members-list');
    }

    if (!isLoaded && location.state) {
      getUser();
      setIsLoaded(true);
    }
  }, [history, isLoaded, location.state, setNotification,
    setNotificationType, t, user, userToken]);

  useEffect(() => {
    if (user && !isUserSet) {
      setPosition(user.jobPosition);
      setLeaveDays(user.availableAnnualLeaveDays);
      setActivatedStatus(user.status);
      setFirstName(user.firstName);
      setMiddleName(user.middleName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPhone(user.phone ? user.phone : '');
      setPersonalNumber(user.personalNumber);
      setSlack(user.slack ? user.slack : '');
      setSkype(user.skype ? user.skype : '');
      setStartDate(user.employeeStartDate ? user.employeeStartDate : '');
      setEndDate(user.employeeEndDate ? user.employeeEndDate : '');

      if (user.roles) {
        const admin = user.roles.find((item) => item.title === 'admin');
        const approver = user.roles.find((item) => item.title === 'approver');
        const employee = user.roles.find((item) => item.title === 'employee');

        setIsAdmin(!!admin);
        setIsApprover(!!approver);
        setIsEmployee(!!employee);
      }

      setIsUserSet(true);
    }
  }, [isUserSet, user]);

  return (
    <SystemLayout>
      <section className="edit-member-page">
        {isLoaded && user ? (
          <form className="edit-member-page__input-container">
            <FormInput
              system
              isInvalidInput={firstNameInvalidInput}
              additionalClass="edit-member-page__input"
              type="text"
              name="first-name"
              id="first-name"
              placeholder={t('editMemberPage.firstNameLabel')}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={middleNameInvalidInput}
              additionalClass="edit-member-page__input"
              type="text"
              name="middle-name"
              id="middle-name"
              placeholder={t('editMemberPage.middleNameLabel')}
              value={middleName}
              onChange={(event) => setMiddleName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={lastNameInvalidInput}
              additionalClass="edit-member-page__input"
              type="text"
              name="last-name"
              id="last-name"
              placeholder={t('editMemberPage.lastNameLabel')}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={emailInvalidInput}
              additionalClass="edit-member-page__input"
              type="email"
              name="email"
              id="email"
              placeholder={t('editMemberPage.emailLabel')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={personalNumberInvalidInput}
              additionalClass="edit-member-page__input"
              type="text"
              name="personal-number"
              id="personal-number"
              placeholder={t('addMemberPage.personalNumberLabel')}
              value={personalNumber}
              onChange={(event) => setPersonalNumber(event.target.value)}
            />
            <div className="edit-member-page__input-group">
              <label htmlFor="start-date" className="edit-member-page__input-label">
                {t('editMemberPage.startDateLabel')}
              </label>
              <FormInput
                system
                isInvalidInput={startDateInvalidInput}
                additionalClass="edit-member-page__input"
                type="date"
                id="start-date"
                name="start-date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="edit-member-page__input-group">
              <label htmlFor="end-date" className="edit-member-page__input-label">
                {t('editMemberPage.endDateLabel')}
              </label>
              <FormInput
                system
                isInvalidInput={false}
                additionalClass="edit-member-page__input"
                type="date"
                id="end-date"
                name="end-date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="edit-member-page__input-group">
              <label htmlFor="position" className="edit-member-page__input-label">
                {t('editMemberPage.positionLabel')}
              </label>
              <FormInput
                system
                isInvalidInput={positionInvalidInput}
                additionalClass="edit-member-page__input"
                type="text"
                name="position"
                value={position}
                id="position"
                placeholder={t('editMemberPage.positionLabel')}
                onChange={(event) => setPosition(event.target.value)}
              />
            </div>
            <div className="edit-member-page__input-group">
              <label htmlFor="leave-days" className="edit-member-page__input-label">
                {t('editMemberPage.leaveDaysLabel')}
              </label>
              <FormInput
                system
                isInvalidInput={leaveDaysInvalidInput}
                additionalClass="edit-member-page__input"
                type="number"
                name="leave-days"
                value={leaveDays}
                id="leave-days"
                placeholder={t('editMemberPage.leaveDaysLabel')}
                onChange={(event) => setLeaveDays(event.target.value)}
              />
            </div>
            <fieldset className="edit-member-page__role-wrapper">
              <legend className="edit-member-page__info">{t('editMemberPage.roleLabel')}</legend>
              <div className="edit-member-page__input-group">
                <label htmlFor="admin" className={`edit-member-page__checkbox ${isAdmin ? 'edit-member-page__checkbox--checked' : ''}`}>
                  {t('editMemberPage.adminLabel')}
                  <input
                    className="hidden"
                    type="checkbox"
                    name="admin"
                    id="admin"
                    checked={isAdmin}
                    onChange={() => setIsAdmin(!isAdmin)}
                  />
                </label>
              </div>
              <div className="edit-member-page__input-group">
                <label htmlFor="employee" className={`edit-member-page__checkbox ${isEmployee ? 'edit-member-page__checkbox--checked' : ''}`}>
                  {t('editMemberPage.employeeLabel')}
                  <input
                    className="hidden"
                    type="checkbox"
                    name="employee"
                    id="employee"
                    checked={isEmployee}
                    onChange={() => setIsEmployee(!isEmployee)}
                  />
                </label>
              </div>
              <div className="edit-member-page__input-group">
                <label htmlFor="approver" className={`edit-member-page__checkbox ${isApprover ? 'edit-member-page__checkbox--checked' : ''}`}>
                  {t('editMemberPage.approverLabel')}
                  <input
                    className="hidden"
                    type="checkbox"
                    name="approver"
                    id="approver"
                    checked={isApprover}
                    onChange={() => setIsApprover(!isApprover)}
                  />
                </label>
              </div>
            </fieldset>
            <FormInput
              system
              isInvalidInput={phoneInvalidInput}
              additionalClass="edit-member-page__input"
              type="text"
              name="phone"
              id="phone"
              placeholder={t('editMemberPage.phoneInputLabel')}
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={false}
              additionalClass="edit-member-page__input"
              type="text"
              name="skype"
              id="skype"
              placeholder={t('editMemberPage.skypeInputLabel')}
              value={skype}
              onChange={(event) => setSkype(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={false}
              additionalClass="edit-member-page__input"
              type="text"
              name="text"
              id="slack"
              placeholder={t('editMemberPage.slackInputLabel')}
              value={slack}
              onChange={(event) => setSlack(event.target.value)}
            />
            <div className="edit-member-page__status-wrapper">
              <p className="edit-member-page__status-label">
                {t('editMemberPage.statusLabel')}
                {`${activatedStatus === 1 ? t('editMemberPage.activatedLabel') : t('editMemberPage.notActivatedLabel')}`}
              </p>
              {activatedStatus === 0 ? (
                <Button
                  additionalClass="edit-member-page__resend-button"
                  title={t('editMemberPage.resendActivationButtonTitle')}
                  onClickHandler={onClickResendActivationHandler}
                />
              ) : (
                <Button
                  additionalClass="edit-member-page__deactivate-button"
                  title={t('editMemberPage.deactivateButtonTitle')}
                  onClickHandler={onClickDeactivateHandler}
                />
              )}
            </div>
            <FormNotification additionalClass="annual-leave-request-form__notification" message={formNotification} />
            <div>
              <Button
                title={t('editMemberPage.saveButtonTitle')}
                onClickHandler={onClickSaveHandler}
                loading={isLoading}
              />
            </div>
          </form>
        ) : (
          <p> loading </p>
        )}
      </section>
    </SystemLayout>
  );
};

export default EditMember;
