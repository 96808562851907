import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Background from 'components/tools/Background';

import './Preview.scss';

const Preview = ({ text }) => {
  const nodes = text
    .split('\n')
    .filter(Boolean)
    .map((node) => <p className="lorem-ipsum-preview__item" key={uuid()}>{node}</p>);

  return <Background additionalClass="lorem-ipsum-preview">{nodes}</Background>;
}

Preview.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Preview;
