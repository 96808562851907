import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/global/Avatar';
import Button from 'components/global/Button';
import FormInput from 'components/global/FormInput';
import FormNotification from 'components/global/FormNotification';

import './CreatePasswordForm.scss';

const CreatePasswordForm = ({
  title,
  passwordLabel,
  repeatPasswordLabel,
  buttonTitle,
  notificationMessage,
  onClickHandler,
  notMatchHint,
}) => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [hint, setHint] = useState('');
  const [passwordInvalidInput, setPasswordInvalidInput] = useState(false);
  const [repeatPasswordInvalidInput, setRepeatPasswordInvalidInput] = useState(false);

  const onClickButtonHandler = () => {
    setPasswordInvalidInput(password === '');
    setRepeatPasswordInvalidInput(password === '');

    if (repeatPassword && password
      && repeatPassword === password) {
      onClickHandler(password, repeatPassword);
    }
  };

  useEffect(() => {
    setHint(password !== repeatPassword ? notMatchHint : '');
  }, [notMatchHint, password, repeatPassword]);

  return (
    <section className="create-password-form">
      <div className="create-password-form__wrapper">
        <h2 className="create-password-form__title">{title}</h2>
        <form className="create-password-form__container">
          <Avatar
            additionalClass="create-password-form__avatar"
            circle
          />
          <div className="create-password-form__input-container">
            <label
              htmlFor="password"
              className={`create-password-form__label ${passwordInvalidInput ? 'create-password-form__label--invalid' : ''}`}
            >
              {passwordLabel}
            </label>
            <FormInput
              isInvalidInput={passwordInvalidInput}
              additionalClass="create-password-form__input"
              type="password"
              name="password"
              id="password"
              placeholder={passwordLabel}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <label
              htmlFor="password"
              className={`create-password-form__label ${repeatPasswordInvalidInput ? 'create-password-form__label--invalid' : ''}`}
            >
              {repeatPasswordLabel}
            </label>
            <FormInput
              isInvalidInput={repeatPasswordInvalidInput}
              additionalClass="create-password-form__input"
              type="password"
              name="repeat-password"
              value={repeatPassword}
              id="repeat-password"
              placeholder={repeatPasswordLabel}
              onChange={(event) => setRepeatPassword(event.target.value)}
            />
            <span className="create-password-form__hint">{hint}</span>
            <FormNotification additionalClass="user-form__notificaiton" message={notificationMessage} />
          </div>
          <Button
            title={buttonTitle}
            onClickHandler={onClickButtonHandler}
          />
        </form>
      </div>
    </section>
  );
};

CreatePasswordForm.defaultProps = {
  passwordLabel: '',
  repeatPasswordLabel: '',
};

CreatePasswordForm.propTypes = {
  passwordLabel: PropTypes.string,
  notMatchHint: PropTypes.string.isRequired,
  repeatPasswordLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default CreatePasswordForm;
