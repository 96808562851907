import React from 'react';

import Logo from 'components/global/Logo';

const Header = () => (
  <header>
    <Logo />
  </header>
);

export default Header;
