export default [
  {
    path: '/dashboard',
    meta: {
      translation: 'navigation.dashboardPageTitle',
    },
  },
  {
    path: '/tools/tools-list',
    meta: {
      translation: 'navigation.toolsPageTitle',
    },
  },
  {
    path: '/announcements-list',
    meta: {
      translation: 'navigation.announcementsPageTitle',
    },
  },
  {
    path: '/leaves',
    meta: {
      translation: 'navigation.leavesPageTitle',
    },
  },
  {
    path: '/members-list',
    meta: {
      translation: 'navigation.membersPageTitle',
    },
  },
  {
    path: '/profile',
    meta: {
      translation: 'navigation.profilePageTitle',
    },
  },
  {
    path: '/faq',
    meta: {
      translation: 'navigation.faqPageTitle',
    },
  },
];
