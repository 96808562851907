import React from 'react';
import PropTypes from 'prop-types';

import './Background.scss';

const Background = ({
  additionalClass, children, theme,
}) => (
  <div
    className={`background ${additionalClass} ${theme}`}
  >
    {children}
  </div>
);

Background.defaultProps = {
  additionalClass: '',
  theme: 'light',
};

Background.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
};

export default Background;
