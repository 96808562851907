import React from 'react';
import PropTypes from 'prop-types';

import './Keycap.scss';

const PREDEFINED_VALUES = {
  ArrowUp: '↑',
  ArrowRight: '→',
  ArrowLeft: '←',
  ArrowDown: '↓',
  Backspace: '⇇',
  Tab: '⇥',
  Escape: 'Esc',
  Enter: null,
  Cmd: null,
};

const Keycap = ({ value, additionalClass }) => (
  <div
    className={`keycap ${value === 'Shift' ? 'keycap--shift' : ''} ${value === 'Alt' ? 'keycap--alt' : ''} ${value === 'Ctrl' ? 'keycap--control' : ''} ${value === 'Cmd' ? 'keycap--meta' : ''} ${value === 'Enter' ? 'keycap--enter' : ''} ${value === ' ' ? 'keycap--space' : ''} ${additionalClass}`}
  >
    <span className="keycap__letter">{value in PREDEFINED_VALUES ? PREDEFINED_VALUES[value] : value}</span>
  </div>
);

Keycap.defaultProps = {
  additionalClass: '',
};

Keycap.propTypes = {
  value: PropTypes.string.isRequired,
  additionalClass: PropTypes.string,
};

export default Keycap;
