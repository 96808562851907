import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import authService from 'services/authService';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import LoginForm from 'components/forms/LoginForm';
import PageLayout from 'layouts/PageLayout';

const Login = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    userToken,
    setUserToken,
    isNotification,
    setIsNotification,
    setIsLogged,
    isLogged,
  } = useContext(AuthContext);
  const {
    setUserEmail,
    setUserId,
  } = useContext(UserContext);
  const [notification, setNotification] = useState('');

  useDocumentTitle(t('loginPage.pageTitle'));

  const onClickLoginHandler = async (email, password) => {
    try {
      const response = await authService.signIn(email, password);

      setIsNotification(false);
      setIsLogged(true);

      setUserToken(response.data.additionalDetails.accessToken);
      setUserEmail(response.data.additionalDetails.userInfo.email);
      setUserId(response.data.additionalDetails.userInfo.userId)
    } catch (error) {
      setIsNotification(true);
      setNotification(t('notification.incorrectLoginDetailsMessage'));
    }
  };

  useEffect(() => {
    if (userToken && isLogged) {
      history.push('dashboard');
    }
  }, [userToken, history, isLogged]);

  return (
    <PageLayout>
      <LoginForm
        title={t('loginPage.title')}
        buttonTitle={t('loginPage.buttonTitle')}
        resetButtonTitle={t('loginPage.resetButtonTitle')}
        emailLabel={t('loginPage.emailLabel')}
        passwordLabel={t('loginPage.passwordLabel')}
        rememberOptionTitle={t('loginPage.remmeberOptionTitle')}
        onClickHandler={onClickLoginHandler}
        onClickResetHandler={() => history.push('/request-reset-password')}
        isError={isNotification}
        notificationMessage={notification}
      />
    </PageLayout>
  );
};

export default Login;
