import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import HexInput from 'components/tools/HexInput';
import SliderInput from 'components/tools/SliderInput';

import './GradientColors.scss';

const GradientColors = ({ additionalClass, values, handlers }) => {
  const { t } = useTranslation();

  const items = values.map((value, index) => (
    <div className="gradient-color" key={value.key}>
      <div className="gradient-color__field">
        <HexInput
          value={value.color}
          onChange={(color) => handlers.setItemProp(index, 'color', color)}
        />
      </div>
      <div className="gradient-color__field">
        <SliderInput
          value={value.opacity}
          onChange={(opacity) => handlers.setItemProp(index, 'opacity', opacity)}
          min={0}
          max={100}
          trackSize={100}
        />
      </div>
      {values.length > 2 && (
        <div className="gradient-color__field">
          <button
            className="gradient-color__button"
            type="button"
            onClick={() => handlers.remove(index)}
            title="Remove color stop"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>
        </div>
      )}
    </div>
  ));

  return (
    <div className={`gradient-colors ${additionalClass}`}>
      <div className="gradient-colors__header">
        <div className="gradient-colors__label" style={{ width: 258 }}>
          {t('gradientGenerator.gradientColorTitle')}
        </div>
        <div className="gradient-colors__label">{t('gradientGenerator.gradientOpacityTitle')}</div>
      </div>
      <div className="gradient-colors__items">{items}</div>
    </div>
  );
};

GradientColors.defaultProps = {
  additionalClass: '',
};

GradientColors.propTypes = {
  additionalClass: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      opacity: PropTypes.number.isRequired,
    }),
  ).isRequired,
  handlers: PropTypes.shape({
    setItemProp: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
};

export default GradientColors;
