import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDocumentTitle } from 'xooks';

import Avatar from 'components/global/Avatar';
import { AuthContext } from 'context/AuthContext';
import { NotificationContext } from 'context/NotificationContext';
import SystemLayout from 'layouts/SystemLayout';
import systemService from 'services/systemService';

import './AnnouncementArticle.scss';

const AnnoucementArticle = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userToken } = useContext(AuthContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [announcement, setAnnouncement] = useState(null);
  const {
    setNotification,
    setNotificationType,
  } = useContext(NotificationContext);

  const fileNameHelper = (fileName) => fileName.split('/')[1];

  useDocumentTitle(t('announcementArticlePage.pageTitle'));

  useEffect(() => {
    const getAnnoucementArticle = async () => {
      try {
        const { data } = await systemService.getAnnouncement(userToken, location.state.id);

        setAnnouncement(data.additionalDetails);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded) {
      getAnnoucementArticle();

      setIsLoaded(true);
    }
  }, [isLoaded, location.state.id, setNotification, setNotificationType, t, userToken]);

  return (
    <SystemLayout>
      {announcement ? (
        <section className="annoucement-article">
          <div className="annoucement-article__wrapper">
            <div className="annoucement-article__text-wrapper">
              <div className="annoucement-article__avatar-wrapper">
                <Avatar circle small additionalClass="annoucement-article__avatar" imageUrl={announcement.author.profilePicture} />
                <div>
                  <p className="annoucement-article__author-name">{announcement.author.name}</p>
                  <p className="annoucement-article__author-position">{announcement.author.jobPosition}</p>
                </div>
              </div>
              <h3 className="annoucement-article__title">{announcement.title}</h3>
              <p className="annoucement-article__description">{announcement.description}</p>
              <p className="annoucement-article__date">{announcement.createdAt}</p>
            </div>
            <div className="annoucement-article__image-wrapper">
              <img className="annoucement-article__image" src={announcement.featuredImage} alt={announcement.title} />
            </div>
          </div>
          <div className="annoucement-article__files-wrapper">
            <p className="annoucement-article__files-label">{t('announcementArticlePage.filesLabel')}</p>
            {announcement.attachments ? (
              <ul className="annoucement-article__list">
                {announcement.attachments.map((item) => (
                  <li className="annoucement-article__item" key={uuid()}>
                    <a className="annoucement-article__download-button tool-button" href={item.attachmentPath} download="attachment">{fileNameHelper(item.attachmentDetails.attachment)}</a>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('announcementArticlePage.noItems')}</p>
            )}
          </div>
        </section>
      ) : (
        <p>{t('announcementArticlePage.loading')}</p>
      )}

    </SystemLayout>
  )
};

export default AnnoucementArticle;
