import React from 'react';
import PropTypes from 'prop-types';
import { useClipboard } from 'xooks';
import { useTranslation } from 'react-i18next';

import Background from 'components/tools/Background';
import { generateGradientColorValues } from '../../generate-gradient-value';

import './GradientGalleryItem.scss';

const GradientGalleryItem = ({
  additionalClass, values, name, onEditorOpen,
}) => {
  const { t } = useTranslation();
  const clipboard = useClipboard();
  const gradient = `linear-gradient(to bottom, ${generateGradientColorValues(values)})`;

  return (
    <Background additionalClass={`gradient-gallery-item ${additionalClass}`}>
      <div className="gradient-gallery-item__header">
        <div className="gradient-gallery-item__name">{name}</div>
        <button
          className={`gradient-gallery-item__copy ${clipboard.copied ? 'gradient-gallery-item__copied' : ''}`}
          type="button"
          onClick={() => clipboard.copy(gradient)}
        >
          {clipboard.copied ? t('gradientGenerator.copiedGradientTitle') : t('gradientGenerator.copyGradientTitle')}
        </button>
      </div>
      <div className="gradient-gallery-item__preview" style={{ backgroundImage: gradient }} />
      <button className="gradient-gallery-item__button" type="button" onClick={() => onEditorOpen(values)}>
        {t('gradientGenerator.openInEditorTitle')}
      </button>
    </Background>
  );
}

GradientGalleryItem.defaultProps = {
  additionalClass: '',
};

GradientGalleryItem.propTypes = {
  additionalClass: PropTypes.string,
  name: PropTypes.string.isRequired,
  onEditorOpen: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      opacity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default GradientGalleryItem;
