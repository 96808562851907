import React from 'react';
import PropTypes from 'prop-types';
import { useClipboard } from 'xooks';

import CopyCodeButton from '../CopyCodeButton';

import './CodeContainer.scss';

const CodeContainer = ({ children }) => {
  const { copy, copied } = useClipboard();

  return (
    <div className="code-container">
      <CopyCodeButton
        copied={copied}
        additionalClass="code-container__copy"
        onClickHandler={() => copy(children)}
      />
      <div className="code-container__container">
        <pre className="code-container__code">
          <code>{children}</code>
        </pre>
      </div>
    </div>
  );
};

CodeContainer.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CodeContainer;
