import React from 'react';
import PropTypes from 'prop-types';
import { useIntermediateValue } from 'xooks';

import Input from '../Input/Input';

const NumberInput = ({
  value,
  onChange,
  min,
  max,
  additionalClass,
}) => {
  const {
    intermediateValue, valid, handleChange, handleSubmit,
  } = useIntermediateValue({
    value,
    onChange,
    rule: (val) => !Number.isNaN(val) && val <= max && val >= min,
    format: (val) => Number(val),
  });

  return (
    <Input
      invalid={!valid}
      type="text"
      value={intermediateValue}
      additionalClass={additionalClass}
      onChange={(event) => handleChange(event.target.value)}
      onBlur={(event) => handleSubmit(event.target.value)}
    />
  );
};

NumberInput.defaultProps = {
  min: 0,
  max: 100,
  additionalClass: '',
};

NumberInput.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  additionalClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default NumberInput;
