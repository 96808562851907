export default [
  {
    symbol: '€',
    entity: '&euro;',
    name: 'Euro sign',
    css: '\\20AC',
  },
  {
    symbol: '£',
    entity: '&pound;',
    name: 'Pound sign',
    css: '\\00A3',
  },
  {
    symbol: '₽',
    entity: '–',
    name: 'Ruble sign',
    css: '\\20BD',
  },
  {
    symbol: '←',
    entity: '&larr;',
    name: 'Left Arrow',
    css: '\\2190',
  },
  {
    symbol: '↑',
    entity: '&uarr;',
    name: 'Up Arrow',
    css: '\\2191',
  },
  {
    symbol: '→',
    entity: '&rarr;',
    name: 'Right Arrow',
    css: '\\2192',
  },
  {
    symbol: '↓',
    entity: '&darr;',
    name: 'Down Arrow',
    css: '\\2193',
  },
  {
    symbol: '¼',
    entity: '&frac14;',
    name: 'Fraction One Quarter',
    css: '\\00BC',
  },
  {
    symbol: '½',
    entity: '&frac12;',
    name: 'Fraction One Half',
    css: '\\00BD',
  },
  {
    symbol: '©',
    entity: '&copy;',
    name: 'Copyright Sign',
    css: '\\00A9',
  },
  {
    symbol: '®',
    entity: '&reg;',
    name: 'Registered Trade Mark Sign',
    css: '\\00AE',
  },
  {
    symbol: '™',
    entity: '&trade;',
    name: 'Trade Mark Sign',
    css: '\\2122',
  },
  {
    symbol: '«',
    entity: '&laquo;',
    name: 'Double Left-Pointing Angle Quotation Mark',
    css: '\\00AB',
  },
  {
    symbol: '»',
    entity: '&raquo;',
    name: 'Double Right-Pointing Angle Quotation Mark',
    css: '\\00BB',
  },
  {
    symbol: '—',
    entity: '&mdash;',
    name: 'Em Dash',
    css: '\\2014',
  },
  {
    symbol: '•',
    entity: '&bull;',
    name: 'Bullet',
    css: '\\2022',
  },
  {
    symbol: '✓',
    entity: '&check;',
    name: 'Check Mark',
    css: '\\2713',
  },
  {
    symbol: '✗',
    entity: '&cross;',
    name: 'Ballot X',
    css: '\\2717',
  },
];
