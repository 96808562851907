import React from 'react';
import PropTypes from 'prop-types';

import EuProjectLogo from 'components/global/EuProjectLogo';
import Header from 'components/global/Header';

const PageLayout = ({ children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <footer>
      <EuProjectLogo />
    </footer>
  </>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
