import TriangleIcon from 'assets/images/tools/triangle.png';
import LoremIcon from 'assets/images/tools/lorem.png';
import SymbolIcon from 'assets/images/tools/symbol.png';
import CursorIcon from 'assets/images/tools/cursor.png';
import PalleteIcon from 'assets/images/tools/pallete.png';
import GradientIcon from 'assets/images/tools/gradient.png';
import FakeIcon from 'assets/images/tools/fake.png';
import KeyboardIcon from 'assets/images/tools/keyboard.png';
import DividerIcon from 'assets/images/tools/divider.png';
import CompressionIcon from 'assets/images/tools/compression.png';
import ConvertIcon from 'assets/images/tools/convert.png';
import DecodeIcon from 'assets/images/tools/decode.png';

export default [
  {
    path: '/tools/triangle-generator',
    icon: TriangleIcon,
    title: 'navigation.triangleGeneratorPageTitle',
  },
  {
    path: '/tools/lorem-isum-generator',
    icon: LoremIcon,
    title: 'navigation.loremIpsumGeneratorPageTitle',
  },
  {
    path: '/tools/gradient-generator',
    icon: GradientIcon,
    title: 'navigation.gradientGeneratorPageTitle',
  },
  {
    path: '/tools/fake-data-generator',
    icon: FakeIcon,
    title: 'navigation.fakeDataGeneratorPageTitle',
  },
  {
    path: '/tools/symbols-collection',
    icon: SymbolIcon,
    title: 'navigation.symbolsCollectionPageTitle',
  },
  {
    path: '/tools/base-64-encoder',
    icon: DecodeIcon,
    title: 'navigation.base64EncodingPageTitle',
  },
  {
    path: '/tools/events-keycodes',
    icon: KeyboardIcon,
    title: 'eventsKeyCodes.pageTitle',
  },
  {
    path: '/tools/color-shades-generator',
    icon: PalleteIcon,
    title: 'navigation.colorShadesGeneratorPageTitle',
  },
  {
    path: '/tools/page-dividers',
    icon: DividerIcon,
    title: 'navigation.pageDividersGeneratorPageTitle',
  },
  {
    path: '/tools/css-cursors-generator',
    icon: CursorIcon,
    title: 'navigation.cssCursorGeneratorPageTitle',
  },
  {
    path: '/tools/svg-to-jsx',
    icon: ConvertIcon,
    title: 'navigation.svgToJsxPageTitle',
  },
  {
    path: '/tools/svg-compressor',
    icon: CompressionIcon,
    title: 'navigation.svgCompressorPageTitle',
  },
];
