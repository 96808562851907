import React, { useState, useEffect, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDocumentTitle } from 'xooks';

import { NotificationContext } from 'context/NotificationContext';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/tools/Button';
import ConfirmModal from 'components/global/ConfirmModal';
import systemService from 'services/systemService';
import AnnouncementCard from 'components/global/AnnouncementCard/AnnouncementCard';

import './AnnouncementList.scss';

const AnnouncementList = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { userToken } = useContext(AuthContext);
  const history = useHistory();
  const { userRole } = useContext(UserContext);
  const { t } = useTranslation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useDocumentTitle(t('announcementListPage.pageTitle'));

  const onErrorHandler = () => {
    setNotification(t('notification.errorMessage'));
    setNotificationType('error');
  };

  const onClickEditHandler = (id) => {
    history.push({
      pathname: '/edit-announcement',
      state: {
        id,
      },
    });
  };

  const onClickConfirmDeleteHandler = async () => {
    try {
      await systemService.deleteAnnouncement(deleteId, userToken);

      setIsLoaded(false);
      setIsModalOpen(false);

      setNotification(t('notification.deleteAnnouncementSuccessMessage'));
      setNotificationType('success');
      resetNotification();
    } catch (error) {
      onErrorHandler();
    }

    setAnnouncements([]);
    setPage(1);
    setHasMore(true);
    setIsLoaded(false);
  };

  useEffect(() => {
    const getAllAnnouncements = async () => {
      try {
        const { data } = await systemService.getAllAnnouncements(userToken, page);

        if (data) {
          if (data.additionalDetails) {
            setAnnouncements([...announcements, ...data.additionalDetails]);
          }
        }
      } catch (error) {
        const { message, response } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else if (response.data.additionalDetails === 'There are no announcements.') {
          setAnnouncements([...announcements]);
          setHasMore(false);
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded) {
      const isAdminRole = userRole.find((role) => role.title === 'admin');

      getAllAnnouncements();

      setIsAdmin(!!isAdminRole);
      setIsLoaded(true);
    }
  }, [announcements, isLoaded, page, setNotification, setNotificationType, t, userRole, userToken]);

  return (
    <SystemLayout>
      <section className="announcement-list">
        {isAdmin && announcements.length > 0 && (
          <Button
            type="button"
            onClick={() => setIsEditMode(!isEditMode)}
          >
              {isEditMode
                ? t('announcementListPage.editModeButtonEnabledLabel')
                : t('announcementListPage.editModeButtonDisabledLabel')}
          </Button>
        )}
        {announcements.length > 0 ? (
          <ul
            id="scrollable"
            className="announcement-list__list"
          >
            <InfiniteScroll
              className="announcement-list__scroller"
              dataLength={announcements.length}
              next={() => {
                setPage(page + 1);
                setIsLoaded(false);
              }}
              hasMore={hasMore}
              loader={<p className="announcement-list__message">{t('announcementListPage.loadingLabel')}</p>}
              endMessage={(
                <p className="announcement-list__message">
                  {t('announcementListPage.loadedLabel')}
                </p>
              )}
              scrollableTarget="scrollable"
            >
              {announcements.map((item) => {
                const itemData = {
                  title: item.title,
                  announcement: item.announcement,
                  description: item.description,
                  alertUsers: item.alertUsers === 1,
                  featuredImage: item.featuredImage,
                  author: item.author.name,
                  authorPosition: item.author.jobPosition,
                  authorImage: item.author.profilePicture,
                  id: item.id,
                  createdAt: item.createdAt,
                };

                return (
                  <li className="announcement-list__item" key={uuid()}>
                    <AnnouncementCard
                      data={itemData}
                      isAdmin={isEditMode}
                      onClickHandler={() => {
                        if (!isEditMode) {
                          history.push({
                            pathname: '/announcement-article',
                            state: {
                              id: itemData.id,
                            },
                          });
                        }
                      }}
                      onClickEditHandler={onClickEditHandler}
                      onClickDeleteHandler={() => {
                        setIsModalOpen(true);

                        setDeleteId(itemData.id);
                      }}
                    />
                  </li>
                )
              })}
            </InfiniteScroll>
          </ul>
        ) : (
          <p className="leaves__message">{t('announcementListPage.noItems')}</p>
        )}
        {isModalOpen && (
          <ConfirmModal
            message={t('announcementListPage.modalMessage')}
            confirmButtonTitle={`${t('announcementListPage.confirmButtonTitle')}`}
            cancelButtonTitle={`${t('announcementListPage.cancelButtonTitle')}`}
            onClickConfirmHandler={onClickConfirmDeleteHandler}
            onClickCancelHandler={() => setIsModalOpen(false)}
          />
        )}
      </section>
    </SystemLayout>
  );
};

export default AnnouncementList;
