import React, {
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import userService from 'services/userService';
import SystemLayout from 'layouts/SystemLayout';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';
import ConfirmModal from 'components/global/ConfirmModal';

import MemberCard from './MemberCard';

import './MembersList.scss';

const MembersList = () => {
  const { t } = useTranslation();
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [roles, setRoles] = useState([]);
  const history = useHistory();
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const isMounted = useRef(null);

  useDocumentTitle(t('membersPage.pageTitle'));

  const onErrorHandler = () => {
    setNotification(t('notification.errorMessage'));
    setNotificationType('error');
  };

  const onClickConfirmDeleteHandler = async () => {
    try {
      const { data } = await userService.deleteUser(deleteUserId, userToken);

      setUsers(data.additionalDetails);
      setIsLoaded(false);
      setIsModalOpen(false);

      setNotification(t('notification.deleteMemberSuccessMessage'));
      setNotificationType('success');
      resetNotification();
    } catch (error) {
      onErrorHandler();
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const { data } = await userService.getAllUsers(userToken);

        setUsers(data.additionalDetails);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded) {
      getUsers();

      const roleArray = [];

      userRole.forEach((role) => roleArray.push(role.title));

      setRoles(roleArray);

      setIsLoaded(true);
    }
  }, [isLoaded, roles, setNotification, setNotificationType, t, userRole, userToken]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);

  return (
    <SystemLayout>
      <section className={`members-list-page ${isModalOpen ? 'members-list-page--modal' : ''}`}>
        {isLoaded && users ? (
          <ul className="members-list-page__list">
            {users.length > 0 ? (
              <>
                {users.map((user) => (
                  <li className="members-list-page__item" key={uuid()}>
                    <MemberCard
                      user={user}
                      isAdmin={roles.includes('admin')}
                      onClickEditHandler={() => history.push({
                        pathname: '/edit-member',
                        state: {
                          id: user.userId,
                        },
                      })}
                      onClickDeleteHandler={(currentUser) => {
                        setIsModalOpen(true);

                        setDeleteUserId(currentUser.userId);
                      }}
                    />
                  </li>
                ))}
              </>
            ) : (
              <p>{t('membersPage.noUsersLabel')}</p>
            )}
          </ul>
        ) : (
          <p>{t('membersPage.loadingLabel')}</p>
        )}
      </section>
      {isModalOpen && (
      <ConfirmModal
        message={t('membersPage.modalMessage')}
        confirmButtonTitle={`${t('membersPage.confirmButtonTitle')}`}
        cancelButtonTitle={`${t('membersPage.cancelButtonTitle')}`}
        onClickConfirmHandler={onClickConfirmDeleteHandler}
        onClickCancelHandler={() => setIsModalOpen(false)}
      />
      )}
    </SystemLayout>
  );
};

export default MembersList;
