import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/tools/Button';

const CopyCodeButton = ({ copied, additionalClass, onClickHandler }) => {
  const { t } = useTranslation();

  return (
    <Button type="button" additionalClass={additionalClass} onClick={onClickHandler}>
      {copied ? t('copiedCodeButtonTitle') : t('copyCodeButtonTitle')}
    </Button>
  );
};

CopyCodeButton.defaultProps = {
  additionalClass: '',
};

CopyCodeButton.propTypes = {
  additionalClass: PropTypes.string,
  copied: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired,
};

export default CopyCodeButton;
