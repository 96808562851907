import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'xooks';

import { NotificationContext } from 'context/NotificationContext';
import { AuthContext } from 'context/AuthContext';
import systemService from 'services/systemService';
import SystemLayout from 'layouts/SystemLayout';
import AnnouncementForm from '../AnnouncementForm/AnnouncementForm';

// TODO: optimize if possible Edit and Add Annoucement Pages

const EditAnnoucement = () => {
  const history = useHistory();
  const location = useLocation();
  const { userToken } = useContext(AuthContext);
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [announcement, setAnnouncement] = useState(null);
  const [announcementId, setAnnouncementId] = useState(10);
  const [isDataUploaded, setIsDataUploaded] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [areFilesUploaded, setIsFilesUploaded] = useState(false);
  const [areFilesDeleted, setIsFilesDeleted] = useState(false);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);

  useDocumentTitle(t('editAnnouncementPage.pageTitle'));

  const onPressSaveAnnouncement = async (data, image, files) => {
    const filesToUpload = files.filter((item) => !item.attachmentPath);
    const oldIds = [];
    const newIds = [];

    if (announcement.attachments.length > 0) {
      announcement.attachments.forEach((item) => oldIds.push(item.attachmentDetails.id));
    }

    if (files.length > 0) {
      files.forEach((item) => {
        if (item.attachmentDetails) {
          newIds.push(item.attachmentDetails.id);
        }
      });
    }

    const idsToDelete = oldIds.filter((item) => !newIds.includes(item));

    setDeleteFiles(idsToDelete);

    setIsImageUploaded(typeof image === 'string');
    setIsFilesUploaded(filesToUpload.length === 0);
    setIsFilesDeleted(idsToDelete.length === 0);

    const isDataChanged = announcement.announcement !== data.announcement
      || announcement.title !== data.title
      || announcement.description !== data.description
      || announcement.alertUsers !== data.alertUsers

    const isImageChanged = image !== announcement.featuredImage;
    const areUploadFilesChanged = files !== announcement.attachments;

    if (isImageChanged) {
      setUploadImage(image);
    }

    if (areUploadFilesChanged) {
      setUploadFiles(filesToUpload);
    }

    if (isDataChanged) {
      setIsLoading(true);

      try {
        const updateData = {
          ...data,
          announcementId,
        };

        const response = await systemService.updateAnnouncement(userToken, updateData);

        setIsDataUploaded(true);
        setAnnouncement(response.data.additionalDescription);
      } catch (error) {
        setNotification(t('notification.errorMessage'));
        setNotificationType('error');

        setIsLoading(false);
      }
    } else {
      setIsDataUploaded(true);
    }
  };

  useEffect(() => {
    const getCurrentAnnoucement = async () => {
      try {
        const response = await systemService.getAnnouncement(userToken, location.state.id);

        setAnnouncement(response.data.additionalDetails);
      } catch (error) {
        const { message } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!location.state) {
      history.push('/announcements-list');
    } else if (location.state.id && !isLoaded) {
      getCurrentAnnoucement();
      setIsLoaded(true);
      setAnnouncementId(location.state.id);
    }
  }, [history, isLoaded, userToken, location, setNotification, t, setNotificationType]);

  useEffect(() => {
    const uploadImageRequest = async () => {
      if (announcementId !== 0) {
        try {
          const formData = new FormData();

          formData.append('file', uploadImage);
          formData.append('announcementId', announcementId);

          const response = await systemService.uploadAnnouncementImage(userToken, formData);

          if (response.status === 200) {
            setIsImageUploaded(true);
          }
        } catch (error) {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (isDataUploaded && !isImageUploaded) {
      uploadImageRequest();
    }
  }, [announcementId, isDataUploaded, isImageUploaded,
    setNotification, setNotificationType, t, uploadImage, userToken]);

  useEffect(() => {
    const uploadFilesRequest = () => {
      if (uploadFiles.length > 0 && announcementId !== 0) {
        uploadFiles.forEach(async (uploadFile) => {
          const fileFormData = new FormData();

          fileFormData.append('file', uploadFile);
          fileFormData.append('announcementId', announcementId);

          try {
            await systemService
              .uploadAnnouncementAttachment(userToken, fileFormData);

            setIsLoaded(false);
          } catch (error) {
            setNotification(t('notification.errorMessage'));
            setNotificationType('error');
          }
        })

        setIsFilesUploaded(true);
      } else {
        setIsFilesUploaded(true);
      }
    };

    if (isDataUploaded && isImageUploaded && !areFilesUploaded) {
      uploadFilesRequest();
    }
  }, [announcementId, isDataUploaded, isImageUploaded,
    uploadImage, userToken, deleteFiles, areFilesUploaded,
    uploadFiles, setNotification, t, setNotificationType]);

  useEffect(() => {
    const deleteFilesRequest = () => {
      if (deleteFiles.length > 0 && announcementId !== 0) {
        deleteFiles.forEach(async (id) => {
          try {
            await systemService
              .deleteAnnouncementAttachment(userToken, {
                announcementId,
                attachmentId: id,
              });

            setIsLoaded(false);
          } catch (error) {
            setNotification(t('notification.errorMessage'));
            setNotificationType('error');
          }

          setIsFilesDeleted(true);
        })
      } else {
        setIsFilesDeleted(false);
      }
    };

    if (isDataUploaded && isImageUploaded && areFilesUploaded && !areFilesDeleted) {
      deleteFilesRequest();
    }
  });

  useEffect(() => {
    if (isDataUploaded && isImageUploaded && areFilesUploaded && areFilesDeleted) {
      setNotification(t('notification.editAnnouncementSuccessMessage'));
      setNotificationType('success');
      resetNotification();

      setIsLoading(false);

      history.push('/announcements-list');
    }
  }, [history, isDataUploaded, isImageUploaded,
    areFilesUploaded, areFilesDeleted, setNotification,
    t, setNotificationType, resetNotification]);

  return (
    <SystemLayout>
      {announcement ? (
        <AnnouncementForm
          onClickHandler={onPressSaveAnnouncement}
          buttonTitle={t('editAnnouncementPage.editButtonTitle')}
          announcementData={announcement}
          isEdit
          submitButtonLoading={isLoading}
        />
      ) : <p>{t('editAnnouncementPage.loading')}</p>}
    </SystemLayout>
  );
};

export default EditAnnoucement;
