import React from 'react';
import PropTypes from 'prop-types';

import directions from '../../../pages/Tools/TriangleGenerator/directions';

import './DirectionPicker.scss'

const DirectionPicker = ({ value, onChange }) => {
  const angles = {
    top: 180,
    bottom: 0,
    right: 270,
    left: 90,
    'bottom-right': 315,
    'bottom-left': 45,
    'top-right': 225,
    'top-left': 135,
  };

  const buttons = directions.map((direction) => (
    <button
      type="button"
      onClick={() => onChange(direction)}
      className={`direction-picker__button ${value}`}
      key={direction}
      aria-label={value}
    >
      <div
        style={{
          width: 26,
          height: 26,
          transform: `rotate(${angles[direction]}deg)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <svg viewBox="0 0 66 36" xmlns="http://www.w3.org/2000/svg" style={{ flex: 1 }}>
          <path
            d="M3 3l30 30L63 3"
            fillRule="evenodd"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="6"
          />
        </svg>
      </div>
    </button>
  ));

  buttons.splice(4, 0, <div className="direction-picker__blank" key="blank" />);

  return <div className="direction-picker">{buttons}</div>;
}

DirectionPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(directions).isRequired,
};

export default DirectionPicker;
