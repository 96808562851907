import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/global/Avatar';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import ToolButton from 'components/tools/Button';
import { UserContext } from 'context/UserContext';

import './LoginForm.scss';

const LoginForm = ({
  title,
  buttonTitle,
  resetButtonTitle,
  rememberOptionTitle,
  notificationMessage,
  emailLabel,
  passwordLabel,
  onClickHandler,
  onClickResetHandler,
  isError,
}) => {
  const {
    setIsRememberMode,
    userImage,
    userEmail,
  } = useContext(UserContext);
  const [email, setEmail] = useState(userEmail);
  const [password, setPassword] = useState('');
  const [isRememeberMeChecked, setIsRememberMeChecked] = useState(!!userImage);
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);
  const [passwordInvalidInput, setPasswordInvalidInput] = useState(false);

  const onLoginHandler = () => {
    setEmailInvalidInput(email === '');
    setPasswordInvalidInput(password === '');

    if (email && password) {
      onClickHandler(email, password);
    }
  };

  useEffect(() => {
    setEmailInvalidInput(isError);
    setPasswordInvalidInput(isError);
  }, [isError]);

  useEffect(() => {
    setIsRememberMode(isRememeberMeChecked);
  }, [isRememeberMeChecked, setIsRememberMode]);

  return (
    <section className="user-form">
      <div className="user-form__wrapper">
        <h2 className="user-form__title">{title}</h2>
        <form className="user-form__container">
          <div className="user-form__avatar-wrapper">
            <Avatar
              additionalClass="user-form__avatar"
              circle
              imageUrl={userImage}
            />
          </div>
          <div className="user-form__input-container">
            <label
              htmlFor="email"
              className={`user-form__label ${emailInvalidInput ? 'user-form__label--invalid' : ''}`}
            >
              {emailLabel}
            </label>
            <FormInput
              isInvalidInput={emailInvalidInput}
              additionalClass="user-form__input"
              type="email"
              name="email"
              id="email"
              placeholder={emailLabel}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label
              htmlFor="password"
              className={`user-form__label ${passwordInvalidInput ? 'user-form__label--invalid' : ''}`}
            >
              {passwordLabel}
            </label>
            <FormInput
              isInvalidInput={passwordInvalidInput}
              additionalClass="user-form__input"
              type="password"
              name="password"
              value={password}
              id="password"
              placeholder={passwordLabel}
              onChange={(event) => setPassword(event.target.value)}
            />
            <div className="user-form__remember-container">
              <input
                className="user-form__checkbox"
                type="checkbox"
                name="remember-me"
                id="remember-me"
                checked={isRememeberMeChecked}
                onChange={() => setIsRememberMeChecked(!isRememeberMeChecked)}
              />
              <label
                className={`user-form__checkbox-label ${isRememeberMeChecked && 'user-form__checkbox-label--checked'}`}
                htmlFor="remember-me"
              >
                {rememberOptionTitle}
              </label>
            </div>
            <ToolButton
              additionalClass="user-form__reset-button"
              onClick={onClickResetHandler}
            >
              {resetButtonTitle}
            </ToolButton>
            <FormNotification additionalClass="user-form__notificaiton" message={notificationMessage} />
          </div>
          <Button
            title={buttonTitle}
            onClickHandler={onLoginHandler}
          />
        </form>
      </div>
    </section>
  );
};

LoginForm.defaultProps = {
  isError: false,
  rememberOptionTitle: '',
  emailLabel: '',
  passwordLabel: '',
};

LoginForm.propTypes = {
  isError: PropTypes.bool,
  rememberOptionTitle: PropTypes.string,
  emailLabel: PropTypes.string,
  passwordLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  resetButtonTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  notificationMessage: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  onClickResetHandler: PropTypes.func.isRequired,
};

export default LoginForm;
