import React from 'react';
import PropTypes from 'prop-types';

import Slider from './Slider';
import NumberInput from '../NumberInput/NumberInput';

import './SliderInput.scss';

const SliderInput = ({
  additionalClass,
  value,
  onChange,
  trackSize,
  min,
  max,
}) => (
  <div className={`slider-input ${additionalClass}`}>
    <NumberInput
      additionalClass="slider-input__input"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
    />
    <Slider min={min} max={max} value={value} onChange={onChange} trackSize={trackSize} />
  </div>
);

SliderInput.defaultProps = {
  min: 0,
  max: 100,
  trackSize: 200,
  additionalClass: '',
};

SliderInput.propTypes = {
  additionalClass: PropTypes.string,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  trackSize: PropTypes.number,
};

export default SliderInput;
