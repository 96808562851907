import oc from 'open-color';
import { v4 } from 'uuid';

const defaultPalette = Object.keys(oc)
  .filter((key) => key !== 'white' && key !== 'black')
  .map((key) => ({
    key: v4(),
    color: oc[key][4],
  }));

export default defaultPalette;
