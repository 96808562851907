import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import AuthContextProvider from 'context/AuthContext';
import UserContextProvider from 'context/UserContext';
import NotificationContextProvider from 'context/NotificationContext';

import i18n from './i18n';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AuthContextProvider>
        <UserContextProvider>
          <NotificationContextProvider>
            <App />
          </NotificationContextProvider>
        </UserContextProvider>
      </AuthContextProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('app'),
);
