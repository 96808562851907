export default [
  {
    symbol: '←',
    entity: '&larr;',
    name: 'Left Arrow',
    css: '\\2190',
  },
  {
    symbol: '↑',
    entity: '&uarr;',
    name: 'Up Arrow',
    css: '\\2191',
  },
  {
    symbol: '→',
    entity: '&rarr;',
    name: 'Right Arrow',
    css: '\\2192',
  },
  {
    symbol: '↓',
    entity: '&darr;',
    name: 'Down Arrow',
    css: '\\2193',
  },
  {
    symbol: '↔',
    entity: '&harr;',
    name: 'Left Right Arrow',
    css: '\\2194',
  },
  {
    symbol: '↕',
    entity: '&varr;',
    name: 'Up Down Arrow',
    css: '\\2195',
  },
  {
    symbol: '↖',
    entity: '&nwarr;',
    name: 'North West Arrow',
    css: '\\2196',
  },
  {
    symbol: '↗',
    entity: '&nearr;',
    name: 'North East Arrow',
    css: '\\2197',
  },
  {
    symbol: '↘',
    entity: '&searr;',
    name: 'South East Arrow',
    css: '\\2198',
  },
  {
    symbol: '↙',
    entity: '&swarr;',
    name: 'South West Arrow',
    css: '\\2199',
  },
  {
    symbol: '↚',
    entity: '&nlarr;',
    name: 'Left Arrow with Stroke',
    css: '\\219A',
  },
  {
    symbol: '↛',
    entity: '&nrarr;',
    name: 'Right Arrow with Stroke',
    css: '\\219B',
  },
  {
    symbol: '↜',
    entity: '&larrw;',
    name: 'Left Wave Arrow',
    css: '\\219C',
  },
  {
    symbol: '↝',
    entity: '&rarrw;',
    name: 'Right Wave Arrow',
    css: '\\219D',
  },
  {
    symbol: '↞',
    entity: '&Larr;',
    name: 'Left Two Headed Arrow',
    css: '\\219E',
  },
  {
    symbol: '↟',
    entity: '&Uarr;',
    name: 'Up Two Headed Arrow',
    css: '\\219F',
  },
  {
    symbol: '↠',
    entity: '&Rarr;',
    name: 'Right Two Headed Arrow',
    css: '\\21A0',
  },
  {
    symbol: '↡',
    entity: '&Darr;',
    name: 'Down Two Headed Arrow',
    css: '\\21A1',
  },
  {
    symbol: '↢',
    entity: '&larrtl;',
    name: 'Left Arrow with Tail',
    css: '\\21A2',
  },
  {
    symbol: '↣',
    entity: '&rarrtl;',
    name: 'Right Arrow with Tail',
    css: '\\21A3',
  },
  {
    symbol: '↤',
    entity: '&mapstoleft;',
    name: 'Left Arrow from Bar',
    css: '\\21A4',
  },
  {
    symbol: '↥',
    entity: '&mapstoup;',
    name: 'Up Arrow from Bar',
    css: '\\21A5',
  },
  {
    symbol: '↦',
    entity: '&mapstoright;',
    name: 'Right Arrow from Bar',
    css: '\\21A6',
  },
  {
    symbol: '↧',
    entity: '&mapstodown;',
    name: 'Down Arrow from Bar',
    css: '\\21A7',
  },
  {
    symbol: '↩',
    entity: '&larrhk;',
    name: 'Left Arrow with Hook',
    css: '\\21A9',
  },
  {
    symbol: '↪',
    entity: '&rarrhk;',
    name: 'Right Arrow with Hook',
    css: '\\21AA',
  },
  {
    symbol: '↫',
    entity: '&larrlp;',
    name: 'Left Arrow with Loop',
    css: '\\21AB',
  },
  {
    symbol: '↬',
    entity: '&rarrlp;',
    name: 'Right Arrow with Loop',
    css: '\\21AC',
  },
  {
    symbol: '↭',
    entity: '&harrw;',
    name: 'Left Right Wave Arrow',
    css: '\\21AD',
  },
  {
    symbol: '↮',
    entity: '&nharr;',
    name: 'Left Right Arrow with Stroke',
    css: '\\21AE',
  },
  {
    symbol: '↰',
    entity: '&lsh;',
    name: 'Up Arrow with Tip Left',
    css: '\\21B0',
  },
  {
    symbol: '↱',
    entity: '&rsh;',
    name: 'Up Arrow with Tip Right',
    css: '\\21B1',
  },
  {
    symbol: '↲',
    entity: '&ldsh;',
    name: 'Down Arrow with Tip Left',
    css: '\\21B2',
  },
  {
    symbol: '↳',
    entity: '&rdsh;',
    name: 'Down Arrow with Tip Right',
    css: '\\21B3',
  },
  {
    symbol: '↵',
    entity: '&crarr;',
    name: 'Down Arrow with Corner Left',
    css: '\\21B5',
  },
  {
    symbol: '↶',
    entity: '&cularr;',
    name: 'Anticlockwise Top Semicircle Arrow',
    css: '\\21B6',
  },
  {
    symbol: '↷',
    entity: '&curarr;',
    name: 'Clockwise Top Semicircle Arrow',
    css: '\\21B7',
  },
  {
    symbol: '↺',
    entity: '&olarr;',
    name: 'Anticlockwise Open Circle Arrow',
    css: '\\21BA',
  },
  {
    symbol: '↻',
    entity: '&orarr;',
    name: 'Clockwise Open Circle Arrow',
    css: '\\21BB',
  },
  {
    symbol: '↼',
    entity: '&lharu;',
    name: 'Left Harpoon with Barb Up',
    css: '\\21BC',
  },
  {
    symbol: '↽',
    entity: '&lhard;',
    name: 'Left Harpoon with Barb Down',
    css: '\\21BD',
  },
  {
    symbol: '↾',
    entity: '&uharr;',
    name: 'Up Harpoon with Barb Right',
    css: '\\21BE',
  },
  {
    symbol: '↿',
    entity: '&uharl;',
    name: 'Up Harpoon with Barb Left',
    css: '\\21BF',
  },
  {
    symbol: '⇀',
    entity: '&rharu;',
    name: 'Right Harpoon with Barb Up',
    css: '\\21C0',
  },
  {
    symbol: '⇁',
    entity: '&rhard;',
    name: 'Right Harpoon with Barb Down',
    css: '\\21C1',
  },
  {
    symbol: '⇂',
    entity: '&dharr;',
    name: 'Down Harpoon with Barb Right',
    css: '\\21C2',
  },
  {
    symbol: '⇃',
    entity: '&dharl;',
    name: 'Down Harpoon with Barb Left',
    css: '\\21C3',
  },
  {
    symbol: '⇄',
    entity: '&rlarr;',
    name: 'Right Arrow over Left Arrow',
    css: '\\21C4',
  },
  {
    symbol: '⇅',
    entity: '&udarr;',
    name: 'Up Arrow Left of Down Arrow',
    css: '\\21C5',
  },
  {
    symbol: '⇆',
    entity: '&lrarr;',
    name: 'Left Arrow over Right Arrow',
    css: '\\21C6',
  },
  {
    symbol: '⇇',
    entity: '&llarr;',
    name: 'Left Paired Arrows',
    css: '\\21C7',
  },
  {
    symbol: '⇈',
    entity: '&uuarr;',
    name: 'Up Paired Arrows',
    css: '\\21C8',
  },
  {
    symbol: '⇉',
    entity: '&rrarr;',
    name: 'Right Paired Arrows',
    css: '\\21C9',
  },
  {
    symbol: '⇊',
    entity: '&ddarr;',
    name: 'Downards Paired Arrows',
    css: '\\21CA',
  },
  {
    symbol: '⇋',
    entity: '&lrhar;',
    name: 'Left Harpoon over Right Harpoon',
    css: '\\21CB',
  },
  {
    symbol: '⇌',
    entity: '&rlhar;',
    name: 'Right Harpoon over Left Harpoon',
    css: '\\21CC',
  },
  {
    symbol: '⇍',
    entity: '&nlArr;',
    name: 'Left Double Arrow with Stroke',
    css: '\\21CD',
  },
  {
    symbol: '⇎',
    entity: '&nhArr;',
    name: 'Left Right Double Arrow with Stroke',
    css: '\\21CE',
  },
  {
    symbol: '⇏',
    entity: '&nrArr;',
    name: 'Right Double Arrow with Stroke',
    css: '\\21CF',
  },
  {
    symbol: '⇐',
    entity: '&lArr;',
    name: 'Left Double Arrow',
    css: '\\21D0',
  },
  {
    symbol: '⇑',
    entity: '&uArr;',
    name: 'Up Double Arrow',
    css: '\\21D1',
  },
  {
    symbol: '⇒',
    entity: '&rArr;',
    name: 'Right Double Arrow',
    css: '\\21D2',
  },
  {
    symbol: '⇓',
    entity: '&dArr;',
    name: 'Down Double Arrow',
    css: '\\21D3',
  },
  {
    symbol: '⇔',
    entity: '&hArr;',
    name: 'Left Right Double Arrow',
    css: '\\21D4',
  },
  {
    symbol: '⇕',
    entity: '&vArr;',
    name: 'Up Down Double Arrow',
    css: '\\21D5',
  },
  {
    symbol: '⇖',
    entity: '&nwArr;',
    name: 'North West Double Arrow',
    css: '\\21D6',
  },
  {
    symbol: '⇗',
    entity: '&neArr;',
    name: 'North East Double Arrow',
    css: '\\21D7',
  },
  {
    symbol: '⇘',
    entity: '&seArr;',
    name: 'South East Double Arrow',
    css: '\\21D8',
  },
  {
    symbol: '⇙',
    entity: '&swArr;',
    name: 'South West Double Arrow',
    css: '\\21D9',
  },
  {
    symbol: '⇚',
    entity: '&lAarr;',
    name: 'Left Triple Arrow',
    css: '\\21DA',
  },
  {
    symbol: '⇛',
    entity: '&rAarr;',
    name: 'Right Triple Arrow',
    css: '\\21DB',
  },
  {
    symbol: '⇜',
    entity: '&ziglarr;',
    name: 'Left Squiggle Arrow',
    css: '\\21DC',
  },
  {
    symbol: '⇝',
    entity: '&zigrarr;',
    name: 'Right Squiggle Arrow',
    css: '\\21DD',
  },
  {
    symbol: '⇤',
    entity: '&larrb;',
    name: 'Left Arrow to Bar',
    css: '\\21E4',
  },
  {
    symbol: '⇥',
    entity: '&rarrb;',
    name: 'Right Arrow to Bar',
    css: '\\21E5',
  },
  {
    symbol: '⇵',
    entity: '&duarr;',
    name: 'Down Arrow Left of Up Arrow',
    css: '\\21F5',
  },
  {
    symbol: '⇽',
    entity: '&loarr;',
    name: 'Left Open-headed Arrow',
    css: '\\21FD',
  },
  {
    symbol: '⇾',
    entity: '&roarr;',
    name: 'Right Open-headed Arrow',
    css: '\\21FE',
  },
  {
    symbol: '⇿',
    entity: '&hoarr;',
    name: 'Left Right Open-headed Arrow',
    css: '\\21FF',
  },
  {
    symbol: '⟵',
    entity: '&xlarr;',
    name: 'Long Left Arrow',
    css: '\\27F5',
  },
  {
    symbol: '⟶',
    entity: '&xrarr;',
    name: 'Long Right Arrow',
    css: '\\27F6',
  },
  {
    symbol: '⟷',
    entity: '&xharr;',
    name: 'Long Left Right Arrow',
    css: '\\27F7',
  },
  {
    symbol: '⟸',
    entity: '&xlArr;',
    name: 'Long Left Double Arrow',
    css: '\\27F8',
  },
  {
    symbol: '⟹',
    entity: '&xrArr;',
    name: 'Long Right Double Arrow',
    css: '\\27F9',
  },
  {
    symbol: '⟺',
    entity: '&xhArr;',
    name: 'Long Left Right Double Arrow',
    css: '\\27FA',
  },
  {
    symbol: '⟼',
    entity: '&xmap;',
    name: 'Long Right Arrow From Bar',
    css: '\\27FC',
  },
  {
    symbol: '⟿',
    entity: '&dzigrarr;',
    name: 'Long Right Squiggle Arrow',
    css: '\\27FF',
  },
  {
    symbol: '⤂',
    entity: '&nvlArr;',
    name: 'Left Double Arrow With Vertical Stroke',
    css: '\\2902',
  },
  {
    symbol: '⤃',
    entity: '&nvrArr;',
    name: 'Right Double Arrow With Vertical Stroke',
    css: '\\2903',
  },
  {
    symbol: '⤄',
    entity: '&nvHarr;',
    name: 'Left Right Double Arrow With Vertical Stroke',
    css: '\\2904',
  },
  {
    symbol: '⤅',
    entity: '&Map;',
    name: 'Right Two-Headed Arrow From Bar',
    css: '\\2905',
  },
  {
    symbol: '⤌',
    entity: '&lbarr;',
    name: 'Left Double Dash Arrow',
    css: '\\290C',
  },
  {
    symbol: '⤍',
    entity: '&rbarr;',
    name: 'Right Double Dash Arrow',
    css: '\\290D',
  },
  {
    symbol: '⤎',
    entity: '&lBarr;',
    name: 'Left Triple Dash Arrow',
    css: '\\290E',
  },
  {
    symbol: '⤏',
    entity: '&rBarr;',
    name: 'Right Triple Dash Arrow',
    css: '\\290F',
  },
  {
    symbol: '⤐',
    entity: '&RBarr;',
    name: 'Right Two-Headed Triple Dash Arrow',
    css: '\\2910',
  },
  {
    symbol: '⤑',
    entity: '&DDotrahd;',
    name: 'Right Arrow With Dotted Stem',
    css: '\\2911',
  },
  {
    symbol: '⤒',
    entity: '&UpArrowBar;',
    name: 'Up Arrow to Bar',
    css: '\\2912',
  },
  {
    symbol: '⤓',
    entity: '&DownArrowBar;',
    name: 'Down Arrow to Bar',
    css: '\\2913',
  },
  {
    symbol: '⤖',
    entity: '&Rarrtl;',
    name: 'Right Two-Headed Arrow With Tail',
    css: '\\2916',
  },
  {
    symbol: '⤙',
    entity: '&latail;',
    name: 'Left Arrow-Tail',
    css: '\\2919',
  },
  {
    symbol: '⤚',
    entity: '&ratail;',
    name: 'Right Arrow-Tail',
    css: '\\291A',
  },
  {
    symbol: '⤛',
    entity: '&lAtail;',
    name: 'Left Double Arrow-Tail',
    css: '\\291B',
  },
  {
    symbol: '⤜',
    entity: '&rAtail;',
    name: 'Right Double Arrow-Tail',
    css: '\\291C',
  },
  {
    symbol: '⤝',
    entity: '&larrfs;',
    name: 'Left Arrow to Black Diamond',
    css: '\\291D',
  },
  {
    symbol: '⤞',
    entity: '&rarrfs;',
    name: 'Right Arrow to Black Diamond',
    css: '\\291E',
  },
  {
    symbol: '⤟',
    entity: '&larrbfs;',
    name: 'Left Arrow From Bar to Black Diamond',
    css: '\\291F',
  },
  {
    symbol: '⤠',
    entity: '&rarrbfs;',
    name: 'Right Arrow From Bar to Black Diamond',
    css: '\\2920',
  },
  {
    symbol: '⤣',
    entity: '&nwarhk;',
    name: 'North West Arrow With Hook',
    css: '\\2923',
  },
  {
    symbol: '⤤',
    entity: '&nearhk;',
    name: 'North East Arrow With Hook',
    css: '\\2924',
  },
  {
    symbol: '⤥',
    entity: '&searhk;',
    name: 'South East Arrow With Hook',
    css: '\\2925',
  },
  {
    symbol: '⤦',
    entity: '&swarhk;',
    name: 'South West Arrow With Hook',
    css: '\\2926',
  },
  {
    symbol: '⤧',
    entity: '&nwnear;',
    name: 'North West Arrow and North East Arrow',
    css: '\\2927',
  },
  {
    symbol: '⤨',
    entity: '&nesear;',
    name: 'North East Arrow and South East Arrow',
    css: '\\2928',
  },
  {
    symbol: '⤩',
    entity: '&seswar;',
    name: 'South East Arrow and South West Arrow',
    css: '\\2929',
  },
  {
    symbol: '⤪',
    entity: '&swnwar;',
    name: 'South West Arrow and North West Arrow',
    css: '\\292A',
  },
  {
    symbol: '⤳',
    entity: '&rarrc;',
    name: 'Wave Arrow Pointing Directly Right',
    css: '\\2933',
  },
  {
    symbol: '⤵',
    entity: '&cudarrr;',
    name: 'Arrow Pointing Right Then Curving Down',
    css: '\\2935',
  },
  {
    symbol: '⤶',
    entity: '&ldca;',
    name: 'Arrow Pointing Down Then Curving Left',
    css: '\\2936',
  },
  {
    symbol: '⤷',
    entity: '&rdca;',
    name: 'Arrow Pointing Down Then Curving Right',
    css: '\\2937',
  },
  {
    symbol: '⤸',
    entity: '&cudarrl;',
    name: 'Right-Side Arc Clockwise Arrow',
    css: '\\2938',
  },
  {
    symbol: '⤹',
    entity: '&larrpl;',
    name: 'Left-Side Arc Anticlockwise Arrow',
    css: '\\2939',
  },
  {
    symbol: '⤼',
    entity: '&curarrm;',
    name: 'Top Arc Clockwise Arrow With Minus',
    css: '\\293C',
  },
  {
    symbol: '⤽',
    entity: '&cularrp;',
    name: 'Top Arc Anticlockwise Arrow With Plus',
    css: '\\293D',
  },
  {
    symbol: '⥅',
    entity: '&rarrpl;',
    name: 'Right Arrow With Plus Below',
    css: '\\2945',
  },
  {
    symbol: '⥈',
    entity: '&harrcir;',
    name: 'Left Right Arrow Through Small Circle',
    css: '\\2948',
  },
  {
    symbol: '⥉',
    entity: '&Uarrocir;',
    name: 'Up Two-Headed Arrow From Small Circle',
    css: '\\2949',
  },
  {
    symbol: '⥊',
    entity: '&lurdshar;',
    name: 'Left Barb Up Right Barb Down Harpoon',
    css: '\\294A',
  },
  {
    symbol: '⥋',
    entity: '&ldrushar;',
    name: 'Left Barb Down Right Barb Up Harpoon',
    css: '\\294B',
  },
  {
    symbol: '⥎',
    entity: '&LeftRightVector;',
    name: 'Left Barb Up Right Barb Up Harpoon',
    css: '\\294E',
  },
  {
    symbol: '⥏',
    entity: '&RightUpDownVector;',
    name: 'Up Barb Right Down Barb Right Harpoon',
    css: '\\294F',
  },
  {
    symbol: '⥐',
    entity: '&DownLeftRightVector;',
    name: 'Left Barb Down Right Barb Down Harpoon',
    css: '\\2950',
  },
  {
    symbol: '⥑',
    entity: '&LeftUpDownVector;',
    name: 'Up Barb Left Down Barb Left Harpoon',
    css: '\\2951',
  },
  {
    symbol: '⥒',
    entity: '&LeftVectorBar;',
    name: 'Left Harpoon With Barb Up to Bar',
    css: '\\2952',
  },
  {
    symbol: '⥓',
    entity: '&RightVectorBar;',
    name: 'Right Harpoon With Barb Up to Bar',
    css: '\\2953',
  },
  {
    symbol: '⥔',
    entity: '&RightUpVectorBar;',
    name: 'Up Harpoon With Barb Right to Bar',
    css: '\\2954',
  },
  {
    symbol: '⥕',
    entity: '&RightDownVectorBar;',
    name: 'Down Harpoon With Barb Right to Bar',
    css: '\\2955',
  },
  {
    symbol: '⥖',
    entity: '&DownLeftVectorBar;',
    name: 'Left Harpoon With Barb Down to Bar',
    css: '\\2956',
  },
  {
    symbol: '⥗',
    entity: '&DownRightVectorBar;',
    name: 'Right Harpoon With Barb Down to Bar',
    css: '\\2957',
  },
  {
    symbol: '⥘',
    entity: '&LeftUpVectorBar;',
    name: 'Up Harpoon With Barb Left to Bar',
    css: '\\2958',
  },
  {
    symbol: '⥙',
    entity: '&LeftDownVectorBar;',
    name: 'Down Harpoon With Barb Left to Bar',
    css: '\\2959',
  },
  {
    symbol: '⥚',
    entity: '&LeftTeeVector;',
    name: 'Left Harpoon With Barb Up From Bar',
    css: '\\295A',
  },
  {
    symbol: '⥛',
    entity: '&RightTeeVector;',
    name: 'Right Harpoon With Barb Up From Bar',
    css: '\\295B',
  },
  {
    symbol: '⥜',
    entity: '&RightUpTeeVector;',
    name: 'Up Harpoon With Barb Right From Bar',
    css: '\\295C',
  },
  {
    symbol: '⥝',
    entity: '&RightDownTeeVector;',
    name: 'Down Harpoon With Barb Right From Bar',
    css: '\\295D',
  },
  {
    symbol: '⥞',
    entity: '&DownLeftTeeVector;',
    name: 'Left Harpoon With Barb Down From Bar',
    css: '\\295E',
  },
  {
    symbol: '⥟',
    entity: '&DownRightTeeVector;',
    name: 'Right Harpoon With Barb Down From Bar',
    css: '\\295F',
  },
  {
    symbol: '⥠',
    entity: '&LeftUpTeeVector;',
    name: 'Up Harpoon With Barb Left From Bar',
    css: '\\2960',
  },
  {
    symbol: '⥡',
    entity: '&LeftDownTeeVector;',
    name: 'Down Harpoon With Barb Left From Bar',
    css: '\\2961',
  },
  {
    symbol: '⥢',
    entity: '&lHar;',
    name: 'Left Harpoon With Barb Up Above Left Harpoon With Barb Down',
    css: '\\2962',
  },
  {
    symbol: '⥣',
    entity: '&uHar;',
    name: 'Up Harpoon With Barb Left Beside Up Harpoon With Barb Right',
    css: '\\2963',
  },
  {
    symbol: '⥤',
    entity: '&rHar;',
    name: 'Right Harpoon With Barb Up Above Right Harpoon With Barb Down',
    css: '\\2964',
  },
  {
    symbol: '⥥',
    entity: '&dHar;',
    name: 'Down Harpoon With Barb Left Beside Down Harpoon With Barb Right',
    css: '\\2965',
  },
  {
    symbol: '⥦',
    entity: '&luruhar;',
    name: 'Left Harpoon With Barb Up Above Right Harpoon With Barb Up',
    css: '\\2966',
  },
  {
    symbol: '⥧',
    entity: '&ldrdhar;',
    name: 'Left Harpoon With Barb Down Above Right Harpoon With Barb Down',
    css: '\\2967',
  },
  {
    symbol: '⥨',
    entity: '&ruluhar;',
    name: 'Right Harpoon With Barb Up Above Left Harpoon With Barb Up',
    css: '\\2968',
  },
  {
    symbol: '⥩',
    entity: '&rdldhar;',
    name: 'Right Harpoon With Barb Down Above Left Harpoon With Barb Down',
    css: '\\2969',
  },
  {
    symbol: '⥪',
    entity: '&lharul;',
    name: 'Left Harpoon With Barb Up Above Long Dash',
    css: '\\296A',
  },
  {
    symbol: '⥫',
    entity: '&llhard;',
    name: 'Left Harpoon With Barb Down Below Long Dash',
    css: '\\296B',
  },
  {
    symbol: '⥬',
    entity: '&rharul;',
    name: 'Right Harpoon With Barb Up Above Long Dash',
    css: '\\296C',
  },
  {
    symbol: '⥭',
    entity: '&lrhard;',
    name: 'Right Harpoon With Barb Down Below Long Dash',
    css: '\\296D',
  },
  {
    symbol: '⥮',
    entity: '&udhar;',
    name: 'Up Harpoon With Barb Left Beside Down Harpoon With Barb Right',
    css: '\\296E',
  },
  {
    symbol: '⥯',
    entity: '&duhar;',
    name: 'Down Harpoon With Barb Left Beside Up Harpoon With Barb Right',
    css: '\\296F',
  },
  {
    symbol: '⥰',
    entity: '&RoundImplies;',
    name: 'Right Double Arrow With Rounded Head',
    css: '\\2970',
  },
  {
    symbol: '⥱',
    entity: '&erarr;',
    name: 'Equals Sign Above Right Arrow',
    css: '\\2971',
  },
  {
    symbol: '⥲',
    entity: '&simrarr;',
    name: 'Tilde Operator Above Right Arrow',
    css: '\\2972',
  },
  {
    symbol: '⥳',
    entity: '&larrsim;',
    name: 'Left Arrow Above Tilde Operator',
    css: '\\2973',
  },
  {
    symbol: '⥴',
    entity: '&rarrsim;',
    name: 'Right Arrow Above Tilde Operator',
    css: '\\2974',
  },
  {
    symbol: '⥵',
    entity: '&rarrap;',
    name: 'Right Arrow Above Almost Equal To',
    css: '\\2975',
  },
  {
    symbol: '⥶',
    entity: '&ltlarr;',
    name: 'Less-Than Above Left Arrow',
    css: '\\2976',
  },
  {
    symbol: '⥸',
    entity: '&gtrarr;',
    name: 'Greater-Than Above Right Arrow',
    css: '\\2978',
  },
  {
    symbol: '⥹',
    entity: '&subrarr;',
    name: 'Subset Above Right Arrow',
    css: '\\2979',
  },
  {
    symbol: '⥻',
    entity: '&suplarr;',
    name: 'Superset Above Left Arrow',
    css: '\\297B',
  },
  {
    symbol: '⥼',
    entity: '&lfisht;',
    name: 'Left Fish Tail',
    css: '\\297C',
  },
  {
    symbol: '⥽',
    entity: '&rfisht;',
    name: 'Right Fish Tail',
    css: '\\297D',
  },
  {
    symbol: '⥾',
    entity: '&ufisht;',
    name: 'Up Fish Tail',
    css: '\\297E',
  },
  {
    symbol: '⥿',
    entity: '&dfisht;',
    name: 'Down Fish Tail',
    css: '\\297F',
  },
];
