import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import bg from './locales/bg';

const DEBUG_ENABLED = true;

const resources = {
  en: {
    translation: en,
  },
  bg: {
    translation: bg,
  },
};

i18n
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development' && DEBUG_ENABLED,
  });

export default i18n;
