import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NotificationContext } from 'context/NotificationContext';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import FormNotification from 'components/global/FormNotification';
import FormInput from 'components/global/FormInput';
import userService from 'services/userService';
import { AuthContext } from 'context/AuthContext';

import './AddMember.scss';

const PERSONAL_NUMBER_REGEX = /^\d{10}$/gm;
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddMember = () => {
  const [firstName, setFirstName] = useState('');
  const [firstNameInvalidInput, setFirstNameInvalidInput] = useState(false);
  const [middleName, setMiddleName] = useState('');
  const [middleNameInvalidInput, setMiddleNameInvalidInput] = useState(false);
  const [lastName, setLastName] = useState('');
  const [lastNameInvalidInput, setLastNameInvalidInput] = useState(false);
  const [position, setPosition] = useState('');
  const [positionInvalidInput, setPositionInvalidInput] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalidInput, setEmailInvalidInput] = useState(false);
  const [personalNumber, setPersonalNumber] = useState('');
  const [personalNumberInvalidInput, setPersonalNumberInvalidInput] = useState(false);
  const [annualLeaveDays, setAnnualLeaveDays] = useState('0');
  const [annualLeaveDaysInvalidInput,
    setAnnualLeaveDaysInvalidInput] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [startDateInvalidInput,
    setStartDateInvalidInput] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { userToken } = useContext(AuthContext);
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useDocumentTitle(t('addMemberPage.pageTitle'));

  const onClickButtonHandler = async () => {
    if (!firstName
      || !middleName
      || !lastName
      || !position
      || !email
      || !personalNumber
      || !annualLeaveDays
      || !startDate) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else if (!EMAIL_REGEX.test(email)) {
      setFormNotification(t('notification.invalidEmailErrorMessage'));
    } else if (!PERSONAL_NUMBER_REGEX.test(personalNumber)) {
      setFormNotification(t('notification.invalidPersonalNumberErrorMessage'));
    } else {
      setFormNotification('');
    }

    setFirstNameInvalidInput(firstName === '');
    setMiddleNameInvalidInput(middleName === '');
    setLastNameInvalidInput(lastName === '');
    setPositionInvalidInput(position === '');
    setEmailInvalidInput(email === '' || !EMAIL_REGEX.test(email));
    setPersonalNumberInvalidInput(!PERSONAL_NUMBER_REGEX.test(personalNumber));
    setAnnualLeaveDaysInvalidInput(annualLeaveDays === '');
    setStartDateInvalidInput(startDate === '');

    if (firstName !== ''
      && middleName !== ''
      && lastName !== ''
      && position !== ''
      && email !== '' && EMAIL_REGEX.test(email)
      && startDate !== ''
      && annualLeaveDays !== ''
      && PERSONAL_NUMBER_REGEX.test(personalNumber)) {
      setIsLoading(true);

      try {
        const userData = {
          firstName,
          middleName,
          lastName,
          email,
          jobPosition: position,
          personalNumber,
          employeeStartDate: startDate,
          availableAnnualLeaveDays: annualLeaveDays,
        };

        const { data } = await userService.addMember(userData, userToken);

        if (data.additionalDetails) {
          setNotification(t('notification.addMemberSuccessMessage'));
          setNotificationType('success');
          resetNotification();

          setIsLoading(false);

          history.push('/members-list');
        }
      } catch (error) {
        const { response } = error;

        if (response.data.responseDescription === 'Validation Error') {
          if (response.data.additionalDetails.email) {
            if (response.data.additionalDetails.email[0] === 'The email has already been taken.') {
              setNotification(t('notification.addMemberEmailMessage'));
              setNotificationType('error');

              setEmailInvalidInput(true);
            }
          } else if (response.data.additionalDetails.personalNumber) {
            if (response.data.additionalDetails.personalNumber[0] === 'The personal number has already been taken.') {
              setNotification(t('notification.addMemberPersonalNumberMessage'));
              setNotificationType('error');

              setPersonalNumberInvalidInput(true);
            }
          }
        }

        setIsLoading(false);
      }
    }
  };

  return (
    <SystemLayout>
      <section className="add-member-form">
        <form className="add-member-form__container">
          <div className="add-member-form__input-container">
            <FormInput
              system
              isInvalidInput={firstNameInvalidInput}
              additionalClass="add-member-form__input"
              type="text"
              name="first-name"
              id="first-name"
              placeholder={t('addMemberPage.firstNameLabel')}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={middleNameInvalidInput}
              additionalClass="add-member-form__input"
              type="text"
              name="middle-name"
              id="middle-name"
              placeholder={t('addMemberPage.middleNameLabel')}
              value={middleName}
              onChange={(event) => setMiddleName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={lastNameInvalidInput}
              additionalClass="add-member-form__input"
              type="text"
              name="last-name"
              id="last-name"
              placeholder={t('addMemberPage.lastNameLabel')}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={positionInvalidInput}
              additionalClass="add-member-form__input"
              type="text"
              name="position"
              value={position}
              id="position"
              placeholder={t('addMemberPage.positionLabel')}
              onChange={(event) => setPosition(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={emailInvalidInput}
              additionalClass="add-member-form__input"
              type="email"
              name="email"
              id="email"
              placeholder={t('addMemberPage.emailLabel')}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <FormInput
              system
              isInvalidInput={personalNumberInvalidInput}
              additionalClass="add-member-form__input"
              type="text"
              name="personal-number"
              id="personal-number"
              placeholder={t('addMemberPage.personalNumberLabel')}
              value={personalNumber}
              onChange={(event) => setPersonalNumber(event.target.value)}
            />
            <label htmlFor="leave-days" className="add-member-form__input-label">
              {t('addMemberPage.annualLeaveLabel')}
            </label>
            <FormInput
              system
              isInvalidInput={annualLeaveDaysInvalidInput}
              additionalClass="add-member-form__input"
              type="number"
              name="leave-days"
              id="leave-days"
              placeholder={t('addMemberPage.annualLeaveLabel')}
              value={annualLeaveDays}
              onChange={(event) => setAnnualLeaveDays(event.target.value)}
            />
            <label htmlFor="leave-days" className="add-member-form__input-label">
              {t('addMemberPage.startDateLabel')}
            </label>
            <FormInput
              system
              isInvalidInput={startDateInvalidInput}
              additionalClass="add-member-form__input"
              type="date"
              id="start-date"
              name="start-date"
              value={startDate}
              placeholder={t('addMemberPage.startDateLabel')}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <FormNotification additionalClass="annual-leave-request-form__notification" message={formNotification} />
          <Button
            title={t('addMemberPage.buttonTitle')}
            onClickHandler={onClickButtonHandler}
            loading={isLoading}
          />
        </form>
      </section>
    </SystemLayout>
  );
};

export default AddMember;
