import React from 'react';
import PropTypes from 'prop-types';

import './SettingsLabel.scss';

const SettingsLabel = ({ additionalClass, children }) => <h2 className={`settings-label ${additionalClass}`}>{children}</h2>;

SettingsLabel.defaultProps = {
  additionalClass: '',
};

SettingsLabel.propTypes = {
  additionalClass: PropTypes.string,
  children: PropTypes.string.isRequired,
};

export default SettingsLabel;
