import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './Slider.scss';

const getClientPosition = (e) => {
  const { touches } = e;

  if (touches && touches.length) {
    const finger = touches[0];

    return {
      x: finger.clientX,
      y: finger.clientY,
    };
  }

  return {
    x: e.clientX,
    y: e.clientY,
  };
};

const Slider = ({
  value = 50,
  min = 0,
  max = 100,
  step = 1,
  trackSize = 200,
  onChange,
}) => {
  const container = useRef(null);
  const handle = useRef(null);
  const start = useRef({});
  const offset = useRef({});

  const getPosition = () => {
    let left = ((value - min) / (max - min)) * 100;

    if (left > 100) left = 100;
    if (left < 0) left = 0;

    return left;
  };

  const change = (val) => {
    let left = val;
    const { width } = container.current.getBoundingClientRect();
    let dx = 0;

    if (left < 0) left = 0;
    if (left > width) left = width;

    dx = (left / width) * (max - min);

    onChange((dx !== 0 ? parseInt(dx / step, 10) * step : 0) + min);
  };

  const getPos = (e) => getClientPosition(e).x + start.current.x - offset.current.x;

  const handleDrag = (e) => {
    e.preventDefault();

    change(getPos(e));
  };

  const handleDragEnd = (e) => {
    e.preventDefault();

    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchmove', handleDrag, {
      passive: false,
    });
    document.removeEventListener('touchend', handleDragEnd);
    document.removeEventListener('touchcancel', handleDragEnd);
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const dom = handle.current;
    const clientPos = getClientPosition(e);

    start.current = {
      x: dom.offsetLeft,
      y: dom.offsetTop,
    };

    offset.current = {
      x: clientPos.x,
      y: clientPos.y,
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('touchend', handleDragEnd);
    document.addEventListener('touchcancel', handleDragEnd);
  };

  const handleTrackMouseDown = (e) => {
    e.preventDefault();

    const clientPos = getClientPosition(e);
    const rect = container.current.getBoundingClientRect();

    start.current = {
      x: clientPos.x - rect.left,
      y: clientPos.y - rect.top,
    };

    offset.current = {
      x: clientPos.x,
      y: clientPos.y,
    };

    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag, { passive: false });
    document.addEventListener('touchend', handleDragEnd);
    document.addEventListener('touchcancel', handleDragEnd);

    change(clientPos.x - rect.left);
  };

  const position = getPosition();

  return (
    <div
      ref={container}
      className="track"
      onTouchStart={handleTrackMouseDown}
      onMouseDown={handleTrackMouseDown}
      style={{ width: trackSize }}
      role="presentation"
    >
      <div className="track__active" style={{ width: `${position}%` }} />
      <div
        ref={handle}
        style={{
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          left: `${position}%`,
          top: '50%',
        }}
        onTouchStart={handleMouseDown}
        onMouseDown={handleMouseDown}
        onClick={(e) => {
          e.stopPropagation();

          e.nativeEvent.stopImmediatePropagation();
        }}
        role="presentation"
      >
        <div className="track__thumb" />
      </div>
    </div>
  );
};

Slider.defaultProps = {
  value: 50,
  min: 0,
  max: 100,
  step: 1,
  trackSize: 200,
};

Slider.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  trackSize: PropTypes.number,
};

export default Slider;
