import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Background from 'components/tools/Background';
import CodeContainer from 'components/tools/CodeContainer';
import { generateRawData, generateJsonData } from '../generator';

import './Output.scss';

const Output = ({
  type,
  fields,
  amount,
  seed,
}) => {
  const [rawData, setRawData] = useState(generateRawData());
  const [jsonData, setJsonData] = useState(generateJsonData(fields, amount));

  useEffect(() => {
    setRawData(generateRawData());
    setJsonData(generateJsonData(fields, amount));
  }, [amount, fields, seed]);

  if (type === 'default') {
    const items = rawData.map((item) => (
      <div key={item.key} className="fake-data-output__item">
        <div className="fake-data-output__key">{item.key}</div>
        <div className="fake-data-output__value">{item.data}</div>
      </div>
    ));

    return <Background additionalClass="fake-data-output">{items}</Background>;
  }

  return (
    <Background additionalClass="fake-data-output">
      <CodeContainer>{JSON.stringify(jsonData, null, 2)}</CodeContainer>
    </Background>
  );
};

Output.defaultProps = {
  seed: '',
};

Output.propTypes = {
  type: PropTypes.oneOf(['default', 'json']).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  amount: PropTypes.number.isRequired,
  seed: PropTypes.string,
};

export default Output;
