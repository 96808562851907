export default {
  navigation: {
    mobileMenuTitle: 'Меню',
    mobileMenuCloseTitle: 'Затвори',
    dashboardPageTitle: 'Начало',
    announcementsPageTitle: 'Фирмени известия',
    faqPageTitle: 'Често задавани въпроси',
    toolsPageTitle: 'Компоненти',
    gradientGeneratorPageTitle: 'Генератор за градиенти',
    signOutTitle: 'Излез',
    triangleGeneratorPageTitle: 'Генератор за триъгълници',
    fakeDataGeneratorPageTitle: 'Генератор примерни данни',
    loremIpsumGeneratorPageTitle: 'Lorem Ipsum генератор',
    symbolsCollectionPageTitle: 'Колекция от HTML символи',
    base64EncodingPageTitle: 'Генератор Base64',
    colorShadesGeneratorPageTitle: 'Генератор на цветови нюанси',
    pageDividersGeneratorPageTitle: 'Генератор на вълнообразни разделители',
    cssCursorGeneratorPageTitle: 'Колекция за типове курсори',
    svgToJsxPageTitle: 'Конвертор на файлове SVG в JSX',
    svgCompressorPageTitle: 'Оптимизатор на файлове с формат SVG',
    addMemberPageTitle: 'Нов потребител',
    membersPageTitle: 'Потребители',
    leavesPageTitle: 'Отпуск и работа от вкъщи',
    profilePageTitle: 'Профил',
    addButtonLabel: 'Добави',
  },
  loadingButton: 'зареждане...',
  copiedCodeButtonTitle: 'Копирано',
  copyCodeButtonTitle: 'Копирай',
  triangleGenerator: {
    pageTitle: 'Генератор за триъгълници',
    settings: {
      directionTitle: 'Посока',
      sizesSubTitle: 'Предварително зададени размери',
      sizeTitle: 'Размер',
      widthTitle: 'Ширина, px',
      heightTitle: 'Височина, px',
      colorTitle: 'Цвят',
      darkTheme: 'Тъмна',
      lightTheme: 'Светла',
      themeToggleButtonTitle: 'тема',
    },
    elementTitle: 'Елемент',
    pseudoElementTitle: 'Псевдоелемент',
  },
  symbolsCollection: {
    pageTitle: 'Колекция от символи',
    nameTitle: 'Име',
    symbolTitle: 'Символ',
    hmtlEntityTitle: 'HTML код',
    cssEntityTitle: 'CSS код',
    searchPlaceholder: 'Търсене на символи...',
    mostUsedTitle: 'Най-използвани',
    arrowsTitle: 'Стрелки',
    mathTitle: 'Математика',
    punctuationTitle: 'Пунктуация',
    specialTitle: 'Специални',
    copiedLabel: 'копирано',
    copyLabel: 'копиране',
  },
  loremIpsumGenerator: {
    pageTitle: 'Лорем Ипсум генератор',
    inputLabel: 'Брой параграфи',
    copiedCodeButtonTitle: 'Копирано',
    copyCodeButtonTitle: 'Генериране и копиране',
  },
  gradientGenerator: {
    pageTitle: 'Генератор за градиенти',
    linearTitle: 'линеен',
    radialTitle: 'радиален',
    directionTitle: 'Посока',
    directionInputTitle: 'Ъгъл, градуси',
    gradientTypeTitle: 'Тип Градиент',
    galleryTitle: 'Галерия',
    copyGradientTitle: 'Копирай CSS',
    copiedGradientTitle: 'Копирано',
    openInEditorTitle: 'Отвори в редактора',
    settingsTitle: 'Настройки',
    colorsTitle: 'Цветове',
    gradientColorTitle: 'Цвят',
    gradientOpacityTitle: 'Прозрачност',
  },
  fakeDataGenerator: {
    pageTitle: 'Генератор за фалшиви данни',
    nameTitle: 'Име',
    removeButtonTitle: 'Премахване',
    regenerateButtonTitle: 'Регенерирайте',
    settingsTitle: 'JSON Схема',
    addFieldButtonTitle: '+ Добави поле',
    amountTitle: 'Брой документи',
    typeTitle: 'Тип',
    tabDataTitle: 'Данни',
    tabSettingsTitle: 'JSON Схема',
  },
  dropZone: {
    dropZoneConnectWord: 'или',
    dropZoneButtonLabel: 'Качете файла',
    dropZoneTitle: 'Пуснете файл в прозореца на браузъра',
  },
  base64Encoding: {
    pageTitle: 'Base64 кодиране',
    dropZoneTitle: 'Пуснете файл в прозореца на браузъра, за да го конвертирате във формат base64',
    resultLabel: 'Необработен base64',
    backgroundLabel: 'Използване като фон за CSS',
  },
  eventsKeyCodes: {
    pageTitle: 'JavaScript кодове за събития при натискане на клавиши',
    eventInfoTitle: 'Информация',
    modifierKeysTitle: 'Допълнителни клавиши',
    usageTitle: 'Ползване',
    headerTitle: 'Натиснете,който и да е клавиш, за да получите информация за JavaScript събитието',
    tipLabel: 'Съвет:',
    tipContent: 'клавишите shift, control, alt и meta (cmd) също се улавят и можете да генерирате код на тези клавишни комбинации',
  },
  colorShadesGenerator: {
    pageTitle: 'Генератор на нюанси',
    copyLabel: 'копирай',
    copiedLabel: 'копирано',
    copiedAllLabel: 'Копирано',
    copyAllLabel: 'Копиране на всички',
    removeLabel: 'Премахване',
    darkLigthLabel: 'Затъмняване/изсветляване, %',
    saturationLabel: 'Наситеност, %',
    loadPalletLabel: 'Зареди предефинирана палитра',
    removeAllLabel: 'Премахни всички',
    addColorLabel: '+ Добави цвят',
  },
  pageDividersGenerator: {
    pageTitle: 'Разделители за страници',
    outputHtmlCodeLabel: 'HTML код',
    outputJsxCodeLabel: 'JSX код',
    downloadLabel: 'Изтегляне на нестилизиран svg',
    positionLabel: 'Позиция',
    colorLabel: 'Цвят',
    directionLabel: 'Посока',
    widthLabel: 'Дължина, %',
    heightLabel: 'Височина, %',
    shapeLabel: 'Тип форма',
    buttonLabels: {
      top: 'Нагоре',
      bottom: 'Надолу',
      normal: 'Нормален',
      reverse: 'Обърнат',
    },
  },
  cssCursorGenerator: {
    pageTitle: 'Колекция за типове курсори',
    generalTitle: 'Общо предназначение',
    statusTitle: 'Статус',
    selectionTitle: 'Селектиране',
    zoomTitle: 'Увеличаване',
    dragAndDropTitle: 'Влачене и пускане',
    resizeTitle: 'Промяна на размера и превъртане',
    copiedLabel: 'копирано',
    copyLabel: 'копиране',
    buttonDescription: 'Кликнете за копиране',
  },
  svgToJsx: {
    dropLabel: 'Пуснете SVG файл в прозореца на браузъра, за да го оптимизирате и да го конвертирате в JSX',
    failedMessage: 'Неуспешен опит за минимизиране на файла',
    settingsLabel: 'Поставяне на SVG',
    exampleButtonTitle: 'Заредете пример',
    textAreaPlaceholder: 'Поставяне на SVG тук',
    processingMessage: 'Обработване ',
  },
  svgCompressor: {
    pageTitle: 'SVG компресор',
    dropLabel: 'Пуснете SVG файл в прозореца на браузъра за да започнете компресирането',
    previewTitle: 'Преглед',
    downloadButtonLabel: 'сваляне',
    controlsLabel: 'или конвертиране в',
    settingsLabel: 'Компресиран код',
    errorMessage: 'Не успя да компресира файла SVG',
  },
  loginPage: {
    pageTitle: 'Вход',
    title: 'Вход',
    buttonTitle: 'Заквакай`',
    resetButtonTitle: 'Не помните паролата?',
    remmeberOptionTitle: 'Запомни ме',
    emailLabel: 'Имейл',
    passwordLabel: 'Парола',
  },
  createPasswordPage: {
    pageTitle: 'Създаване на парола',
    title: 'Създаване на парола',
    buttonTitle: 'Заквакай`',
    passwordLabel: 'Парола',
    repeatPasswordLabel: 'Парола',
  },
  restorePasswordPage: {
    title: 'Възстановяване на паролата',
    buttonTitle: 'Възстановяване на паролата',
    emailLabel: 'Имейл',
    successMessage: 'Линк за възстановяване на паролата ще бъде изпратен на вашия имейл.',
    redirectButtonTitle: 'Към влизане',
  },
  notFoundPage: {
    pageTitle: 'Страницата не е намерена',
    message: 'Изглеждате изгубени... нека ви върнем в началото',
    buttonTitle: 'Начало',
  },
  addMemberPage: {
    pageTitle: 'Нов потребител',
    firstNameLabel: 'Име',
    middleNameLabel: 'Презиме',
    lastNameLabel: 'Фамилия',
    positionLabel: 'Позиция',
    emailLabel: 'Имейл',
    buttonTitle: 'Създаване',
    personalNumberLabel: 'ЕГН',
    annualLeaveLabel: 'дни отпуск',
    startDateLabel: 'Дата на постъпване',
  },
  profilePage: {
    profilePageTitle: 'Профил',
    avatarMessage: 'Пуснете файл на аватара или ',
    avatarInputButtonTitle: 'качете файл',
    phoneInputLabel: 'Телефон, започващ с +359',
    signatureLabel: 'Генератор на имейл подпис',
    contactInfoLabel: 'Контактна информация',
    skypeInputLabel: 'Skype акаунт',
    slackInputLabel: 'Slack акаунт',
    saveChangesButtonTitle: 'Запазете промените',
    languageSwitcherLabel: 'Избор на език',
    resetPasswordButtonTitle: 'Промяна на парола',
  },
  resetPasswordPage: {
    pageTitle: 'Нова парола',
    title: 'Нова парола',
    buttonTitle: 'Заквакай`',
    passwordLabel: 'Парола',
    repeatPasswordLabel: 'Парола',
  },
  addAnnouncementPage: {
    pageTitle: 'Създаване на известие',
    titleLabel: 'Заглавие',
    tagsLabel: 'Тагове (FE, BE)',
    descriptionLabel: 'Кратко описание (за превю)',
    announcementLabel: 'Известие',
    checkmarkLabel: 'Предупреждаване на потребителите чрез имейл',
    addButtonTitle: 'Създаване',
    previewImageLabel: 'Преглед',
    uploadImagelabel: 'Качи изображение',
    resetImageButtonTitle: 'Изтриване',
    filesLabel: 'Прикачване на файлове',
    uploadFilesLabel: 'Плъзнете файловете тук',
    removeFileButtonTitle: 'изтриване',
  },
  editAnnouncementPage: {
    pageTitle: 'Редактиране на съобщение',
    editButtonTitle: 'Запази',
    loading: 'зареждане',
  },
  faqPage: {
    pageTitle: 'Често задавани въпроси',
    noItemsLabel: 'няма въпроси',
    loadingLabel: 'зареждане',
    addButtonTitle: 'Добави',
    editButtonTitle: 'Запази',
    questionLabel: 'Въпрос',
    answerLabel: 'Отговор',
    modalMessage: 'Сигурни ли сте, че искате да изтриете въпроса?',
    confirmButtonTitle: 'Изтриване',
    cancelButtonTitle: 'Отмени',
  },
  membersPage: {
    startDate: 'постъпил на: ',
    paidLeaveDays: 'дни отпуска: ',
    modalMessage: 'Сигурни ли сте, че искате да изтриете потребителя?',
    modalMessageDeleteButton: 'Изтриване',
    modalMessageCancelButton: 'Отмяна',
    noUsersLabel: 'няма потребители',
    loadingLabel: 'зареждане',
    confirmButtonTitle: 'Изтриване',
    cancelButtonTitle: 'Отмени',
  },
  editMemberPage: {
    pageTitle: 'Редактиране на потребител',
    firstNameLabel: 'Име',
    middleNameLabel: 'Презиме',
    lastNameLabel: 'Фамилия',
    emailLabel: 'Имейл: ',
    positionLabel: 'Позиция',
    roleLabel: 'Роля (трябва да има поне една роля)',
    saveButtonTitle: 'Запази',
    resendActivationButtonTitle: 'Изпрати мейл за активация',
    deactivateButtonTitle: 'Деактивирай',
    adminLabel: 'Админ',
    employeeLabel: 'Служител',
    approverLabel: 'Одобряващ',
    leaveDaysLabel: 'Дни отпуск',
    statusLabel: 'Статус: ',
    activatedLabel: 'активиран',
    notActivatedLabel: 'неактивиран',
    startDateLabel: 'Дата на постъпване',
    endDateLabel: 'Дата на напускане',
    phoneInputLabel: 'Телефон',
    skypeInputLabel: 'Skype акаунт',
    slackInputLabel: 'Slack акаунт',
  },
  dashboardPage: {
    pageTitle: 'Начало',
    latestLabel: 'най-ново',
    calendarLabel: 'календар',
    upcomingLeavesLabel: 'предстоящи искания',
    todosLabel: 'задачи',
    noLatestLabel: 'няма съобщения',
    noLeavesLabel: 'няма молби',
  },
  leavesPage: {
    pageTitle: 'Добавяне на нова молба',
    homeOfficeButtonLabel: 'Работа от вкъщи',
    annualLeaveButtonLabel: 'Отпуск',
    noItemsLabel: 'няма заявени молби',
    requestStartDateLabel: 'начална дата: ',
    requestDaysLabel: 'дни: ',
    requestReasonLabel: 'причина: ',
    requestResolutionLabel: 'решение: ',
    requestButtonLabel: 'Разрешаване',
    annualLeaveTypeLabel: 'Отпуск',
    homeOfficeTypeLabel: 'Работа от вкъщи',
    requestAnnualLeaveUnpaidTypeLabel: 'тип: неплатен',
    requestAnnualLeavePaidTypeLabel: 'тип: платен',
    signButtonLabel: 'Изпращане на документ',
    concatLabel: 'от',
    pendingLabel: 'чакащ',
    approvedLabel: 'одобрен',
    declinedLabel: 'отказан',
    loadingLabel: 'зареждане на още...',
    loadedLabel: 'няма повече молби',
  },
  homeOfficeRequestPage: {
    pageTitle: 'Заявка за работа от вкъщи',
    startDateLabel: 'Начална дата',
    daysLabel: 'Дни',
    reasonLabel: 'Причина (максимум 250 символа)',
    buttonTitle: 'Изпращане',
  },
  annualLeaveRequestPage: {
    pageTitle: 'Заявка за отпуск',
    paidLabel: 'платен',
    daysLabel: 'Дни',
    unpaidLabel: 'неплатен',
    reasonLabel: 'Причина (максимум 250 символа)',
    buttonTitle: 'Изпращане',
    startDateLabel: 'Начална дата',
  },
  resolveRequestPage: {
    pageTitle: 'Разрешаване на молба',
    statusLabel: 'Избери статус: ',
    approvedLabel: 'одобрен',
    declinedLabel: 'отказан',
    resolutionLabel: 'Причина (максимум 250 символа)',
    buttonTitle: 'Изпращане',
  },
  signAnnualLeavePage: {
    pageTitle: 'Качване на молба',
    namesNumberLabel: 'Въведете трите имена по документ за самоличност',
    positionLabel: 'Длъжност',
    resetButtonTitle: 'Изтриване',
    signButtonTitle: 'Поставяне',
    saveButtonTitle: 'Качване',
  },
  announcementListPage: {
    pageTitle: 'Съобщения',
    noItems: 'няма съобщения',
    editModeButtonEnabledLabel: 'Активиран режим на редактиране',
    editModeButtonDisabledLabel: 'Деактивиран режим на редактиране',
    modalMessage: 'Сигурни ли сте, че искате да изтриете съобщението?',
    confirmButtonTitle: 'Изтриване',
    cancelButtonTitle: 'Отмени',
    loadingLabel: 'зареждане на още...',
    loadedLabel: 'няма повече съобщения',
  },
  announcementArticlePage: {
    pageTitle: 'Съобщение',
    filesLabel: 'Прикачени файлове',
    noItems: 'няма прикачени файлове',
    loading: 'зареждане',
  },
  notification: {
    errorMessage: 'Нещо се е объркало. Моля, опитайте отново.',
    unauthorizedMessage: 'За да извършите това действие, трябва да сте удостоверени.',
    welcomeMessage: 'Здравей, ',
    addMemberEmailMessage: 'Имейлът вече е използван.',
    addMemberPersonalNumberMessage: 'ЕГН вече е взет.',
    addMemberSuccessMessage: 'Потребителският акаунт е създаден успешно.',
    profileUpdateSuccessMessage: 'Потребителският акаунт е актуализиран успешно.',
    editMemberSuccessMessage: 'Потребителският акаунт е актуализиран успешно.',
    editMemberErrorMessage: 'Потребителският акаунт не е актуализиран.',
    deleteMemberSuccessMessage: 'Потребителят е изтрит успешно.',
    resendActivationSuccessMessage: 'Имейлът за активиране на акаунта е изпратен успешно.',
    addFAQSuccessMessage: 'Често задаваният въпрос е успешно създаден.',
    editFAQSuccessMessage: 'Често задаваният въпрос е актуализиран успешно.',
    deleteFAQSuccessMessage: 'Често задаваният въпрос е изтрит успешно.',
    notEnoughLeaveDaysErrorMessage: 'Недостатъчен брой дни годишен отпуск.',
    addRequestSuccessMessage: 'Заявката е създадена успешно.',
    resolveRequestSuccessMessage: 'Заявката е обработена успешно.',
    signRequestSuccessMessage: 'Заявката е подписана успешно.',
    addAnnouncementSuccessMessage: 'Съобщението е създадено успешно.',
    editAnnouncementSuccessMessage: 'Съобщението е успешно актуализирано.',
    deleteAnnouncementSuccessMessage: 'Съобщението е успешно изтрито.',
    incorrectLoginDetailsMessage: 'Неправилни данни за вход. Моля, опитайте отново или възстановете паролата си.',
    passwordsDoNotMatchHint: 'Паролите не съвпадат.',
    activationErrorMessage: 'Токенът за активиране не е верен или акаунтът вече е активиран. Моля, свържете се с администратор.',
    createPasswordErrorMessage: 'Паролата трябва да бъде най-малко 12 символа и да съдържа малка буква, главна буква, специален символ и цифра.',
    fileTypeErrorMessage: 'Файлът трябва да е от типа: jpeg, png, jpg, docx, xls, xlsx, pdf.',
    characterLimitErrorMessage: 'Ограничението на символите за въвеждане е достигнато.',
    requiredFieldsErrorMessage: 'Моля, попълнете всички задължителни полета.',
    passwordResetSuccessMessage: 'Имейлът за възстановяване на паролата е изпратен успешно.',
    invalidPersonalNumberErrorMessage: 'ЕГН не е валиден.',
    invalidEmailErrorMessage: 'Имейлът не е невалиден.',
    invalidPhoneErrorMessage: 'Телефонният номер не е невалиден.',
    confirmButtonTitle: 'Вход',
    cancelButtonTitle: 'Отмени',
  },
  euroProject: {
    text: 'Този проект е изпълнен във връзка с целите и условията по Договор № BG16RFOP002-2.083-0445-C01,  процедура за предоставяне на БФП №BG16RFOP002-2.083 “Ваучерна схема за предоставяне на услуги за информационни и комуникационни технологии“, Ваучер N000394, Тип 2, Група 2: „Ваучери за ИКТ услуги за оптимизиране на управленски, производствени и логистични процеси',
    name: 'ИКТ система за оптимизиране на управленските процеси в Ръббър Дък ООД',
    licence: 'Лицензионен(сериен номер): А0007 / 29.06.2021',
    developer: 'Разработил Давид Холдинг АД',
  },

};
