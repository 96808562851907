export default {
  navigation: {
    mobileMenuTitle: 'Menu',
    mobileMenuCloseTitle: 'Close',
    dashboardPageTitle: 'Dashboard',
    announcementsPageTitle: 'Announcements',
    faqPageTitle: 'FAQ',
    toolsPageTitle: 'Components',
    gradientGeneratorPageTitle: 'Gradient Generator',
    signOutTitle: 'Sign Out',
    triangleGeneratorPageTitle: 'Triangle Generator',
    fakeDataGeneratorPageTitle: 'Fake Data Generator',
    loremIpsumGeneratorPageTitle: 'Lorem Ipsum Generator',
    symbolsCollectionPageTitle: 'Symbols collection',
    base64EncodingPageTitle: 'Base64 encoding',
    colorShadesGeneratorPageTitle: 'Color Shades Generator',
    pageDividersGeneratorPageTitle: 'Page dividers',
    cssCursorGeneratorPageTitle: 'CSS cursor properties',
    svgToJsxPageTitle: 'SVG to JSX',
    svgCompressorPageTitle: 'SVG compressor',
    addMemberPageTitle: 'New Member',
    membersPageTitle: 'Members',
    leavesPageTitle: 'Leaves',
    profilePageTitle: 'Profile',
    addButtonLabel: 'Add',
  },
  loadingButton: 'loading...',
  copiedCodeButtonTitle: 'Copied to clipboard',
  copyCodeButtonTitle: 'Copy to clipboard',
  triangleGenerator: {
    pageTitle: 'Triangle Generator',
    settings: {
      directionTitle: 'Direction',
      sizesSubTitle: 'Predefined sizes',
      sizeTitle: 'Size',
      widthTitle: 'Width, px',
      heightTitle: 'Height, px',
      colorTitle: 'Color',
      darkTheme: 'Dark',
      lightTheme: 'Light',
      themeToggleButtonTitle: 'preview',
    },
    elementTitle: 'Element',
    pseudoElementTitle: 'Pseudo-element',
  },
  symbolsCollection: {
    pageTitle: 'Symbols collection',
    nameTitle: 'Name',
    symbolTitle: 'Symbol',
    hmtlEntityTitle: 'HTML entity',
    cssEntityTitle: 'CSS entity',
    searchPlaceholder: 'Search symbols...',
    mostUsedTitle: 'Most Used',
    arrowsTitle: 'Arrows',
    mathTitle: 'Math and fractions',
    punctuationTitle: 'Punctuation',
    specialTitle: 'Special',
    copiedLabel: 'copied',
    copyLabel: 'copy',
  },
  loremIpsumGenerator: {
    pageTitle: 'Lorem ipsum',
    inputLabel: 'Amount of paragraphs',
    copiedCodeButtonTitle: 'Copied',
    copyCodeButtonTitle: 'Generate and copy',
  },
  gradientGenerator: {
    pageTitle: 'Gradient Generator',
    linearTitle: 'linear',
    radialTitle: 'radial',
    directionTitle: 'Gradient direction',
    directionInputTitle: 'Gradient angle, deg',
    gradientTypeTitle: 'Gradient type',
    galleryTitle: 'Gradients Gallery',
    copyGradientTitle: 'Copy CSS',
    copiedGradientTitle: 'Copied',
    openInEditorTitle: 'Open in editor',
    settingsTitle: 'Settings',
    colorsTitle: 'Colors',
    gradientColorTitle: 'Color',
    gradientOpacityTitle: 'Opacity, %',
  },
  fakeDataGenerator: {
    pageTitle: 'Fake Data Generator',
    nameTitle: 'Name',
    removeButtonTitle: 'Remove',
    regenerateButtonTitle: 'Regenerate',
    settingsTitle: 'JSON Schema',
    addFieldButtonTitle: '+ Add Field',
    amountTitle: 'Amount of documents',
    typeTitle: 'Type',
    tabDataTitle: 'Raw data',
    tabSettingsTitle: 'JSON Schema',
  },
  dropZone: {
    dropZoneConnectWord: 'or',
    dropZoneButtonLabel: 'Upload file',
    dropZoneTitle: 'Drop files to browser window',
  },
  base64Encoding: {
    pageTitle: 'Base64 encoding',
    dropZoneTitle: 'Drop a file to the browser window to convert it to base64 format',
    resultLabel: 'Raw base64',
    backgroundLabel: 'Use as CSS background',
  },
  eventsKeyCodes: {
    pageTitle: 'JavaScript events keycodes',
    eventInfoTitle: 'Event info',
    modifierKeysTitle: 'Modifier keys',
    usageTitle: 'Usage',
    headerTitle: 'Press any key to get JavaScript event keycode info',
    tipLabel: 'Tip:',
    tipContent: 'shift, control, alt and meta (cmd) keys are also captured, you can generate usage with these keys combinations',
  },
  colorShadesGenerator: {
    pageTitle: 'Color shades generator',
    copyLabel: 'copy',
    copiedLabel: 'copied',
    copiedAllLabel: 'Copied',
    copyAllLabel: 'Copy All Values',
    removeLabel: 'Remove',
    darkLigthLabel: 'Darken/lighten by, %',
    saturationLabel: 'Saturation shift, %',
    loadPalletLabel: 'Load default palette',
    removeAllLabel: 'Remove all',
    addColorLabel: '+ Add color',
  },
  pageDividersGenerator: {
    pageTitle: 'Page dividers',
    outputHtmlCodeLabel: 'HTML code',
    outputJsxCodeLabel: 'JSX code',
    downloadLabel: 'Download unstyled svg',
    positionLabel: 'Position',
    colorLabel: 'Color',
    directionLabel: 'Direction',
    widthLabel: 'Width, %',
    heightLabel: 'Height, %',
    shapeLabel: 'Shape type',
    buttonLabels: {
      top: 'Top',
      bottom: 'Bottom',
      normal: 'Normal',
      reverse: 'Reversed',
    },
  },
  cssCursorGenerator: {
    pageTitle: 'CSS cursor properties',
    generalTitle: 'General purpose',
    statusTitle: 'Status',
    selectionTitle: 'Selection',
    zoomTitle: 'Zoom',
    dragAndDropTitle: "Drag'n'Drop",
    resizeTitle: 'Resize and scroll',
    copiedLabel: 'copied',
    copyLabel: 'copy',
    buttonDescription: 'Click to copy cursor value',
  },
  svgToJsx: {
    dropLabel: 'Drop an SVG file to the browser window to optimize it and convert it to JSX',
    failedMessage: 'Failed to parse or minify file',
    settingsLabel: 'Paste SVG markup',
    exampleButtonTitle: 'Load example',
    textAreaPlaceholder: 'Paste SVG markup here',
    processingMessage: 'Processing with ',
  },
  svgCompressor: {
    pageTitle: 'SVG compressor',
    dropLabel: 'Drop one or more SVG files to start compression',
    previewTitle: 'Preview',
    downloadButtonLabel: 'download',
    controlsLabel: 'or convert to',
    settingsLabel: 'Compressed Code',
    errorMessage: 'Failed to parse SVG file',
  },
  loginPage: {
    pageTitle: 'Login',
    title: 'Login',
    buttonTitle: 'Get Quackin`',
    resetButtonTitle: 'Forgot your password?',
    remmeberOptionTitle: 'Remember me',
    emailLabel: 'Email',
    passwordLabel: 'Password',
  },
  createPasswordPage: {
    pageTitle: 'Create your password',
    title: 'Create your password',
    buttonTitle: 'Get Quackin`',
    passwordLabel: 'Password',
    repeatPasswordLabel: 'Password',
  },
  restorePasswordPage: {
    pageTitle: 'Restore password',
    title: 'Restore password',
    buttonTitle: 'Reset password',
    emailLabel: 'email',
    successMessage: 'A reset link will be sent to your email',
    redirectButtonTitle: 'Go to Login',
  },
  notFoundPage: {
    pageTitle: 'Not Found',
    message: 'you seem lost.. let’s get you back home',
    buttonTitle: 'Home',
  },
  addMemberPage: {
    pageTitle: 'Add member',
    firstNameLabel: 'Name',
    middleNameLabel: 'Middle Name',
    lastNameLabel: 'Surname',
    positionLabel: 'Position',
    emailLabel: 'Email',
    buttonTitle: 'Create',
    startDateLabel: 'Start date',
    personalNumberLabel: 'Private number',
    annualLeaveLabel: 'Annual leave days',
  },
  profilePage: {
    pageTitle: 'Profile',
    avatarMessage: 'Drop a file on the avatar or ',
    avatarInputButtonTitle: 'upload file',
    phoneInputLabel: 'Phone number, start with +359',
    signatureLabel: 'HMTL template signature',
    contactInfoLabel: 'Contact info',
    skypeInputLabel: 'Skype account',
    slackInputLabel: 'Slack account',
    saveChangesButtonTitle: 'Save changes',
    languageSwitcherLabel: 'Choose language',
    resetPasswordButtonTitle: 'Change my password',
  },
  resetPasswordPage: {
    pageTitle: 'Reset your password',
    title: 'Reset your password',
    buttonTitle: 'Get Quackin`',
    passwordLabel: 'Password',
    repeatPasswordLabel: 'Password',
  },
  addAnnouncementPage: {
    pageTitle: 'Add Annoucement',
    titleLabel: 'Title',
    tagsLabel: 'Tags (i.e FE, BE)',
    descriptionLabel: 'Short description (used in preview)',
    announcementLabel: 'Announcement',
    checkmarkLabel: 'Alert members via Email',
    addButtonTitle: 'Create',
    previewImageLabel: 'Preview Image',
    uploadImagelabel: 'Upload image',
    resetImageButtonTitle: 'Delete',
    filesLabel: 'Attach files',
    uploadFilesLabel: 'Drag files here',
    removeFileButtonTitle: 'delete',
  },
  editAnnouncementPage: {
    pageTitle: 'Edit Announcement',
    editButtonTitle: 'Save',
    loading: 'loading',
  },
  faqPage: {
    pageTitle: 'Frequenty asked questions',
    noItemsLabel: 'no questions and answers',
    loadingLabel: 'loading',
    addButtonTitle: 'Add',
    editButtonTitle: 'Save',
    questionLabel: 'Question',
    answerLabel: 'Answer',
    modalMessage: 'Are you sure you want to delete the question?',
    confirmButtonTitle: 'Delete',
    cancelButtonTitle: 'Cancel',
  },
  membersPage: {
    pageTitle: 'Members',
    startDate: 'start date: ',
    paidLeaveDays: 'leave days: ',
    modalMessage: 'Are you sure you want to delete the user?',
    confirmButtonTitle: 'Delete',
    cancelButtonTitle: 'Cancel',
    noUsersLabel: 'no users',
    loadingLabel: 'loading',
  },
  editMemberPage: {
    pageTitle: 'Edit Member',
    firstNameLabel: 'Name',
    middleNameLabel: 'Middle Name',
    lastNameLabel: 'Surname',
    emailLabel: 'Email: ',
    positionLabel: 'Position',
    roleLabel: 'Role (must have at least one assigned role)',
    saveButtonTitle: 'Save',
    resendActivationButtonTitle: 'Resend activation mail',
    deactivateButtonTitle: 'Deactivate',
    adminLabel: 'Admin',
    employeeLabel: 'Employee',
    approverLabel: 'Approver',
    leaveDaysLabel: 'Leave days',
    statusLabel: 'Status: ',
    activatedLabel: 'activated',
    notActivatedLabel: 'not activated',
    startDateLabel: 'Start date',
    endDateLabel: 'End date',
    phoneInputLabel: 'Phone',
    skypeInputLabel: 'Skype account',
    slackInputLabel: 'Slack account',
  },
  dashboardPage: {
    pageTitle: 'Dashboard',
    latestLabel: 'latest',
    calendarLabel: 'calendar',
    upcomingLeavesLabel: 'upcoming leaves',
    todosLabel: 'todos',
    noLatestLabel: 'no announcements',
    noLeavesLabel: 'no requests',
  },
  leavesPage: {
    pageTitle: 'Add New Request',
    homeOfficeButtonLabel: 'Home Office',
    annualLeaveButtonLabel: 'Annual Leave',
    noItemsLabel: 'no leave requests',
    requestStartDateLabel: 'start date: ',
    requestDaysLabel: 'days: ',
    requestReasonLabel: 'reason: ',
    requestResolutionLabel: 'resolution: ',
    requestButtonLabel: 'Resolve',
    annualLeaveTypeLabel: 'Annual Leave',
    homeOfficeTypeLabel: 'Home Office',
    requestAnnualLeaveUnpaidTypeLabel: 'type: unpaid',
    requestAnnualLeavePaidTypeLabel: 'type: paid',
    signButtonLabel: 'Send document',
    concatLabel: 'by',
    pendingLabel: 'pending',
    approvedLabel: 'approved',
    declinedLabel: 'declined',
    loadingLabel: 'loading more...',
    loadedLabel: 'no more requests',
  },
  homeOfficeRequestPage: {
    pageTitle: 'Add Home Office Request',
    startDateLabel: 'Start date',
    daysLabel: 'Days',
    reasonLabel: 'Reason (max. 10000 symbols)',
    buttonTitle: 'Submit',
  },
  annualLeaveRequestPage: {
    pageTitle: 'Add Annual Leave Request',
    paidLabel: 'paid',
    unpaidLabel: 'unpaid',
    daysLabel: 'Days',
    reasonLabel: 'Reason (max. 10000 symbols)',
    buttonTitle: 'Submit',
    startDateLabel: 'Start date',
  },
  resolveRequestPage: {
    pageTitle: 'Resolve Request',
    statusLabel: 'Choose status: ',
    approvedLabel: 'approved',
    declinedLabel: 'declined',
    resolutionLabel: 'Resolution (max. 5000 symbols)',
    buttonTitle: 'Submit',
  },
  signAnnualLeavePage: {
    pageTitle: 'Upload Document Request',
    namesNumberLabel: 'Full names on ID',
    positionLabel: 'Job title',
    resetButtonTitle: 'Erase',
    signButtonTitle: 'Place on document',
    saveButtonTitle: 'Upload',
  },
  announcementListPage: {
    pageTitle: 'Announcements',
    noItems: 'no announcements',
    editModeButtonEnabledLabel: 'Edit mode enabled',
    editModeButtonDisabledLabel: 'Edit mode disabled',
    modalMessage: 'Are you sure you want to delete the announcement?',
    confirmButtonTitle: 'Delete',
    cancelButtonTitle: 'Cancel',
    loadingLabel: 'loading more...',
    loadedLabel: 'no more announcements',
  },
  announcementArticlePage: {
    pageTitle: 'Announcement',
    filesLabel: 'Attached files',
    noItems: 'no attachments',
    loading: 'loading',
  },
  notification: {
    errorMessage: 'Something went wrong. Please try again.',
    unauthorizedMessage: 'You must be authenticated to perform this action',
    welcomeMessage: 'Hey, ',
    addMemberEmailMessage: 'The email has already been taken.',
    addMemberPersonalNumberMessage: 'The personal number has already been taken.',
    addMemberSuccessMessage: 'The user account has been successfully created.',
    profileUpdateSuccessMessage: 'The user account has been successfully updated.',
    editMemberSuccessMessage: 'The user account has been successfully updated.',
    editMemberErrorMessage: 'The user account has not been updated.',
    deleteMemberSuccessMessage: 'The user has been successfully deleted.',
    resendActivationSuccessMessage: 'The account activation email has been sent successfully.',
    addFAQSuccessMessage: 'The FAQ has been successfully created.',
    editFAQSuccessMessage: 'The FAQ has been successfully updated.',
    deleteFAQSuccessMessage: 'The question has been successfully deleted.',
    notEnoughLeaveDaysErrorMessage: 'Not enough annual leave days.',
    addRequestSuccessMessage: 'The request has been successfully created.',
    resolveRequestSuccessMessage: 'The request has been successfully processed.',
    signRequestSuccessMessage: 'The request has been successfully signed.',
    addAnnouncementSuccessMessage: 'The announcement has been successfully created.',
    editAnnouncementSuccessMessage: 'The announcement has been successfully updated.',
    deleteAnnouncementSuccessMessage: 'The announcement has been successfully deleted.',
    incorrectLoginDetailsMessage: 'Incorrect login details. Please try again or reset your password.',
    passwordsDoNotMatchHint: 'Passwords do not match.',
    activationErrorMessage: 'The activation token is not correct or the account has already been activated. Please contact administrator.',
    createPasswordErrorMessage: 'The password must be at least 12 characters and contain small letter,  capital letter, special character and number.',
    fileTypeErrorMessage: 'The file must be a file of type: jpeg, png, jpg, docx, xls, xlsx, pdf.',
    characterLimitErrorMessage: 'The character limit for the input is reached.',
    passwordResetSuccessMessage: 'Password reset email has been successfully sent.',
    requiredFieldsErrorMessage: 'Please fill all the required fields.',
    invalidPersonalNumberErrorMessage: 'The personal number is not invalid.',
    invalidEmailErrorMessage: 'The email is not invalid.',
    invalidPhoneErrorMessage: 'The phone number is not invalid.',
    confirmButtonTitle: 'Login',
    cancelButtonTitle: 'Cancel',
  },
  euroProject: {
    text: 'This project has been implemented in relation to the objectives and conditions of Contract No BG16RFOP002-2.083-0445-C01, Grant procedure No BG16RFOP002-2.083 "Voucher scheme for provision of services for information and communication technologies", Voucher N000394, Type 2, Group 2: "Vouchers for Information and Communication Technology services to optimize management, production and logistics processes',
    licence: 'Licence (serial number): А0007 / 29.06.2021',
  },

};
