import API from 'helpers/api';

const authService = {
  signIn: (
    email, password,
  ) => API.post('api/account/login', {
    email,
    password,
  }),
  createPassword: (
    email,
    password,
    passwordConfirm,
    token,
  ) => API.post('api/account/password-reset', {
    email,
    password,
    passwordConfirm,
    token,
  }),
  activateAccount: (
    token,
  ) => API.get(`api/account/activate/${token}`),
  restorePassword: (
    email,
  ) => API.post('api/account/request/password-reset', { email }),
};

export default authService;
