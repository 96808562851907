import React, {
  useState, useLayoutEffect, useRef,
} from 'react';
import { useLocalStorage, useDocumentTitle } from 'xooks';
import optimize from 'svgo-browser/lib/optimize';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import SvgInput from 'components/tools/SvgInput';
import processSvgFile from 'helpers/utility/svg/processSvgFile';
import formatFileName from './format-file-name';
import Output from './Output/Output';

const INITIAL_PROGRESS_STATE = {
  loading: false,
  output: null,
  error: null,
};

const SvgCompressor = () => {
  const ls = useLocalStorage({ key: 'svg-compressor', delay: 500 });
  const [value, setValue] = useState(ls.retrieve() || '');
  const [results, setResults] = useState({ });
  const queue = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useDocumentTitle(t('svgCompressor.pageTitle'));

  const incrementQueue = () => {
    queue.current += 1;
  };

  const postMessage = (data) => {
    const { index, name, queue: q } = data.payload;

    setResults((current) => ({
      ...current,
      [`${index}_${name}`]: {
        queue: q || 1,
        loading: false,
        error: data.error,
        content: data.content,
      },
    }));
  };

  const handleFilesDrop = (files) => {
    incrementQueue();

    Promise.all(files.map((file) => processSvgFile(file))).then((filesData) => {
      setResults((current) => filesData.reduce(
        (acc, fileData, index) => {
          acc[`${index}_${fileData.file.name}`] = {
            ...INITIAL_PROGRESS_STATE,
            queue: queue.current,
          };
          return acc;
        },
        { ...current },
      ));

      filesData.forEach((fileData, index) => {
        postMessage({
          content: fileData.text,
          payload: { name: fileData.file.name, index, queue: queue.current },
        });
      });
    });
  };

  const handleChange = (text) => {
    setValue(text);

    incrementQueue();

    ls.save(text);

    const data = {
      content: text,
      payload: { name: 'file', index: 'input', queue: queue.current },
    };

    const { payload } = data;

    optimize(data.content)
      .then((content) => postMessage({ error: null, payload, content }))
      .catch((error) => postMessage({ error, payload, content: null }));
  };

  const errors = (result) => Object.keys(result).filter((key) => results[key].error);

  useLayoutEffect(() => {
    if (value.trim().length > 0 && !isLoading) {
      setIsLoading(true);

      const data = {
        content: value,
        payload: { name: 'file', index: 'input' },
      };

      const { payload } = data;

      optimize(data.content)
        .then((content) => postMessage({ error: null, payload, content }))
        .catch((error) => postMessage({ error, payload, content: null }));
    }
  }, [results, value, isLoading]);

  return (
    <SystemLayout>
      <SvgInput
        value={value}
        onChange={handleChange}
        errors={errors(results)}
        onFilesDrop={handleFilesDrop}
        formatFileName={formatFileName}
        dropLabel={t('svgCompressor.dropLabel')}
      />
      {results && <Output results={results} />}
    </SystemLayout>
  );
};

export default SvgCompressor;
