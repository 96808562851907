import React from 'react';
import PropTypes from 'prop-types';

import Input from '../Input';

import './Select.scss';

const Select = ({
  additionalClass,
  data,
  value,
  onChange,
  id,
  label,
}) => {
  const options = data.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <div className={`select ${additionalClass}`}>
      <label className="select__label" htmlFor={id}>
        {label}
      </label>
      <div className="select__control-wrapper">
        <Input
          component="select"
          additionalClass="select__control"
          id={id}
          onChange={(event) => onChange(event.target.value)}
          value={value}
        >
          {options}
        </Input>
        <svg
          className="select__chevron"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </div>
    </div>
  );
};

Select.defaultProps = {
  additionalClass: '',
};

Select.propTypes = {
  additionalClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
    .isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default Select;
