import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';
import Button from 'components/global/Button';
import ConfirmModal from 'components/global/ConfirmModal';
import FormNotification from 'components/global/FormNotification';
import { AuthContext } from 'context/AuthContext';
import { UserContext } from 'context/UserContext';
import { NotificationContext } from 'context/NotificationContext';
import systemService from 'services/systemService';

import FAQItem from './FAQItem';

import './FAQ.scss';

const FAQ = () => {
  const [newQuestion, setNewQuestion] = useState('');
  const [newQuestionInvalidInput, setNewQuestionInvalidInput] = useState(false);
  const [newAnswer, setNewAnswer] = useState('');
  const [newAnswerInvalidInput, setNewAnswerInvalidInput] = useState(false);
  const [isLoaded, setIsLoaded] = useState('');
  const [questions, setQuestions] = useState([]);
  const { t } = useTranslation();
  const { userToken } = useContext(AuthContext);
  const { userRole } = useContext(UserContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState();
  const {
    setNotification,
    setNotificationType,
    resetNotification,
  } = useContext(NotificationContext);
  const [formNotification, setFormNotification] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useRef(null);

  useDocumentTitle(t('faqPage.pageTitle'));

  const onErrorHandler = () => {
    setNotification(t('notification.errorMessage'));
    setNotificationType('error');
  };

  const onClickAddHandler = async () => {
    if (!newQuestion || !newAnswer) {
      setFormNotification(t('notification.requiredFieldsErrorMessage'));
    } else {
      setFormNotification('');
    }

    setNewQuestionInvalidInput(newQuestion === '');
    setNewAnswerInvalidInput(newAnswer === '');

    if (newQuestion && newAnswer) {
      setIsLoading(true);

      try {
        const params = {
          question: newQuestion,
          answer: newAnswer,
        };

        await systemService.createFAQ(params, userToken);

        setNotification(t('notification.addFAQSuccessMessage'));
        setNotificationType('success');
        resetNotification();

        setIsLoaded(false);

        setNewQuestion('');
        setNewAnswer('');
      } catch (error) {
        onErrorHandler();
      }

      setIsLoading(false);
    }
  };

  const onClickEditHandler = (id) => {
    const editItem = questions.find((item) => item.id === id);

    setNewQuestion(editItem.question);
    setNewAnswer(editItem.answer);

    setNewAnswerInvalidInput(false);
    setNewQuestionInvalidInput(false);

    setEditItemId(id);
    setIsEditMode(true);
  };

  const onClickSaveHandler = async () => {
    if (newQuestion && newAnswer) {
      setIsLoading(true);

      try {
        const params = {
          id: editItemId,
          question: newQuestion,
          answer: newAnswer,
        };

        await systemService.updateFAQ(params, userToken);

        setNotification(t('notification.editFAQSuccessMessage'));
        setNotificationType('success');
        resetNotification();

        setNewQuestion('');
        setNewAnswer('');

        setIsEditMode(false);
        setIsLoaded(false);
      } catch (error) {
        onErrorHandler();
      }

      setIsLoading(false);
    }
  };

  const onClickConfirmDeleteHandler = async () => {
    try {
      await systemService.deleteFAQ(deleteUserId, userToken);

      setIsModalOpen(false);
      setIsLoaded(false);

      setNotification(t('notification.deleteFAQSuccessMessage'));
      setNotificationType('success');
      resetNotification();
    } catch (error) {
      onErrorHandler();
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    const getAllFAQ = async () => {
      try {
        const { data } = await systemService.getAllFAQ(userToken);

        if (data.additionalDetails) {
          setQuestions(data.additionalDetails);
        }
      } catch (error) {
        const { message, response } = error;

        if (message === 'Request failed with status code 401') {
          setNotification(t('notification.unauthorizedMessage'));
          setNotificationType('unautorized');
        } else if (response.data.additionalDetails === 'Currently, there are no created FAQs.') {
          setQuestions([]);
        } else {
          setNotification(t('notification.errorMessage'));
          setNotificationType('error');
        }
      }
    };

    if (!isLoaded) {
      const isAdminRole = userRole.find((role) => role.title === 'admin');

      getAllFAQ();
      setIsAdmin(!!isAdminRole);
      setIsLoaded(true);
    }
  }, [isLoaded, resetNotification, setNotification, setNotificationType, t, userRole, userToken]);

  return (
    <SystemLayout>
      <section className="faq">
        {isAdmin && (
        <form className="faq__form">
          <input
            className={`faq__input ${newQuestionInvalidInput ? 'faq__input--invalid' : ''}`}
            placeholder={t('faqPage.questionLabel')}
            type="text"
            value={newQuestion}
            onChange={(event) => setNewQuestion(event.target.value)}
          />
          <textarea
            className={`faq__input faq__textarea ${newAnswerInvalidInput ? 'faq__input--invalid' : ''}`}
            placeholder={t('faqPage.answerLabel')}
            type="text"
            rows="5"
            value={newAnswer}
            onChange={(event) => setNewAnswer(event.target.value)}
          />
          <FormNotification additionalClass="faq__notification" message={formNotification} />
          <Button
            additionalClass="faq__add-button"
            title={isEditMode
              ? t('faqPage.editButtonTitle') : t('faqPage.addButtonTitle')}
            onClickHandler={isEditMode ? onClickSaveHandler : onClickAddHandler}
            loading={isLoading}
          />
        </form>
        )}
        {isLoaded ? (
          <>
            {questions.length > 0 ? (
              <ul className="faq__list">
                {questions.map((item) => (
                  <li key={uuid()} className="faq__item">
                    <FAQItem
                      item={item}
                      isAdmin={isAdmin}
                      onClickEditHandler={onClickEditHandler}
                      onClickDeleteHandler={() => {
                        setIsModalOpen(true);
                        setDeleteUserId(item.id);
                      }}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('faqPage.noItemsLabel')}</p>
            )}
          </>
        ) : (
          <p>{t('faqPage.loadingLabel')}</p>
        )}
      </section>
      {isModalOpen && (
      <ConfirmModal
        message={t('faqPage.modalMessage')}
        confirmButtonTitle={`${t('faqPage.confirmButtonTitle')}`}
        cancelButtonTitle={`${t('notification.cancelButtonTitle')}`}
        onClickConfirmHandler={onClickConfirmDeleteHandler}
        onClickCancelHandler={() => setIsModalOpen(false)}
      />
      )}
    </SystemLayout>
  );
};

export default FAQ;
