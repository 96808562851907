import React, { useState } from 'react';
import { useDocumentTitle, useLocalStorage, useClipboard } from 'xooks';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Background from 'components/tools/Background';
import Tabs from 'components/tools/Tabs';
import Input from 'components/tools/Input';
import symbols from 'helpers/utility/symbols';

import './SymbolsCollection.scss';

const searchSymbols = (query, type) => {
  if (!(type in symbols)) {
    return [];
  }

  if (query.trim().length === 0) {
    return symbols[type].data;
  }

  return symbols[type].fuse.search(query).map(({ item }) => item);
};

const SymbolsCollection = () => {
  const clipboard = useClipboard({ timeout: 1000 });
  const lsQuery = useLocalStorage({ key: 'html-symbols/search', delay: 200 });
  const lsType = useLocalStorage({ key: 'html-symbols/type', delay: 200 });
  const [query, setQuery] = useState(lsQuery.retrieve() || '');
  const [type, setType] = useState(lsType.retrieve() || 'Most used');
  const [copiedValue, setCopiedValue] = useState(null);
  const { t } = useTranslation();

  useDocumentTitle(t('symbolsCollection.pageTitle'));

  const typesData = Object.keys(symbols).map((tab) => ({ value: tab, label: t(tab) }));

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    lsQuery.save(event.target.value);
  };

  const handleTypeChange = (value) => {
    setType(value);
    lsType.save(value);
  };

  const handleCopy = (value) => {
    setCopiedValue(value);
    clipboard.copy(value)
  }

  const results = searchSymbols(query, type).map((item) => (
    <tr className="symbols-collection-item" key={item.entity}>
      <td className="symbols-collection-item__name">{item.name}</td>
      <td>
        <button
          className={`symbols-collection-item__button ${item.symbol === copiedValue && clipboard.copied ? 'symbols-collection-item__button--copied' : ''}`}
          type="button"
          onClick={() => handleCopy(item.symbol)}
          data-content={`${clipboard.copied ? t('symbolsCollection.copiedLabel') : t('symbolsCollection.copyLabel')}`}
        >
          {item.symbol}
        </button>
      </td>
      <td>
        <button
          className={`symbols-collection-item__button ${item.entity === copiedValue && clipboard.copied ? 'symbols-collection-item__button--copied' : ''}`}
          type="button"
          onClick={() => handleCopy(item.entity)}
          data-content={`${clipboard.copied ? t('symbolsCollection.copiedLabel') : t('symbolsCollection.copyLabel')}`}
        >
          {item.entity}
        </button>
      </td>
      <td>
        <button
          className={`symbols-collection-item__button ${item.css === copiedValue
        && clipboard.copied ? 'symbols-collection-item__button--copied' : ''})`}
          type="button"
          onClick={() => handleCopy(item.css)}
          data-content={`${clipboard.copied ? t('symbolsCollection.copiedLabel') : t('symbolsCollection.copyLabel')}`}
        >
          {item.css}
        </button>
      </td>
    </tr>
  ));

  return (
    <SystemLayout>
      <Background additionalClass="symbols-collection">
        <div className="symbols-collection__input-wrapper">
          <Tabs data={typesData} active={type} onTabChange={handleTypeChange} noBorderOnMobile additionalClass="symbols-collection__tabs" />
          <Input
            additionalClass="symbols-collection__input"
            value={query}
            onChange={handleQueryChange}
            type="text"
            placeholder={t('symbolsCollection.searchPlaceholder')}
          />
        </div>
        <table className="symbols-collection__results">
          <thead>
            <tr>
              <th className="symbols-collection__heading">{t('symbolsCollection.nameTitle')}</th>
              <th className="symbols-collection__heading">{t('symbolsCollection.symbolTitle')}</th>
              <th className="symbols-collection__heading">{t('symbolsCollection.hmtlEntityTitle')}</th>
              <th className="symbols-collection__heading">{t('symbolsCollection.cssEntityTitle')}</th>
            </tr>
          </thead>
          <tbody>{results}</tbody>
        </table>
      </Background>
    </SystemLayout>
  );
};

export default SymbolsCollection;
