import React from 'react';
import PropTypes from 'prop-types';

import CodeContainer from 'components/tools/CodeContainer';
import Background from 'components/tools/Background';
import generateGradientValue from '../generate-gradient-value';

import './GradientCode.scss';

const GradientCode = ({
  values, angle, type,
}) => {
  const gradient = generateGradientValue({ values, angle, type });

  return (
    <Background additionalClass="gradient-code">
      <div className="gradient-code__section">
        <div className="gradient-code__title">CSS</div>
        <CodeContainer>{`background-image: ${gradient};`}</CodeContainer>
      </div>
      <div className="gradient-code__section">
        <div className="gradient-code__title">JavaScript</div>
        <CodeContainer>{`backgroundImage: '${gradient}',`}</CodeContainer>
      </div>
    </Background>
  );
};

GradientCode.propTypes = {
  angle: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['radial', 'linear']).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      position: PropTypes.number.isRequired,
      opacity: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default GradientCode;
