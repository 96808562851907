import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/tools/Tabs';
import SliderInput from 'components/tools/SliderInput';
import Select from 'components/tools/Select';
import HexInput from 'components/tools/HexInput';
import Background from 'components/tools/Background';

import { shapes } from '../Shape/Shape';

import './Settings.scss';

const data = Object.keys(shapes).map((shape) => ({
  value: shape,
  label: (shape.charAt(0).toUpperCase() + shape.slice(1)).replace('_', ' '),
}));

const Settings = ({
  values,
  handlers,
}) => {
  const { t } = useTranslation();

  const positionsData = [
    {
      value: 'top',
      label: t('pageDividersGenerator.buttonLabels.top'),
    },
    {
      value: 'bottom',
      label: t('pageDividersGenerator.buttonLabels.bottom'),
    },
  ];

  const directionsData = [{
    value: 'normal',
    label: t('pageDividersGenerator.buttonLabels.normal'),
  }, {
    value: 'reverse',
    label: t('pageDividersGenerator.buttonLabels.reverse'),
  }];

  return (
    <Background additionalClass="page-dividers-settings">
      <div className="page-dividers-settings__wrapper">
        <div className="page-dividers-settings__group">
          <div className="page-dividers-settings__input">
            <Select
              data={data}
              onChange={handlers.onTypeChange}
              id="shape-select"
              label={t('pageDividersGenerator.shapeLabel')}
              value={values.type}
            />
          </div>
          <div className="page-dividers-settings__input">
            <div className="page-dividers-settings__label">{t('pageDividersGenerator.positionLabel')}</div>
            <Tabs
              data={positionsData}
              onTabChange={handlers.onPositionChange}
              active={values.position}
            />
          </div>
        </div>
        <div className="page-dividers-settings__group">
          <div className="page-dividers-settings__input">
            <div className="page-dividers-settings__label">{t('pageDividersGenerator.positionLabel')}</div>
            <HexInput
              additionalClass="page-dividers-settings__hex-input"
              value={values.color}
              onChange={handlers.onColorChange}
            />
          </div>
          <div className="page-dividers-settings__input">
            <div className="page-dividers-settings__label">{t('pageDividersGenerator.directionLabel')}</div>
            <Tabs
              data={directionsData}
              onTabChange={handlers.onDirectionChange}
              active={values.direction}
            />
          </div>
        </div>
        <div className="page-dividers-settings__group">
          <div className="page-dividers-settings__input">
            <div className="page-dividers-settings__label">{t('pageDividersGenerator.widthLabel')}</div>
            <SliderInput
              value={values.width}
              onChange={handlers.onWidthChange}
              min={100}
              max={400}
            />
          </div>
          <div className="page-dividers-settings__input">
            <div className="page-dividers-settings__label">{t('pageDividersGenerator.heightLabel')}</div>
            <SliderInput
              value={values.height}
              onChange={handlers.onHeightChange}
              min={0}
              max={500}
            />
          </div>
        </div>
      </div>
    </Background>
  );
};

Settings.propTypes = {
  values: PropTypes.shape({
    position: PropTypes.oneOf(['top', 'bottom']).isRequired,
    direction: PropTypes.oneOf(['normal', 'reverse']).isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(shapes)).isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    onTypeChange: PropTypes.func.isRequired,
    onColorChange: PropTypes.func.isRequired,
    onWidthChange: PropTypes.func.isRequired,
    onHeightChange: PropTypes.func.isRequired,
    onPositionChange: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func.isRequired,
  }).isRequired,
};

export default Settings;
