import oc from 'open-color';
import React from 'react';
import PropTypes from 'prop-types';

import Swatch from './Swatch';
import HexInput from '../../../../components/tools/HexInput/index';

import './ColorPicker.scss';

const predefinedColors = [
  oc.black,
  oc.red[5],
  oc.pink[5],
  oc.grape[5],
  oc.violet[5],
  oc.indigo[5],
  oc.blue[5],
  oc.cyan[5],
  oc.teal[5],
  oc.green[5],
  oc.lime[5],
  oc.yellow[5],
];

const ColorPicker = ({ value, onChange }) => (
  <>
    <div className="swatches">
      {predefinedColors.map((color) => (
        <Swatch
          additionalClass="swatches__color"
          key={color}
          value={color}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
    <HexInput additionalClass="swatches__input" value={value} onChange={onChange} />
  </>
);

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColorPicker;
