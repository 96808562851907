import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useClipboard } from 'xooks';
import { useTranslation } from 'react-i18next';

import SettingsLabel from 'components/tools/SettingsLabel';
import Background from 'components/tools/Background';
import Button from 'components/tools/Button';
import CopyCodeButton from 'components/tools/CopyCodeButton';
import formatFileName from '../format-file-name';

import './CompressedResult.scss';

const CompressedResult = ({ content, fileKey }) => {
  const history = useHistory();
  const { copied, copy } = useClipboard();
  const { t } = useTranslation();

  const convertToJsx = () => {
    localStorage.setItem('conversion-after-compression/jsx', JSON.stringify(content));

    history.push('/tools/svg-to-jsx');
  };

  const convertToB64 = () => {
    localStorage.setItem('conversion-after-compression/b64', JSON.stringify(content));

    history.push('/tools/base-64-encoder');
  };

  return (
    <Background additionalClass="compressed-result">
      <div className="compressed-result__preview">
        <SettingsLabel>{t('svgCompressor.previewTitle')}</SettingsLabel>
        <img src={`data:image/svg+xml;charset=utf-8;base64,${btoa(content)}`} alt="" />
        <div className="compressed-result__name">{formatFileName(fileKey)}</div>
        <div className="compressed-result__controls">
          <CopyCodeButton
            copied={copied}
            onClickHandler={() => copy(content)}
            additionalClass="compressed-result__control"
          />
          <a
            className="button-tool button-tool--black compressed-result__control"
            download={fileKey}
            href={`data:image/svg+xml;charset=utf-8;base64,${btoa(content)}`}
          >
            {t('svgCompressor.downloadButtonLabel')}
          </a>
          <div className="compressed-result__controls-label">{t('svgCompressor.controlsLabel')}</div>
          <div className="compressed-result__controls-group">
            <Button additionalClass="compressed-result__controls-group-item" onClick={convertToJsx}>
              jsx
            </Button>
            <Button additionalClass="compressed-result__controls-group-item" onClick={convertToB64}>
              base64
            </Button>
          </div>
        </div>
      </div>

      <div className="compressed-result__result">
        <SettingsLabel>{t('svgCompressor.settingsLabel')}</SettingsLabel>
        <div className="compressed-result__code-container">
          <code className="compressed-result__code">{content || ''}</code>
        </div>
      </div>
    </Background>
  );
};

CompressedResult.defaultProps = {
  content: '',
  fileKey: '',
}

CompressedResult.propTypes = {
  content: PropTypes.string,
  fileKey: PropTypes.string,
};

export default CompressedResult;
