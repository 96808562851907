import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AgencyLogo from 'assets/images/eu-project/agency-logo.jpg';
import EuFlag from 'assets/images/eu-project/eu-flag.png';
import OpicLogo from 'assets/images/eu-project/opic-logo.png';

import './EuProjectLogo.scss';

const EuProjectLogo = ({
  additionalClass,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`eu-project ${additionalClass}`}>
      <div className="eu-project__images-wrapper">
        <div className="eu-project__image-wrapper">
          <img src={EuFlag} alt="" className="eu-project__image" />
        </div>
        <div className="eu-project__image-wrapper">
          <img src={AgencyLogo} alt="" className="eu-project__image" />
        </div>
        <div className="eu-project__image-wrapper">
          <img src={OpicLogo} alt="" className="eu-project__image" />
        </div>
      </div>
      <div className="eu-project__text-wrapper">
        <p className="eu-project__text">{t('euroProject.name')}</p>
        <p className="eu-project__text">{t('euroProject.licence')}</p>
        <p className="eu-project__text">{t('euroProject.text')}</p>
        <p className="eu-project__text">{t('euroProject.developer')}</p>
      </div>
    </div>
  );
};

EuProjectLogo.defaultProps = {
  additionalClass: '',
};

EuProjectLogo.propTypes = {
  additionalClass: PropTypes.string,
};

export default EuProjectLogo;
