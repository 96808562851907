import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDocumentTitle } from 'xooks';

import SystemLayout from 'layouts/SystemLayout';

import './AddLeaveRequest.scss';

const Leaves = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useDocumentTitle(t('leavesPage.pageTitle'));

  return (
    <SystemLayout>
      <section className="add-request-page">
        <button
          className="add-request-page__button"
          type="button"
          onClick={() => history.push('/add-homeoffice-request')}
        >
          {t('leavesPage.homeOfficeButtonLabel')}
        </button>
        <button
          className="add-request-page__button"
          type="button"
          onClick={() => history.push('/add-annual-leave-request')}
        >
          {t('leavesPage.annualLeaveButtonLabel')}
        </button>
      </section>
    </SystemLayout>
  );
};

export default Leaves;
