import React, { useState, useEffect } from 'react';
import { useDocumentTitle, useLocalStorage } from 'xooks';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Settings from './Settings/Settings';
import Output from './Output/Output';
import Preview from './Preview/Preview';

const INITIAL_VALUES = {
  type: 'triangles',
  direction: 'normal',
  position: 'top',
  width: 125,
  height: 75,
  color: '#ffffff',
};

const PageDividersGenerator = () => {
  const ls = useLocalStorage({ key: 'page-dividers', delay: 1000 });
  const initialValues = ls.retrieve() || INITIAL_VALUES;
  const { t } = useTranslation();
  const [type, setType] = useState(initialValues.type);
  const [color, setColor] = useState(initialValues.color);
  const [width, setWidth] = useState(initialValues.width);
  const [height, setHeight] = useState(initialValues.height);
  const [position, setPosition] = useState(initialValues.position);
  const [direction, setDirection] = useState(initialValues.direction);

  useDocumentTitle(t('pageDividersGenerator.pageTitle'));

  const values = {
    type,
    color,
    width,
    height,
    position,
    direction,
  };

  const handlers = {
    onTypeChange: setType,
    onColorChange: setColor,
    onWidthChange: setWidth,
    onHeightChange: setHeight,
    onPositionChange: setPosition,
    onDirectionChange: setDirection,
  };

  useEffect(() => {
    ls.save({
      direction, width, height, color, position, type,
    });

    return ls.cancel;
  }, [direction, width, height, color, position, type, ls]);

  return (
    <SystemLayout>
      <Settings values={values} handlers={handlers} onTypeChange={(value) => setType(value)} />
      <Preview values={values} />
      <Output values={values} />
    </SystemLayout>
  );
};

export default PageDividersGenerator;
