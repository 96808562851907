import React from 'react';
import { useDocumentTitle } from 'xooks';
import { useTranslation } from 'react-i18next';

import SystemLayout from 'layouts/SystemLayout';
import Background from 'components/tools/Background';
import SettingsLabel from 'components/tools/SettingsLabel';
import CursorControl from './CursorControl/CursorControl';
import data from './data';

import './CssCursors.scss';

const CssCursors = () => {
  const { t } = useTranslation();

  useDocumentTitle(t('cssCursorGenerator.pageTitle'));

  const controls = data.map((group) => {
    const values = group.data.map((value) => (
      <CursorControl key={value} value={value} additionalClass="css-cursors-generator__button" />
    ));

    return (
      <div className="css-cursors-generator__group" key={t(group.title)}>
        <SettingsLabel additionalClass="css-cursors-generator__title">{t(group.title)}</SettingsLabel>
        <div className="css-cursors-generator__buttons">{values}</div>
      </div>
    );
  });

  return (
    <SystemLayout>
      <Background additionalClass="css-cursors-generator">{controls}</Background>
    </SystemLayout>
  );
};

export default CssCursors;
