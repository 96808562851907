import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from 'xooks';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import './HexInput.scss';

const HexInput = ({
  additionalClass, value, onChange,
}) => {
  const ref = useRef(null);
  const [opened, setOpened] = useState(false);
  const closePicker = () => setOpened(false);

  useClickOutside(ref, closePicker);

  useLayoutEffect(() => {
    const closeOnEscape = (event) => event.code === 'Escape' && closePicker();

    window.addEventListener('keydown', closeOnEscape);

    return () => window.removeEventListener('keydown', closeOnEscape);
  }, []);

  return (
    <div className={`hex-input ${additionalClass}`} ref={ref}>
      <div className="hex-input__wrapper">
        <button
          className="hex-input__control"
          type="button"
          onClick={() => setOpened(!opened)}
          style={{ backgroundColor: value }}
          aria-label={value}
        />
        <div className="hex-input__hash">#</div>
        <HexColorInput
          className="hex-input__input"
          color={value.toUpperCase()}
          onChange={onChange}
        />
      </div>
      {opened && <HexColorPicker className="hex-input__picker" color={value} onChange={onChange} />}
    </div>
  );
};

HexInput.defaultProps = {
  additionalClass: '',
};

HexInput.propTypes = {
  additionalClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default HexInput;
