import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import data from './data';
import SettingsLabel from '../../../../components/tools/SettingsLabel';
import GradientGalleryItem from './GradientGalleryItem/GradientGalleryItem';

import './GradientsGallery.scss';

const GradientsGallery = ({ additionalClass, handlers, onItemClickHandler }) => {
  const { t } = useTranslation();

  const handleGradientPick = (values) => {
    onItemClickHandler();

    handlers.setState(values.map((value) => ({ ...value, key: v4() })));
  };

  const items = data.map((item) => (
    <GradientGalleryItem
      key={item.name}
      values={item.values}
      name={item.name}
      onEditorOpen={handleGradientPick}
      additionalClass="gradients-gallery__item"
    />
  ));

  return (
    <div className={`gradients-gallery ${additionalClass}`}>
      <SettingsLabel additionalClass="gradients-gallery__title">{t('gradientGenerator.galleryTitle')}</SettingsLabel>
      <div className="gradients-gallery__items">{items}</div>
    </div>
  );
};

GradientsGallery.defaultProps = {
  additionalClass: '',
  onItemClickHandler: () => {},
};

GradientsGallery.propTypes = {
  additionalClass: PropTypes.string,
  onItemClickHandler: PropTypes.func,
  handlers: PropTypes.shape({
    setState: PropTypes.func.isRequired,
  }).isRequired,
};

export default GradientsGallery;
